import { DialogUtility } from '@syncfusion/ej2-popups';
import { debounce } from 'lodash';

export const asyncDebounce = (func, wait) => {
    const debounced = debounce((resolve, reject, args) => {
        func(...args)
            .then(resolve)
            .catch(reject);
    }, wait);
    return (...args) =>
        new Promise((resolve, reject) => {
            debounced(resolve, reject, args);
        });
};

export const fillWithZeros = (value, decimals) => {
    const valueSplitted = value !== 0 ? `${value}`.split('.') : '';
    if (valueSplitted) {
        if (valueSplitted.length === 1) {
            return parseFloat(value).toFixed(decimals);
        } else if (valueSplitted.length > 1) {
            const paddedDecimals = valueSplitted[1].padEnd(decimals, '0');
            return `${valueSplitted[0]}.${paddedDecimals}`;
        }
        return value;
    }
};

interface ConfirmationArgs {
    title?: string;
    message: string;
    width?: number;
    confirmBtnText?: string;
    cancelBtnText?: string;
}
export const showConfirmation = async ({
    title,
    message,
    width = undefined,
    confirmBtnText = 'Confirm',
    cancelBtnText = 'Cancel',
}: ConfirmationArgs) => {
    return new Promise((resolve) => {
        const alert = DialogUtility.confirm({
            animationSettings: { effect: 'None' },
            isModal: true,
            width,
            content: message,
            cancelButton: {
                text: cancelBtnText,
                cssClass: 'e-cancel mt-2',
                click: () => {
                    alert.hide();
                    resolve(false);
                },
            },
            okButton: {
                text: confirmBtnText,
                cssClass: 'e-submit mt-2',
                click: () => {
                    alert.hide();
                    resolve(true);
                },
            },
            title,
            position: { X: 'center', Y: 'center' },
        });
    });
};
