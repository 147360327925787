import React from 'react';
import VideoParticipant from './VideoParticipant/VideoParticipant';

export const getOtherParticipants = (participants, memberConnectionId, room, setEnableAudio) => {
    if (participants.length > 0) {
        return participants.map((participant, index) => {
            if (participant.identity !== memberConnectionId) {
                return (
                    <VideoParticipant
                        key={participant.sid}
                        participant={participant}
                        room={room}
                        setEnableAudio={setEnableAudio}
                    />
                );
            }
            return <></>;
        });
    }
};
