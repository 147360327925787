export const ExportPDFButtonURL = {
    PATIENTSWITHOPENGAPS: '/api/PatientViewer/AdvancedMemberBulkPdf',
};

export const ExportPDFButtonContainingString = {
    ATLEASTONEID: '"MemberIds":["',
};

export const ExportPDFButtonType = {
    PDF: 'application/pdf',
    ZIP: 'application/zip',
};
