import React from 'react';
import { ajaxPost } from 'common/ajax';
import { VideoService } from 'components/Common/VirtualVisits/VideoService';
import { notificationService } from 'subjects/common/NotificationService';
import { disconnectRoom, leaveConversation } from './helpers';
import { TELEHEALTH_MODULES_TO_URL } from './MyPanelHelpers';
import { appointmentDialogService } from './Subjects/AppointmentDialogService';
import VirtualVisit from './VirtualVisit';

export default class VirtualVisitModal extends React.Component<any, any> {
    subscription: any;
    virtualVisitSubscription: any;
    onCloseVideoCallSubscription: any;
    virtualVisitRef: any;
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            fields: {
                dataSource: [],
                id: 'nodeId',
                text: 'nodeText',
                child: 'nodeChild',
            },
            showVirtualVisit: false,
            roomData: {},
            telehealthModuleType: null,
            isOnDemand: false,
            notificationContent: null,
        };

        this.subscription = null;
        this.virtualVisitSubscription = null;
    }

    componentDidMount() {
        this.subscription = VideoService.getVideo().subscribe({
            next: (args: any) => {
                this.showHideVisitWindow(args);
            },
        });
    }

    componentWillUnmount() {
        this.virtualVisitSubscription && this.virtualVisitSubscription.unsubscribe();
        this.subscription && this.subscription.unsubscribe();
        this.onCloseVideoCallSubscription && this.onCloseVideoCallSubscription.unsubscribe();
    }

    showHideVisitWindow = ({ roomName, appointment, telehealthModuleType, notificationContent }) => {
        if (roomName && appointment) {
            this.showVirtualVisitWindow(roomName, appointment, telehealthModuleType);
            this.setState({ isOnDemand: appointment.isOnDemand, notificationContent: notificationContent });
        } else {
            this.setRoomInfo(null, null, null);
        }
    };

    setRoomInfo = (room, appointment, telehealthModuleType) => {
        if (room && appointment) {
            this.setState({
                showVirtualVisit: true,
                roomData: room,
                appointment: appointment,
                telehealthModuleType,
            });
        } else {
            this.setState({ showVirtualVisit: false });
        }
    };

    closeVirtualVisitWindow = async (room, conversation) => {
        await disconnectRoom(room);
        await leaveConversation(conversation);
        VideoService.showVideo(null, null, undefined);
        VideoService.updateBtnsState(false);
        if (this.state.isOnDemand && this.state.notificationContent?.type) {
            if (this.state.notificationContent?.type == 'success') {
                notificationService.success({ title: this.state.notificationContent?.title, duration: 8000 });
            }
            if (this.state.notificationContent?.type == 'warning') {
                notificationService.warning({ title: this.state.notificationContent?.title, duration: 15000 });
                this.onCloseVideoCallSubscription = appointmentDialogService.onCloseVideoCall(this.state.appointment);
            }
        }

        this.setState({
            showVirtualVisit: false,
            isOnDemand: false,
        });
    };

    showVirtualVisitWindow = (roomName, appointment, telehealthModuleType) => {
        this.virtualVisitRef && this.virtualVisitRef.handleRestoreWindow();
        this.virtualVisitSubscription = ajaxPost(
            `/api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/CreateVideoRoom?roomName=${roomName}&memberId=${appointment.memberId}`,
        ).subscribe({
            next: (response) => {
                if (!(response as any).response?.item2) {
                    this.setRoomInfo(response.response, appointment, telehealthModuleType);
                    VideoService.updateBtnsState(true);
                    VideoService.setWindowIsAvailable(true);
                } else {
                    notificationService.error({
                        title: (response as any).response.item2,
                    });
                    VideoService.setWindowIsAvailable(false);
                }
            },
            error: console.error,
        });
    };

    render() {
        if (!this.state.showVirtualVisit) {
            return null;
        }

        return (
            <VirtualVisit
                ref={(ref) => (this.virtualVisitRef = ref)}
                endCall={this.closeVirtualVisitWindow}
                roomData={this.state.roomData}
                appointment={this.state.appointment}
                telehealthModuleType={this.state.telehealthModuleType}
            />
        );
    }
}
