import { createTheme } from '@mui/material/styles';

export const secondaryTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    height: 36,
                    minHeight: 36,
                    maxHeight: 36,
                    padding: 0,
                    margin: 0,
                    alignItems: 'center',
                    fontSize: 13,
                    fontFamily: 'Roboto',
                },
                indicator: {
                    display: 'none',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'inherit',
                    color: 'white',
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    backgroundColor: '#0099a8',
                    alignContent: 'center',
                    alignItems: 'center',
                    '&.Mui-selected': {
                        backgroundColor: 'white',
                        color: '#0099a8',
                    },
                },
            },
        },
    },
});

export const primaryTheme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 36,
                    padding: 0,
                    margin: 0,
                    alignItems: 'end',
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    gap: 3,
                },
                indicator: {
                    display: 'none',
                },
                scrollButtons: {
                    height: 36,
                    maxHeight: 36,
                    minHeight: 36,
                    border: '1px solid #00b3e3',
                    borderBottom: 'none',
                    fontSize: 12,
                    fontFamily: 'Roboto',
                    backgroundColor: 'white',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    height: 36,
                    maxHeight: 36,
                    minHeight: 36,
                    marginRight: 3,
                    borderTop: '1px solid #00b3e3',
                    borderLeft: '1px solid #00b3e3',
                    borderRight: '1px solid #00b3e3',
                    textTransform: 'inherit',
                    color: '#00b3e3',
                    fontSize: 12,
                    fontFamily: 'Roboto',
                    backgroundColor: 'white',
                    alignContent: 'center',
                    alignItems: 'center',
                    '&.Mui-selected': {
                        backgroundColor: '#00b3e3',
                        color: 'white',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: '#00b3e3',
                },
            },
        },
    },
});
