import React, { useEffect, useRef, useState } from 'react'

import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnChooser, GridComponent, Inject, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useForm } from 'react-hook-form';

import { formatDateTZ } from 'components/ProviderData/DirectoryChangeRequest/DataUpdateRequestConfig'
import LoadingScreen from 'components/UI/LoadingScreen/LoadingScreen';

import { MemberEnrollmentApi } from '../MemberEnrollmentApi';
import { FaxHistoryFilter } from '../MemberEnrollmentDetails/MemberEnrollmentConfig';
import { FAX_SERVER_GRID_VIEW_ID, FaxHistroyGridColumns } from './AdminAndSupport.Metadata';

const FaxTransactionHistory = () => {
    const { register, handleSubmit, getValues, resetField, reset, setValue } = useForm<FaxHistoryFilter>({
        mode: 'onChange',
        defaultValues: {
            FaxReceivedDate: undefined,
            FromDate: undefined,
            ToDate: undefined,
            FileName: '',
            ReceivedStatus: null,
            AvaProcessingStatus: null,
        }
    });
    const gridRef = useRef<GridComponent | null>(null);
    const [faxData, setFaxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [processingStatusList, setProcessingStatusList] = useState<any>();
    const [receivedStatusList, setReceivedStatusList] = useState<any>();
    const minDate = new Date(2010, 11, 31);
    const maxDate = new Date(9999, 11, 31);

    const fetchItemLists = async (partitionName) => {
        await MemberEnrollmentApi.GetDescriptionsByPartition({ partitionName: partitionName })
            .then((data: any) => {
                if (partitionName === 'FaxServerFileStatus') {
                    setReceivedStatusList(data);
                }
                else if (partitionName === 'FaxFileProcessingStatus') {
                    setProcessingStatusList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const SearchFaxServerEnrollments = async () => {
        const faxDates = getValues('FaxReceivedDate');
        if (faxDates !== undefined && faxDates) {
            setValue('FromDate', faxDates[0])
            setValue('ToDate', faxDates[1])
        } else {
            setValue('FromDate', undefined)
            setValue('ToDate', undefined)
        }
        const filterData = {
            fromDate: formatDateTZ(getValues('FromDate')),
            toDate: formatDateTZ(getValues('ToDate')),
            fileName: getValues('FileName'),
            receivedStatus: getValues('ReceivedStatus'),
            avaProcessingStatus: getValues('AvaProcessingStatus'),
        };
        setLoading(true);

        await MemberEnrollmentApi.GetMemberEnrollmentProcessingFaxHistory(filterData)
            .then((data: any) => setFaxData(data))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const onSubmit = () => {
        SearchFaxServerEnrollments()
    }

    const handleResetForm = () => {
        resetField('FaxReceivedDate')
        resetField('FromDate')
        resetField('ToDate')
        reset()
        SearchFaxServerEnrollments()
    }

    useEffect(() => {
        setLoading(true);
        Promise.all([
            SearchFaxServerEnrollments(),
            fetchItemLists('FaxServerFileStatus'),
            fetchItemLists('FaxFileProcessingStatus')
        ])
        .finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row p-3">
                    <div className="col-md-2">
                        <DateRangePickerComponent
                            id="FaxReceivedDate"
                            placeholder="Fax Received Date"
                            min={minDate}
                            max={maxDate}
                            {...register('FaxReceivedDate')}
                            className="py-2"
                        />
                    </div>
                    <div className="col-md-2">
                        <TextBoxComponent
                            type="text"
                            className="form-control form-control-sm py-2"
                            showClearButton={true}
                            placeholder="File Name"
                            {...register('FileName')}
                        />
                    </div>
                    <div className="col-md-2">
                        <DropDownListComponent
                            fields={{ text: 'description', value: 'description' }}
                            dataSource={receivedStatusList}
                            placeholder="Received Status"
                            showClearButton={true}
                            allowFiltering={true}
                            filterType="Contains"
                            cssClass="form-control form-control-sm py-1"
                            {...register('ReceivedStatus')}
                        />
                    </div>
                    <div className="col-md-2">
                        <DropDownListComponent
                            fields={{ text: 'description', value: 'code' }}
                            dataSource={processingStatusList}
                            placeholder="Processing Status"
                            showClearButton={true}
                            allowFiltering={true}
                            filterType="Contains"
                            cssClass="form-control form-control-sm"
                            {...register('AvaProcessingStatus')}
                        />
                    </div>
                    <div className="col-md-4">
                        <input
                            type="button"
                            className="btn-secondary customBtn py-2 me-2"
                            value="Clear"
                            style={{ backgroundColor: '#adb5bd' }}
                            onClick={handleResetForm}
                        />
                        <input type="submit" className="btn-primary mx-2 py-2 px-2 allign-middle customBtn"
                            style={{ color: '#fff' }} value="Search" />
                    </div>
                </div>
            </form>

            <div className="gridContainer">
                <GridComponent
                    ref={gridRef}
                    id={'transactionHistoryGrid'}
                    dataSource={faxData}
                    showColumnChooser={true}
                    rowHeight={50}
                    height="auto"
                    allowPaging={true}
                    allowSorting={true}
                    allowResizing={true}
                    toolbar={[]}
                    columns={FaxHistroyGridColumns}
                    pageSettings={{ pageSize: 15, pageCount: 5 }}
                >
                    <Inject services={[Sort, Toolbar, Page, ColumnChooser]} />
                </GridComponent>
                {loading && <LoadingScreen parentId={FAX_SERVER_GRID_VIEW_ID} text="Fetching for files" height="100%" />}
            </div>
        </>
    )
}

export default FaxTransactionHistory;