import { ColumnChooser } from '@syncfusion/ej2-react-grids';
import { currentUserService } from 'subjects/common/CurrentUserService';

export default class ColumnChooserWithTracker extends ColumnChooser {
    constructor(parent, serviceLocator) {
        super(parent, serviceLocator);
        parent.columnChooserSettings.operator = 'contains';
    }

    columnStateChange(stateColumns, state) {
        super.columnStateChange(stateColumns, state);
        this.parent.setColumnsVisibility &&
            this.parent.setColumnsVisibility(this.stateChangeColumns);
        this.stateChangeColumns.forEach((c) => {
            currentUserService.track('UIGridColumnChange', {
                field: c.field,
                visible: c.visible,
                gridid: this.parent.uid,
            });
        });
    }
}
