import { ColumnModel } from "@syncfusion/ej2-react-grids";

export const FAX_SERVER_GRID_VIEW_ID = 'member-enrollment-fax-server-grid';

export const FaxHistroyGridColumns: ColumnModel[] = [
    {
        field: `receivedTime`,
        headerText: 'Fax Received Date',
        type: 'Date',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        format: 'MM/dd/y',
        width: 'auto',
    },
    {
        field: 'fileName',
        headerText: 'File Name',
        type: 'text',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        width: 450,
    },
    {
        field: 'pagesReceived',
        headerText: 'Total Pages',
        type: 'text',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        width: 130,
    },
    {
        field: 'faxStatus',
        headerText: 'Fax Received Status',
        type: 'text',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        width: 'auto',
    },
    {
        field: 'from',
        headerText: 'Fax Received From',
        type: 'text',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        width: 'auto',
    },
    {
        field: 'to',
        headerText: 'Fax Sent To',
        type: 'text',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        width: 'auto',
    },
    {
        field: 'avaProcessingDate',
        headerText: 'Processing Date',
        type: 'Date',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        format: 'MM/dd/y',
        width: 'auto',
    },
    {
        field: 'avaProcessingStatus',
        headerText: 'Processing Status',
        type: 'text',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
        width: 'auto',
    }
]
