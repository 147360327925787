import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import './CallNotes.css';
import CallNotesDialog from './CallNotesDialog';

interface NotepadProps {
    caller: string | null;
    talkdeskId: string;
}

export default function CallNotes({ caller, talkdeskId }: NotepadProps) {
    const [showNotes, setShowNotes] = useState<boolean>(false);

    const HelpTooltip = () => (
        <TooltipComponent content={'Attach notes to this call'} target="#talkdesk-call-overlay-notes">
            <div id="talkdesk-call-overlay-notes" className="me-2">
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
            </div>
        </TooltipComponent>
    );

    const toggleNotes = () => setShowNotes(!showNotes);

    const header = `Call notes for ${caller ?? talkdeskId}`;

    return (
        <>
            <div className={'notepad-container'}>
                <span className={'notepad-icon'}>
                    <FontAwesomeIcon icon={faStickyNote} size="lg" onClick={toggleNotes} />
                </span>
                <HelpTooltip />
            </div>
            <CallNotesDialog
                talkdeskId={talkdeskId}
                target={'.call-timer-popup-container'}
                header={header}
                visible={showNotes}
                setVisible={setShowNotes}
            />
        </>
    );
}
