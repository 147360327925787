import { ODataV4Adaptor } from '@syncfusion/ej2-data';
import { getAvaDataManager } from '../../common/AVADataManager';

export const makeId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

export const getGroupUsersData = () => {
    return getAvaDataManager('/api/filters/groupusers', ODataV4Adaptor);
};
