import { ProgressButtonComponent, SpinPosition } from "@syncfusion/ej2-react-splitbuttons";

interface IProgressButton {
    type: string;
    className: string;
    buttonRef: React.RefObject<ProgressButtonComponent>;
    children: string | JSX.Element;
    id?: string;
    disabled?: boolean;
    handleClick?: React.MouseEventHandler<Element>;
}

interface IProgressButtonEvent {
    buttonRef: React.RefObject<ProgressButtonComponent>;
    isDisabled: boolean;
    method?: string;
}

export const spinnerButtonDuration = 60000;
const spinnerPosition: {position: SpinPosition} = { position: 'Right' };

export const handleProgressBtnStatus = (params: IProgressButtonEvent) => {
    const {buttonRef, isDisabled, method} = params;
    if (buttonRef?.current) {
        buttonRef.current.disabled = isDisabled;

        if (method) {
            buttonRef.current[method]();
        }
    }
};

const ProgressButton = (props: IProgressButton) => {
    const {type, className, buttonRef, children, id, disabled, handleClick} = props;

    const onProgress = () => {
        const params = {
            buttonRef: buttonRef,
            isDisabled: true,
        }
        handleProgressBtnStatus(params);
    };

    const onEnd = () => {
        const params = {
            buttonRef: buttonRef,
            isDisabled: false,
            method: 'stop',
        }
        handleProgressBtnStatus(params);
    };

    const onBegin = () => {
        buttonRef.current?.progress();
    };

    return (
        <ProgressButtonComponent
            id={id}
            type={type}
            className={className}
            ref={buttonRef}
            progress={onProgress}
            end={onEnd}
            begin={onBegin}
            duration={spinnerButtonDuration}
            disabled={disabled}
            onClick={handleClick}
            spinSettings={spinnerPosition}
        >
            {children}
        </ProgressButtonComponent>
    )
}

export default ProgressButton;