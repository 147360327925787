import React, { useRef, useState } from 'react';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { select } from '@syncfusion/ej2-base';
import { FileInfo, RemovingEventArgs, SelectedEventArgs, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { headers } from 'common/axios';
import { axiosInstance } from './axios';
import styles from './DataImport.module.scss';
import { DataType, IpaImportFormData } from './Model';
import { ProviderNetworkDataTypes } from 'components/ProviderData/DataImportUtility/DataImport.Metadata';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import './IpaDataImport.scss';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ControlledSyncfusionDropDownList } from 'components/Common/SyncfusionWrappers/Syncfusion';
import { saveFileRequestResult } from 'common/widgets';

const DEFAULT_FORM_VALUES: IpaImportFormData = {
    dataType: null,
    actionType: 'Insert',
    file: '',
};
const SEARCH_URL = '/api/ProviderNetworkDataUtility/importData';

type IpadataImportFormProps = {
    showSuccessToast?: any;
    showErrorToast?: any;
    isReadOnlyViewer: boolean;
};

const IpaDataImportForm = ({ showSuccessToast, showErrorToast, isReadOnlyViewer }: IpadataImportFormProps) => {
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState<FileInfo | null>(null);
    const [reportUploadResponse, setReportUploadResponse] = useState<any>(null);
    const uploaderRef = useRef<UploaderComponent>(null);
    const dropAreaRef = useRef<HTMLDivElement>(null);

    const {
        control,
        formState: { errors },
        handleSubmit,
        clearErrors,
        setValue,
    } = useForm<IpaImportFormData>({
        defaultValues: DEFAULT_FORM_VALUES,
        mode: 'onSubmit',
    });

    const onFilesSelect = (args: SelectedEventArgs): void => {
        clearErrors('file');
        const newFile = args.filesData[0];
        setValue('file', newFile?.name ?? '');
        setFile(newFile);
    };

    const onFilesRemove = (args: RemovingEventArgs) => {
        setValue('file', '');
        setFile(null);
        setReportUploadResponse(null);
    };

    const onFormSubmit = async (data: IpaImportFormData) => {
        try {
            setUploading(true);
            const payload = new FormData();
            Object.keys(data).forEach((key) => {
                if (data[key] !== null) payload.append(key, data[key]);
            });
            payload.delete('file');
            payload.append('file', file!.rawFile);
            setReportUploadResponse(null);
            const response = await axiosInstance.post<any>(SEARCH_URL, payload, {
                headers: headers(),
                responseType: 'blob',
            });

            saveFileRequestResult(response.data, response.request);
            setReportUploadResponse(response);

            uploaderRef.current?.clearAll();
            setFile(null);
        } catch (error: any) {
            const responseObj = await error?.response?.data.text();
            if (
                error.request.responseType === 'blob' &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf('json') != -1
            ) {
                const errorString = JSON.parse(responseObj);
                showErrorToast(errorString.detail);
                setReportUploadResponse(errorString);
            }
        } finally {
            clearErrors();
            setUploading(false);
        }
    };

    const onBrowseBtnClick = (args: React.MouseEvent) => {
        const wrapperEle: HTMLElement = select('.e-file-select-wrap button', document) as HTMLElement;
        wrapperEle.click();
        args.preventDefault();
    };

    const removeAttachedFile = () => {
        setValue('file', '');
        setFile(null);
        setReportUploadResponse(null);
    };

    return (
        <DisabledWrapper disabled={isReadOnlyViewer} withSpinner={uploading}>
            {uploading ? (
                <div style={{ height: 200 }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '45%',
                            left: '40%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '50px',
                            fontWeight: 'bold',
                        }}
                    >
                        <p>processing file, please wait....</p>
                    </div>
                </div>
            ) : (
                <form>
                    <Row className="mb-4">
                        <Col md={4}>
                            <RadioButtonComponent
                                name="actionType"
                                cssClass="radio-label "
                                checked={DEFAULT_FORM_VALUES.actionType == 'Insert' ? true : false}
                                label="Insert"
                                change={(args) => {
                                    if (args.event.target.checked) {
                                        setValue('actionType', 'Insert');
                                        DEFAULT_FORM_VALUES.actionType = 'Insert';
                                    }
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <RadioButtonComponent
                                name="actionType"
                                cssClass="radio-label "
                                checked={DEFAULT_FORM_VALUES.actionType == 'Update' ? true : false}
                                label="Update"
                                change={(args) => {
                                    if (args.event.target.checked) {
                                        setValue('actionType', 'Update');
                                        DEFAULT_FORM_VALUES.actionType = 'Update';
                                    }
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <RadioButtonComponent
                                name="actionType"
                                cssClass="radio-label "
                                checked={DEFAULT_FORM_VALUES.actionType == 'Delete' ? true : false}
                                label="Delete"
                                change={(args) => {
                                    if (args.event.target.checked) {
                                        setValue('actionType', 'Delete');
                                        DEFAULT_FORM_VALUES.actionType = 'Delete';
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <div className={styles.col}>
                                <label className="e-label-select mb-2">Target Dataset:</label>
                                <ControlledSyncfusionDropDownList
                                    name="dataType"
                                    control={control}
                                    dataSource={ProviderNetworkDataTypes as DataType[]}
                                    fields={{ text: 'text', value: 'value' }}
                                    ddlAttrs={{
                                        placeholder: 'Select a Target Dataset',
                                    }}
                                    rules={{ required: 'Please select a Target Dataset' }}
                                />
                            </div>
                            <Form.Text className="text-danger">{errors.dataType?.message}</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className={styles.label}>Data Source File (xlsx):</label>
                            <Controller
                                render={({ field: { onChange } }) => (
                                    <div
                                        className={`${styles.uploaderWrapper} ${styles.customBorder} ${
                                            errors.file && ' ' + styles.errorInput
                                        }`}
                                        ref={dropAreaRef}
                                    >
                                        <div className="w-100 d-flex flex-column align-items-center">
                                            <span className="mb-2">
                                                <FontAwesomeIcon icon={faCloudArrowUp} className="uploadIcon" />
                                            </span>
                                            <span className="mb-2">Drag and drop your file here</span>
                                            <span>
                                                or{' '}
                                                <span className={styles.browseBtn} onClick={onBrowseBtnClick}>
                                                    browse file
                                                </span>
                                            </span>
                                        </div>
                                        <UploaderComponent
                                            ref={uploaderRef}
                                            cssClass={styles.uploader}
                                            multiple={false}
                                            type="file"
                                            allowedExtensions=".xls, .xlsx"
                                            autoUpload={false}
                                            selected={onFilesSelect}
                                            removing={(args: RemovingEventArgs) => {
                                                onFilesRemove(args);
                                                onChange('');
                                            }}
                                            created={() => {
                                                uploaderRef.current!.dropArea = dropAreaRef.current!;
                                            }}
                                        />
                                    </div>
                                )}
                                name="file"
                                control={control}
                                rules={{
                                    required: 'Please select your file',
                                    validate: {
                                        acceptableFormat: () => {
                                            return (
                                                ['xls', 'xlsx'].includes(file?.type ?? 'wrong_format') ||
                                                'This file type cannot be submitted'
                                            );
                                        },
                                    },
                                }}
                            />
                            <Form.Text className={`${styles.text} ${styles.errorText}`}>
                                {errors.file?.message}
                            </Form.Text>
                        </Col>
                    </Row>
                    {file != null && (
                        <Row className="mt-2">
                            <Col>
                                <div>
                                    <b>File name: </b>
                                    <span>{file?.name}</span>
                                    <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        onClick={() => {
                                            removeAttachedFile();
                                        }}
                                        style={{
                                            color: '#1d86b7',
                                            marginLeft: '30px',
                                            fontSize: '16px',
                                            cursor: 'pointer',
                                        }}
                                    ></i>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-4">
                        <Col>
                            <div className={styles.btnGroup}>
                                <Button
                                    className="e-file-upload-btn"
                                    type="button"
                                    onClick={handleSubmit(onFormSubmit)}
                                >
                                    Data Import
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {reportUploadResponse && reportUploadResponse?.status == 200 && (
                                <div className={`mt-4 mb-4 text-left e-upload-message`}>
                                    Please check the result file and review the import status column.
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {reportUploadResponse && reportUploadResponse?.status == 500 && (
                                <div className={`mt-4 mb-4 text-left ${styles.errorText}`}>
                                    <p className="e-error-header">Failed import data:</p>
                                    <p>{reportUploadResponse?.detail}</p>
                                </div>
                            )}
                        </Col>
                    </Row>
                </form>
            )}
        </DisabledWrapper>
    );
};

export default IpaDataImportForm;
