import React, { useEffect, useState } from 'react';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subscription, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from 'interfaces/User';
import {
    talkdeskFactory as factory,
    talkdeskMessenger as messenger,
    talkdeskValidator as validator,
} from '../../components/Common/TalkdeskUtils';
import { configurationService } from '../../subjects/crossModules/ConfigurationService';
import { currentUserService } from '../../subjects/common/CurrentUserService';
import { talkdeskCtiService } from '../../subjects/crossModules/TalkdeskCtiService';
import { talkdeskInboundService } from '../../subjects/crossModules/TalkdeskInboundService';
import { talkdeskOutboundService } from '../../subjects/crossModules/TalkdeskOutboundService';

interface TalkdeskProps {
    ctiEnabled: boolean;
    inboundCallsEnabled: boolean;
    outboundCallsEnabled: boolean;
    loginReminders: boolean;
}

export default function Talkdesk(props: TalkdeskProps) {
    const subscriptions: Subscription[] = [];
    const [ctiUrl, setCtiUrl] = useState('');
    const [userId, setUserId] = useState('');
    const [userEnabled, setUserEnabled] = useState(false);
    const [visible, setVisible] = React.useState(true);

    // subscriptions
    function getIframeSrcSubscription(): Subscription {
        return configurationService.getAppSetting$('TalkdeskCTISource').subscribe((response) => {
            setCtiUrl(response.response);
        });
    }
    function getCurrentUserSubscription(): Subscription {
        return currentUserService.get().subscribe((user) => {
            const u = user as User;
            setUserId(u.userId);
            setUserEnabled(u.talkdeskEnabled);
        });
    }
    function getIframeToggleSubscription(): Subscription {
        return talkdeskCtiService.iframeToggleMessages$().subscribe(() => {
            setVisible(!visible);
        });
    }
    function getGlobalMessageEventsSubscription(): Subscription {
        // this will emit every talkdesk automated task (auth, inbound started/ended, outbound started/ended)
        return fromEvent(window, 'message')
            .pipe(
                filter(validator.isMessageEvent),
                filter(validator.isFromTalkdesk)
                // , tap(e => validator.logEvent(e.data.action, e.data))
            )
            .subscribe((event) => {
                talkdeskCtiService.ctiMessages.next(event);

                if (props.inboundCallsEnabled) {
                    talkdeskInboundService.inboundCallEndedMessages.next(event);
                    talkdeskInboundService.inboundCallStartedMessages.next(event);
                }
                if (props.outboundCallsEnabled) {
                    talkdeskOutboundService.outboundCallEndedMessages.next(event);
                    talkdeskOutboundService.outboundCallStartedMessages.next(event);
                }
                if (props.loginReminders) {
                    talkdeskCtiService.userSessionMessages.next(event);
                }
            });
    }
    function getAuthenticationRequestSubscription(): Subscription {
        return talkdeskCtiService.externalIdMessages$().subscribe((_) => {
            setVisible(false);
            messenger.sendMessageEvent(window, factory.createGetExternalIdMessage(userId));
        });
    }
    function getClickToCallRequestsSubscription(): Subscription {
        return talkdeskOutboundService
            .outboundCallRequestedMessages$()
            .subscribe((outboundCallData) => {
                messenger.sendMessageEvent(
                    window,
                    factory.createStartOutboundCallMessage(userId, outboundCallData)
                );
            });
    }

    // handle subscriptions
    useEffect(() => {
        // load iframe, subscribe to:
        if (props.ctiEnabled) {
            subscriptions.push(
                // get current user to: check if it has access to talkdesk & get its externalId
                getCurrentUserSubscription(),
                // handle toggle events from the navigation menu
                getIframeToggleSubscription(),
                talkdeskInboundService.initialize()
            );
        }

        return () => {
            // clean up message handlers
            subscriptions.forEach((s) => s.unsubscribe());
        };
        // subscriptions won't affect the hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ctiEnabled, visible]);
    useEffect(() => {
        if (props.ctiEnabled && userEnabled) {
            subscriptions.push(
                // get the iframe URL from appsettings
                getIframeSrcSubscription(),
                // observe authentication requests & talkdesk automated tasks (if enabled)
                getGlobalMessageEventsSubscription(),
                // handle authentication requests (send current user's externalId to talkdesk)
                getAuthenticationRequestSubscription()
            );
            // handle AVA's click to call request
            if (props.outboundCallsEnabled) {
                subscriptions.push(
                    // send the request to talkdesk (this will launch the call in the callbar)
                    getClickToCallRequestsSubscription()
                );
            }
            if (props.loginReminders) {
                talkdeskCtiService.initLoginReminders();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.ctiEnabled,
        props.outboundCallsEnabled,
        props.inboundCallsEnabled,
        props.loginReminders,
        userId,
        userEnabled,
    ]);

    // HTML elements & behavior
    if (props.ctiEnabled === true && userEnabled)
        return (
            <div className={ visible
                ? 'talkdesk-container talkdesk-open'
                : 'talkdesk-container talkdesk-closed' }
            >
                <span className={ 'talkdesk-close-button' }>
                    <FontAwesomeIcon icon={faWindowClose} size="lg" onClick={_ => setVisible(false)} />
                </span>
                <iframe
                    id="talkdesk_cti"
                    src={ctiUrl}
                    className={ 'talkdesk-cti' }
                ></iframe>
            </div>
        );
    else return <></>;
}
