import React, { useState, useEffect } from 'react';
import { map } from 'rxjs/operators';
import { ajaxGet } from 'common/ajax';
import { notificationService } from 'subjects/common/NotificationService';

export default function Footer() {
    const [buildNumber, setBuildNumber] = useState(null);

    useEffect(() => {
        const ajaxSubscription = ajaxGet('api/Config/GetBuildNumber')
            .pipe(map((response) => response.response))
            .subscribe({
                next: (response) => {
                    setBuildNumber(response);
                },
                error: (error) => {
                    notificationService.error({ title: 'Product info could not be loaded', content: error ?? '' });
                },
            });

        return () => {
            ajaxSubscription.unsubscribe();
        };
    }, []);

    return (
        <footer className="main-footer" data-testid='footer'>
            <span className="footer__disclaimer">
                Confidential patient information; Proprietary trade secret information. Unauthorized use prohibited; All
                rights reserved by Alignment Healthcare.
            </span>
            <span className="footer__version">v{buildNumber}</span>
        </footer>
    );
}
