import React, { useEffect, useState } from 'react';
import { requests } from 'common/axios';
import { DOFRContract, DOFRContractTemplate, IPADetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { DOFR_CONTRACT_TAB_ID } from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContract.metadata';
import DOFRContractAssignmentsGrid from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContractAssignmentsGrid';
import DOFRContractsGrid from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContractsGrid';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { useToast } from '@hooks';

type DOFRContractTabProp = {
    ipa: IPADetail;
    isReadOnlyViewer: boolean;
};
const DOFRContractTab = ({ ipa, isReadOnlyViewer }: DOFRContractTabProp) => {
    const [loading, setLoading] = useState(false);
    const [selectedContract, setSelectedContract] = useState<DOFRContract | null>(null);
    const [pendingSelectionId, setPendingSelectionId] = useState('');
    const [templates, setTemplates] = useState<any>([]);
    const { showErrorToast } = useToast();

    const fetchTemplates = async () => {
        requests
            .get<DOFRContractTemplate[]>(`/api/DOFRContractTemplate/SearchTemplates`)
            .then((data) => setTemplates(data?.map((t) => ({ ...t, text: t.name, value: t.id }))))
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to fetch DOFR templates. Please try again later');
            });
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    return (
        <DisabledWrapper disabled={loading} withSpinner={loading}>
            <div id={DOFR_CONTRACT_TAB_ID} className="tab-content tabDiv p-3">
                <DOFRContractsGrid
                    isReadOnlyViewer={isReadOnlyViewer}
                    templates={templates}
                    ipa={ipa}
                    setLoading={setLoading}
                    selectedContract={selectedContract}
                    pendingSelectionId={pendingSelectionId}
                    setPendingSelectionId={setPendingSelectionId}
                    setSelectedContract={setSelectedContract}
                />

                {selectedContract && (
                    <>
                        <div className="mb-5" />
                        <DOFRContractAssignmentsGrid
                            isReadOnlyViewer={isReadOnlyViewer}
                            setLoading={setLoading}
                            selectedContract={selectedContract}
                            setPendingSelectionId={setPendingSelectionId}
                        />
                    </>
                )}
            </div>
        </DisabledWrapper>
    );
};

export default DOFRContractTab;
