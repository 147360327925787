import { useState } from "react";
import { map } from "rxjs";
import { ajaxGet, ajaxPost, ajaxPut } from "common/ajax";

export const requestTypes = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT'
}

export const useMakeRequest = () => {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const ajaxGetRequest = (url: string) => {
        setIsLoading(true);
        ajaxGet(url).pipe(map((ajaxResponse) => ajaxResponse.response))
        .subscribe({
            next: (response) => {
                setData(response);
                setError('');
                setIsLoading(false);
            },
            error: (error) => {
                setData(null);
                setError(error);
                setIsLoading(false);
            },
        });
    }

    const ajaxPostRequest = (url: string, payload: any) => {
        setIsLoading(true);
        ajaxPost(url, payload)
        .subscribe({
            next: (response) => {
                const data = response.response ?? response;
                setData(data);
                setError('');
                setIsLoading(false);
            },
            error: (error) => {
                setData(null);
                setError(error);
                setIsLoading(false);
            },
        });
    }

    const ajaxPutRequest = (url: string, data: any = null, responseType: XMLHttpRequestResponseType) => {
        setIsLoading(true);
        ajaxPut(url, data, responseType).subscribe({
            next: (response) => {
                const data = response.response ?? response;
                if (data) {
                    setError('');
                    setIsLoading(false);
                } else {
                    setError(error);
                    setIsLoading(false);
                }
            },
            error: (error) => {
                setError(error);
                setIsLoading(false);
            },
        });
    }

    const makeRequest = (type: string, url: string, payload: any, responseType: XMLHttpRequestResponseType = 'json') => {
        switch(type) {
            case requestTypes.GET:
                ajaxGetRequest(url);
                break;
            case requestTypes.POST:
                ajaxPostRequest(url, payload);
                break;
            case requestTypes.PUT:
                ajaxPutRequest(url, payload, responseType);
                break;
            default:
                setError("Invalid request");
        }
    }

    return [makeRequest, data, error, isLoading];
}