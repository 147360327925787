import { Subject } from 'rxjs';

const dialogSubject = new Subject();

const dialogPlaceholderService = {
    open: (type, dialogProps) => {
        dialogSubject.next({
            isOpening: true,
            type,
            dialogProps,
        });
    },
    close: () => {
        dialogSubject.next({
            isOpening: false,
        });
    },
    get: () => dialogSubject.asObservable(),
};

export default dialogPlaceholderService;
