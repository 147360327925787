import { ReactElement, useState } from 'react';

export const useMultiStepsForm = (stepElements: ReactElement[] | any) => {
    const [currentStep, setCurrentStep] = useState(0);

    function next() {
        setCurrentStep((i) => {
            if (i >= stepElements.length - 1) {
                return i;
            }

            return i + 1;
        });
    }

    function back() {
        setCurrentStep((i) => {
            if (i <= 0) {
                return i;
            }

            return i - 1;
        });
    }

    function goTo(step: number) {
        setCurrentStep(step);
    }

    return {
        steps: stepElements,
        currentStep,
        step: stepElements[currentStep],
        isFirstStep: currentStep === 0,
        isLastStep: currentStep === stepElements.length - 1,
        next,
        back,
        goTo,
    };
};
