import React, { useEffect, useRef, useState } from 'react';
import { ColumnChooser, GridComponent, Inject, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { requests } from 'common/axios';
import { DOFRContract, DOFRAssignment } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { showConfirmation } from 'components/ProviderData/IpaAdministration/atoms/utils';
import {
    BASE_URI,
    dofrAssignmentForm,
    dofrAssignmentsColumns,
    dofrAssignmentsToolbar,
    DOFRAssignmentsToolbarIds,
} from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContract.metadata';
import DOFRContractAssignmentForm from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContractAssignmentForm';
import { dofrService } from 'components/ProviderData/subjects/IpaAdministrationService';
import { useToast } from '@hooks';
import { Dialog } from 'interfaces/Dialog';
import { dialogService } from 'subjects/common/DialogService';

const TOOLBAR_IDS = [DOFRAssignmentsToolbarIds.New, DOFRAssignmentsToolbarIds.Edit, DOFRAssignmentsToolbarIds.Delete];

type DOFRAssignmentsGridProps = {
    isReadOnlyViewer: boolean;
    selectedContract: DOFRContract | null;
    setLoading: (loading: boolean) => void;
    setPendingSelectionId: (s: string) => void;
};
const DOFRAssignmentsGrid = ({
    isReadOnlyViewer,
    selectedContract,
    setLoading,
    setPendingSelectionId,
}: DOFRAssignmentsGridProps) => {
    const gridRef = useRef<GridComponent | null>(null);
    const [selectedAssignment, setSelectedAssignment] = useState<DOFRAssignment | null>(null);
    const [assignments, setAssignments] = useState<DOFRAssignment[]>([]);
    const { showErrorToast, showInfoToast, showSuccessToast } = useToast();

    const onToolbarClick = async (args: ClickEventArgs) => {
        switch (args.item.id) {
            case DOFRAssignmentsToolbarIds.New:
            case DOFRAssignmentsToolbarIds.Edit:
                return showDOFRAssignmentForm(args.item.id);
            case DOFRAssignmentsToolbarIds.Delete: {
                const shouldDelete = await showConfirmation({
                    message: 'Do you want to delete this DOFR Assignment?',
                    title: 'Delete DOFR Assignment',
                });
                if (shouldDelete)
                    selectedAssignment && onFormSubmit({ ...selectedAssignment, isDeleted: true }, 'delete');
            }
        }
    };

    const showDOFRAssignmentForm = (actionId: DOFRAssignmentsToolbarIds.New | DOFRAssignmentsToolbarIds.Edit) => {
        const isAdd = actionId === DOFRAssignmentsToolbarIds.New;
        if (!isAdd && !selectedAssignment) return showErrorToast('Please select an assignment first');
        const dialog: Dialog = {};
        dialog.header = `${isReadOnlyViewer ? 'Viewing' : isAdd ? 'Adding' : 'Editing'} DOFR Assignment`;
        dialog.buttons = isReadOnlyViewer
            ? null
            : dofrAssignmentForm.getButtons({
                  onSubmitClick() {
                      dofrService.submit(false);
                  },
                  onCancelClick() {
                      dialogService.close();
                  },
              });
        dialog.content = () => (
            <DOFRContractAssignmentForm
                isReadOnlyViewer={isReadOnlyViewer}
                isAdd={isAdd}
                contract={selectedContract}
                assignment={isAdd ? null : selectedAssignment}
                onSubmit={onFormSubmit}
                templateType={selectedContract?.templateType ?? ''}
            />
        );
        dialog.width = 900;
        dialogService.open(dialog);
    };

    const onFormSubmit = (assignment: DOFRAssignment | null, action: 'create' | 'update' | 'delete') => {
        if (!assignment) {
            dialogService.close();
            return showInfoToast('There are no changes to be saved');
        }
        const contract = { ...selectedContract };
        if (action === 'create') contract.assignments?.push(assignment);
        else contract.assignments = contract.assignments?.map((a) => (a.id === assignment.id ? assignment : a));
        dialogService.loading(true);
        setLoading(true);
        requests
            .put<DOFRContract>(`${BASE_URI}/${contract.id}`, { data: contract })
            .then((contract) => {
                setPendingSelectionId(contract.id);
                dialogService.close();
                showSuccessToast(`DOFR Assignment ${action}d`);
                dofrService.refresh();
            })
            .catch((e) => {
                console.error(e);
                showErrorToast(`Failed to ${action} DOFR assignment. Please try again later`);
            })
            .finally(() => {
                dialogService.loading(false);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!selectedContract) setSelectedAssignment(null);
        else setAssignments(selectedContract.assignments?.filter((a) => !a.isDeleted) ?? []);
    }, [selectedContract]);

    useEffect(() => {
        if (isReadOnlyViewer) return gridRef.current?.toolbarModule?.enableItems(TOOLBAR_IDS, false);
        if (selectedAssignment) {
            gridRef.current?.toolbarModule?.enableItems(TOOLBAR_IDS.slice(1), true);
        } else {
            gridRef.current?.toolbarModule?.enableItems(TOOLBAR_IDS.slice(1), false);
        }
    }, [selectedAssignment, isReadOnlyViewer]);

    return (
        <>
            <label className="gridTitle mb-1">Contract's Assignments</label>
            <GridComponent
                ref={gridRef}
                allowPaging
                allowSorting
                columns={dofrAssignmentsColumns}
                dataSource={assignments}
                height={320}
                rowHeight={40}
                recordDoubleClick={() => showDOFRAssignmentForm(DOFRAssignmentsToolbarIds.Edit)}
                rowSelected={({ data }) => setSelectedAssignment(data)}
                showColumnChooser
                toolbar={dofrAssignmentsToolbar}
                toolbarClick={onToolbarClick}
            >
                <Inject services={[ColumnChooser, Page, Sort, Toolbar]} />
            </GridComponent>
        </>
    );
};

export default DOFRAssignmentsGrid;
