import { useEffect, useState } from 'react';
import { map } from 'rxjs/operators';
import { configurationService } from '../subjects/crossModules/ConfigurationService';

export const useApplicationFlags = (applicationName) => {
    const [featureFlags, setFeatureFlags] = useState([]);

    // set how many hours feature flags will live in session cache before refreshing from the server
    const cacheDuration = 1;

    useEffect(() => {
        const flagsFromSession = getFeatureFlagsFromSessionStorage();

        if (!flagsFromSession || areFlagsExpired(flagsFromSession.lastUpdatedAt)) {
            const featureFlagsSubscription = getFeatureFlagsFromServer().subscribe((flags) => {
                setFeatureFlags(flags);
                const sessionFlags = {
                    lastUpdatedAt: new Date().getTime(),
                    flags,
                };
                window.sessionStorage.setItem(
                    `${applicationName.toLowerCase()}-feature-flags`,
                    JSON.stringify(sessionFlags),
                );
            });

            if (featureFlagsSubscription) {
                return featureFlagsSubscription.unsubscribe();
            }
        } else {
            setFeatureFlags(flagsFromSession.flags);
        }
    }, []);

    const areFlagsExpired = (lastUpdatedAt) => {
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() - cacheDuration);

        return lastUpdatedAt < expirationDate.getTime();
    };
    const getFeatureFlagsFromSessionStorage = () => {
        return JSON.parse(window.sessionStorage.getItem(`${applicationName.toLowerCase()}-feature-flags`));
    };

    const getFeatureFlagsFromServer = () => {
        return configurationService.getAminFlags$().pipe(
            map((response) => {
                const applications = response.response;
                let flags = applications.find((app) => app.name === applicationName)?.featureFlags;

                if (!flags) {
                    flags = [];
                }

                return flags;
            }),
        );
    };

    return [...featureFlags];
};
