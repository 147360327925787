const gapsInCare = [
    { id: 'hasHccAlerts', name: 'HCC Alerts' },
    { id: 'hasStarGaps', name: 'STAR' },
    { id: 'hasClinicalAlerts', name: 'Clinical Notifications' },
];

const socialDeterminants = [{ id: 'Homelessness', name: 'Homelessness' }];

const careAnywhereStatus = [
    { id: 'NotAssigned', name: 'All' },
    { id: 'Enrolled', name: 'Enrolled' },
    { id: 'Eligible', name: 'Eligible and not Enrolled' },
];

const clinicalPrograms = [
    { id: 'CareAnywhere', name: 'Care Anywhere' },
    { id: 'SNP', name: 'SNP' },
];

const atRiskScoreCategory = [
    { id: 'Healthy', name: 'Healthy' },
    { id: 'At Risk', name: 'At Risk' },
    { id: 'Polychronic 1', name: 'Polychronic 1' },
    { id: 'Polychronic 2', name: 'Polychronic 2' },
    { id: 'Polychronic 3', name: 'Polychronic 3' },
    { id: 'Advanced Illness', name: 'Advanced Illness' },
];

const admissionRiskScoreCategory = [
    { id: 'Low', name: 'Low' },
    { id: 'Medium', name: 'Medium' },
    { id: 'High', name: 'High' },
    { id: 'Very High', name: 'Very High' },
    { id: 'Extreme', name: 'Extreme' },
];

const memberStatus = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
];

const authorizationStatus = [
    { id: 'requested', name: 'Requested' },
    { id: 'approved', name: 'Approved' },
    { id: 'denied', name: 'Denied' },
    { id: 'cancelled', name: 'Cancelled' },
];

const authorizationCriteria = [
    { id: 'fromHere', name: 'Referred from here' },
    { id: 'toHere', name: 'Referred to here' },
    { id: 'PCP', name: 'This office is listed as PCP' },
];

const filterTypes = {
    gapsInCare,
    socialDeterminants,
    careAnywhereStatus,
    clinicalPrograms,
    atRiskScoreCategory,
    admissionRiskScoreCategory,
    memberStatus,
    authorizationStatus,
    authorizationCriteria,
};

const filterOptions = {
    Gaps: gapsInCare,
    SocialDeterminants: socialDeterminants,
    CareAnywhereStatus: careAnywhereStatus,
    ClinicalPrograms: clinicalPrograms,
    AtRiskCategory: atRiskScoreCategory,
    AdmissionRiskScoreCategory: admissionRiskScoreCategory,
    MemberStatus: memberStatus,
    authorizationStatus,
    authorizationCriteria,
};

function getFilterOptionsById(elementId) {
    return filterOptions[elementId] || [];
}

function getFields(type, elementId) {
    if (type) {
        if (['HPs', 'IPAs', 'PCPs', 'Providers', 'MSOs', 'Company'].includes(elementId)) {
            return { text: 'name', value: 'referenceId' };
        }

        if (type === 'simplearray') {
            return { text: '', value: '' };
        }
    }
    return { text: 'name', value: 'id' };
}

const pagingSize = 100;

export { filterTypes, getFilterOptionsById, getFields, pagingSize };
