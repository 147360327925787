import React from 'react';
import { ReactComponent as Logo } from './ahc-logo.svg';
import './LogoLoader.scss';

interface LogoLoaderProps {
    size: 'small' | 'large';
}

export const LogoLoader = ({ size }: LogoLoaderProps) => {
    return (
        <div
            style={{
                margin: '10% auto',
                display: 'block',
                width: size === 'large' ? '25%' : '10%',
            }}
        >
            <Logo />
        </div>
    );
};
