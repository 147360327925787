import { BehaviorSubject } from 'rxjs';

const tabChanges$ = new BehaviorSubject<string>('invalidId');

const TabLayoutService = {
    selectTab: (tabId: string) => tabChanges$.next(tabId),
    tabChanges$: () => tabChanges$,
};

export { TabLayoutService };
