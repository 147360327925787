import React, { useEffect, useRef, useState } from 'react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { TreeGridComponent, Inject as InjectToolbar, Toolbar, ExcelExport, Sort } from '@syncfusion/ej2-react-treegrid';
import moment from 'moment';
import { Row } from 'react-bootstrap';
import { requests } from 'components/AvaEzm/AxiosApi';
import { textDateValidation } from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { RiskPoolRateDetail, IPADetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import RiskPoolRateChangeHistoryGrid from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRateChangeHistoryGrid';
import { riskPoolRatesColumns } from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRates.Metadata';
import { RiskPoolRatesForm } from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRatesForm';
import { riskPoolRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import { useUnsubscribe } from '@hooks';
import { Dialog } from 'interfaces/Dialog';
import { dialogService } from 'subjects/common/DialogService';
import RiskPoolTieredRateForm from './RiskPoolTieredRateForm';

type RiskPoolProps = {
    ipaCode: string;
    ipaId?: number;
    ipa: IPADetail;
    isReadOnlyViewer: boolean;
};

const RiskPoolRatesTab = (props: RiskPoolProps) => {
    const { ipaCode, ipa, isReadOnlyViewer } = props;
    const { effDate, endDate, companyCode } = ipa;
    const [dataSource, setDataSource] = useState<RiskPoolRateDetail[]>([]);
    const toastRef = useRef<ToastComponent>(null);
    const treeGridRef = useRef<TreeGridComponent>(null);
    const pushSubscription = useUnsubscribe();
    const [pendingSelection, setPendingSelection] = useState<any>(null);
    const [capRevenues, setCapRevenues] = useState<any[]>([]);
    const [nextSequence, setNextSequence] = useState(1);
    const [RiskPoolRateDetail, setRiskPoolRateDetail] = useState<RiskPoolRateDetail>({} as RiskPoolRateDetail);
    const [prevDatesRiskPool, setPrevDatesRiskPool] = useState<
        { effectiveStartDate: Date | string; effectiveEndDate: Date | string }[] | null
    >(null);
    const [maxEndDate, setMaxEndDate] = useState<Date>();
    const formatData = (d, parentRate?: any) => ({
        ...d,
        sequence: d.memberCountStart ? `Range (${d.memberCountStart} - ${d.memberCountEnd})` : d.sequence,
        parentRateId: parentRate?.rateId,
    });
    const updateDataSource = async () => {
        const searchUrlRiskPoolRates = `/api/IpaRiskPoolRate/GetRiskPoolRates?IpaCode=${ipaCode}`;
        const data = await requests.get<any>(searchUrlRiskPoolRates);

        const formatedData = data
            .filter((d) => d.isDeleted == false)
            .sort((x, y) => y.sequence - x.sequence)
            .map(formatData)
            .map((d) => ({
                ...d,
                riskPoolTieredRates: d.riskPoolTieredRates
                    ?.filter((tieredRate) => tieredRate.isDeleted == false)
                    .map(formatData)
                    .sort((a, b) => b.rateId - a.rateId),
            }));

        const nextSeq =
            data && data.length > 0
                ? Math.max.apply(
                      null,
                      data.map((d) => d.sequence),
                  )
                : 0;

        const maxEffEndDate = new Date(
            Math.max(
                ...formatedData.map((d) => {
                    return new Date(d.effectiveEndDate);
                }),
            ),
        );

        setNextSequence(nextSeq + 1);
        setDataSource(formatedData);
        setMaxEndDate(maxEffEndDate);
    };

    const setPreviousRiskPoolDates = (row) => {
        const previousRiskPoolRate = dataSource.filter((d) => d.id !== row.id);
        let extractDates = {} as RiskPoolRateDetail[];
        if (row.parentItem) {
            extractDates = [row.parentItem];
        } else if (previousRiskPoolRate) {
            extractDates = previousRiskPoolRate;
        }
        setPrevDatesRiskPool(extractDates);
        return extractDates;
    };

    const setRiskPoolRateDetailRow = (row: any) => {
        let riskPoolData: any;
        if (row?.parentItem) {
            row.parentRateId = row.parentItem.rateId;
        }
        const parentRateId = row?.parentItem?.rateId ?? row.rateId;
        const selectedParentRate = dataSource.find((d) => d.rateId === parentRateId);

        dataSource?.forEach((p: RiskPoolRateDetail) => {
            if (p.id === row.id) riskPoolData = p;
            if (riskPoolData) return;
            p.riskPoolTieredRates?.forEach((c) => {
                if (c.id === row.id) {
                    riskPoolData = c;
                    return;
                }
            });
        });

        setRiskPoolRateDetail({
            ...riskPoolData,
            id: row.id,
            sequence: row.sequence,
            parentRateId: row?.parentItem?.rateId,
            isRiskPoolRate: row?.schemaName ? true : false,
            effectiveStartDate: row.effectiveStartDate,
            effectiveEndDate: row.effectiveEndDate,
            parentRateData: selectedParentRate,
        } as RiskPoolRateDetail);
        setPreviousRiskPoolDates(row);
    };

    const FormRiskPoolRates = ({ riskPoolRate, prevDatesRiskPool }) => {
        const _capRevenues = capRevenues.length <= 0 ? riskPoolRate.capRevenues : capRevenues;
        const _sequence = riskPoolRate?.isCopy ? riskPoolRate?.sequence : nextSequence;

        return riskPoolRate.isRiskPoolRate ? (
            <RiskPoolRatesForm
                nextSequence={_sequence}
                ipaCode={ipaCode}
                capRevenues={_capRevenues}
                riskPoolRate={riskPoolRate}
                ipaInfo={{ dates: { effDate, endDate } }}
                prevDatesRiskPool={prevDatesRiskPool}
                maxEndDate={maxEndDate}
                isReadOnlyViewer={isReadOnlyViewer}
                setPendingSelection={setPendingSelection}
            />
        ) : (
            <RiskPoolTieredRateForm
                data={riskPoolRate}
                isReadOnlyViewer={isReadOnlyViewer}
                setPendingSelection={setPendingSelection}
            />
        );
    };

    const toolbarClick = ({ item }) => {
        if (item.text === 'Create') {
            riskPoolRateService.add();
            const riskPoolRateDate = {
                sequence: nextSequence,
                ipaCode: ipaCode,
                rateId: 0,
                partitionKey: ipaCode,
                schemaName: 'IpaRiskPool',
                schemaVersion: '1.0',
                createdAt: new Date(),
                isRiskPoolRate: true,
            } as any;

            setRiskPoolRateDetail({ ...riskPoolRateDate, sequence: 0 });
            showCreateDialog(riskPoolRateDate, false);
        } else {
            const columnsToRemove = ['sequence', 'active', 'effectiveStartDatef', 'effectiveEndDatef'];
            const columnsToExport = riskPoolRatesColumns.filter((c) => !columnsToRemove.includes(c.field)) as any[];
            treeGridRef.current?.excelExport({
                includeHiddenColumn: true,
                fileName: 'RiskPoolRates-report.xlsx',
                columns: columnsToExport,
                header: {
                    headerRows: 1,
                    rows: [
                        {
                            cells: [
                                {
                                    colSpan: 12,
                                    value: 'Cap Rates Report',
                                    style: {
                                        fontColor: '#ffffff',
                                        backColor: '#00b3e3',
                                        fontSize: 25,
                                        hAlign: 'Center',
                                        bold: true,
                                    },
                                },
                            ],
                        },
                    ],
                },
            });
        }
    };

    const showCreateDialog = (_RiskPoolRate, isRiskPoolRateCreation) => {
        let _prevDatesRiskPool = setPreviousRiskPoolDates({
            sequenceNumber: Infinity,
            isRiskPoolRate: _RiskPoolRate.isRiskPoolRate,
        });
        if (isRiskPoolRateCreation) {
            const { effectiveStartDate, effectiveEndDate } = _RiskPoolRate;
            _prevDatesRiskPool = [{ effectiveStartDate, effectiveEndDate }] as RiskPoolRateDetail[];
        }
        const dialog: any = {};
        if (_RiskPoolRate.isRiskPoolRate) {
            dialog.header = _RiskPoolRate.isCopy ? 'Copy Risk Pool Rate' : 'Create Risk Pool Rate';
        } else {
            dialog.header = 'Create Risk Pool Tiered Rate';
        }

        const ipaEndDate = moment(ipa.endDate).startOf('day');
        const PrevEndDate = moment(_prevDatesRiskPool.find((f) => f)?.effectiveEndDate).startOf('day');
        const riskPoolRateEffDate = moment(_RiskPoolRate?.maxEndDate).startOf('day');
        dialog.buttons = [];
        dialog.content = () =>
            (ipaEndDate.isSame(PrevEndDate) && _RiskPoolRate.isRiskPoolRate) ||
            (ipaEndDate.isSame(riskPoolRateEffDate) && _RiskPoolRate?.isCopy) ? (
                textDateValidation
            ) : (
                <FormRiskPoolRates riskPoolRate={_RiskPoolRate} prevDatesRiskPool={_prevDatesRiskPool} />
            );
        dialog.width = 1000;
        dialogService.open(dialog);
    };

    const fetchCapRevenuesValues = async (companyCode: string, type: string) => {
        let _capRevenues = await requests.get<any[]>(
            `/api/CapRate/GetCapRevenueFormulas?companyCode=${companyCode}&type=${type}`,
        );
        _capRevenues = _capRevenues.map((c) => ({ ...c, text: `${c.code} - ${c.description}` }));
        if (type === 'CMS') {
            setCapRevenues(_capRevenues);
        }
    };

    const showChangeHistoryDialog = (riskPoolData) => {
        const dialog: Dialog = {};
        dialog.header = `Risk Pool ${riskPoolData.isRiskPoolRate ? '' : 'Tiered '}Rate - Change History`;
        dialog.width = 1000;
        dialog.content = () => (
            <RiskPoolRateChangeHistoryGrid
                isReadOnlyViewer={isReadOnlyViewer}
                riskPoolData={riskPoolData}
                setPendingSelection={setPendingSelection}
                approvalCallback={() => {
                    updateDataSource();
                    setRiskPoolRateDetail({} as RiskPoolRateDetail);
                }}
            />
        );
        dialogService.open(dialog);
    };

    useEffect(() => {
        fetchCapRevenuesValues(companyCode, 'CMS');
        updateDataSource();
        if (treeGridRef && isReadOnlyViewer) {
            // Disable toolbar items.
            treeGridRef.current?.toolbarModule.enableItems(
                [treeGridRef.current.element.id + '_gridcontrol_Create'],
                false,
            );
        }

        pushSubscription(
            riskPoolRateService.get$().subscribe(({ type, riskPoolRateData }) => {
                if (type === 'submit') {
                    const typesText = {
                        submit: 'Saved',
                    };
                    toastRef.current?.show({
                        title: 'Success !',
                        content: `Risk Pool Rate ${typesText[type]} Successfully`,
                        cssClass: 'e-toast-success',
                    });
                    updateDataSource();
                    setRiskPoolRateDetail({} as RiskPoolRateDetail);
                } else if (type === 'cancel') {
                    setRiskPoolRateDetail({} as RiskPoolRateDetail);
                    dialogService.close();
                } else if (type === 'addRiskPoolTieredRate' || type === 'copy') {
                    const _riskPoolRateData = {
                        ...riskPoolRateData,
                        isCopy: type === 'copy',
                        riskPoolTieredRates: [],
                    } as RiskPoolRateDetail;

                    setRiskPoolRateDetail({ ..._riskPoolRateData, sequence: 0 });
                    showCreateDialog(_riskPoolRateData, true);
                } else if (type === 'viewHistory') {
                    showChangeHistoryDialog(riskPoolRateData);
                }
            }),
        );
    }, [companyCode]);

    return (
        <div className="tab-content tabDiv p-3">
            <Row>
                <Row>
                    <TreeGridComponent
                        dataSource={dataSource}
                        dataBound={() => treeGridRef.current?.expandAll()}
                        expanded={() => {
                            if (pendingSelection) {
                                const visibleRecords = treeGridRef.current?.getVisibleRecords();
                                const index = visibleRecords?.findIndex((r: any) => r.id === pendingSelection.id);
                                typeof index === 'number' && treeGridRef.current?.selectRow(index);
                            }
                        }}
                        columns={riskPoolRatesColumns}
                        treeColumnIndex={0}
                        height="350"
                        childMapping="riskPoolTieredRates"
                        rowSelected={({ data }) => setRiskPoolRateDetailRow(data)}
                        selectionSettings={{ mode: 'Row', type: 'Single' }}
                        ref={treeGridRef}
                        toolbar={['Create']}
                        editSettings={{ allowAdding: true }}
                        toolbarClick={toolbarClick}
                        allowSelection={true}
                        allowExcelExport={true}
                        showColumnChooser={true}
                        allowSorting={true}
                    >
                        <InjectToolbar services={[Toolbar, Sort, ExcelExport]} />
                    </TreeGridComponent>
                </Row>
                <Row style={{ padding: '20px' }}>
                    {RiskPoolRateDetail?.sequence !== undefined && RiskPoolRateDetail?.sequence !== 0 ? (
                        <FormRiskPoolRates riskPoolRate={RiskPoolRateDetail} prevDatesRiskPool={prevDatesRiskPool} />
                    ) : null}
                </Row>
            </Row>
            <ToastComponent id="toast_target" ref={toastRef} title="" content="" position={{ X: 'Right', Y: 'Top' }} />
        </div>
    );
};

export default RiskPoolRatesTab;
