import { ItemModel, TabAnimationSettingsModel, TabModel } from '@syncfusion/ej2-react-navigations';
import { exportExcelService } from 'subjects/common/ExportMembersService';

// Tab Component
export const tabDisableAnimation: TabAnimationSettingsModel = {
    previous: { effect: 'None' },
    next: { effect: 'None' },
};

export const preventSwipe = (e) => {
    if (e.isSwiped) {
        e.cancel = true;
    }
};

export const excelExportIcon = 'e-excelexport';

export const exportExcel = {
    toolbarItem: {
        text: 'ExcelExport',
        align: 'Right',
    } as ItemModel,

    // if we want to change the text, we will have to generate unique ids ourselves
    generateToolbarItem: (text = 'Export to Excel', align = 'Right') =>
        ({
            text,
            align,
            prefixIcon: excelExportIcon,
            id: 'grid_excelexport' + Math.floor(Math.random() * 1000000),
        } as ItemModel),

    // hiddenColumns is an array of header texts (column.headerText)
    // we sometimes use the same field property for multiple columns. header text seems to be unique
    toolbarClick: (
        args,
        fileName,
        grid,
        hiddenColumns?: string[],
        url?: string,
        filterQueryString?: string,
        postBody?: any
    ) => {
        if (grid && args.item.prefixIcon === excelExportIcon) {
            const button = args.item.controlParent.activeEle;
            button.classList.add('loading', 'animatedBtn');
            grid.toolbarModule.enableItems([args.item.id], false);

            const callback = () => {
                //once the function is done, reenable the button
                button.classList.remove('loading');
                button.classList.add('success');
                setTimeout(() => {
                    button.classList.remove('success');
                    grid?.toolbarModule.enableItems([args.item.id], true);
                }, 2000);
            };

            // export server side if url is provided
            if (url) {
                let serverUrl = url;
                if (filterQueryString) {
                    serverUrl += filterQueryString;
                }
                exportExcelService.post(serverUrl, postBody ?? null, callback);
                return;
            }

            // hide columns so they don't export
            if (Array.isArray(hiddenColumns) && hiddenColumns.length > 0) {
                hiddenColumns.forEach((hiddenColumn) => {
                    const index = grid.columns.findIndex((column) => column.headerText === hiddenColumn);
                    if (index > -1) {
                        grid.columns[index].visible = false;
                    }
                });
            }

            grid.excelExport({ fileName })
                .then(() => {
                    callback();
                })
                .catch(() => {
                    button.classList.remove('loading');
                });
        }
    },
};

export const tabDefaultSettings: TabModel = {
    animation: tabDisableAnimation,
    selecting: preventSwipe,
};
