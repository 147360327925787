import { iif, of, switchMap, tap, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import Auth from 'Auth';
import { notificationService } from 'subjects/common/NotificationService';

type AjaxMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'OPTIONS' | 'DELETE';

const ajaxWithExpiredSessionHandler = <TResponse = any>(
    url: string,
    method: AjaxMethod,
    responseType: XMLHttpRequestResponseType,
    headers?: Record<string, any>,
    body?: any,
) => {
    const expiredSessionError$ = of(1).pipe(
        tap((_) =>
            notificationService.error({
                title: 'Session Expired',
                content: 'Please login again to re-authorize',
            }),
        ),
        switchMap((_) => throwError(() => new Error('Unauthorized Error'))),
    );

    const ajaxCall$ = ajax<TResponse>({
        url,
        method,
        responseType,
        headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
            ...headers,
        },
        body,
    });

    return iif(() => Auth.isExpired(), expiredSessionError$, ajaxCall$);
};

const ajaxGet = <TResponse = any>(url: string) => ajaxWithExpiredSessionHandler<TResponse>(url, 'GET', 'json');

const ajaxGetType = <TResponse = any>(url: string, responseType: XMLHttpRequestResponseType) =>
    ajaxWithExpiredSessionHandler<TResponse>(url, 'GET', responseType);

const ajaxPost = <TResponse = any>(url: string, data: any = null, responseType: XMLHttpRequestResponseType = 'json') =>
    ajaxWithExpiredSessionHandler<TResponse>(
        url,
        'POST',
        responseType,
        {
            'Content-Type': 'application/json',
        },
        JSON.stringify(data),
    );

// Headers will be empty because that let the browser could set it by default
const ajaxPostFormData = (url: string, data: any) => ajaxWithExpiredSessionHandler(url, 'POST', 'json', {}, data);

const ajaxPut = <TResponse = any>(url: string, data: any = null, responseType: XMLHttpRequestResponseType = 'json') =>
    ajaxWithExpiredSessionHandler<TResponse>(
        url,
        'PUT',
        responseType,
        {
            'Content-Type': 'application/json',
        },
        JSON.stringify(data),
    );

const ajaxPatch = <TResponse = any>(url: string, data: any = null, responseType: XMLHttpRequestResponseType = 'json') =>
    ajaxWithExpiredSessionHandler<TResponse>(
        url,
        'PATCH',
        responseType,
        {
            'Content-Type': 'application/json',
        },
        JSON.stringify(data),
    );

const ajaxDelete = <TResponse = any>(url: string, data: any = null) =>
    data
        ? ajaxWithExpiredSessionHandler<TResponse>(
              url,
              'DELETE',
              'json',
              {
                  'Content-Type': 'application/json',
              },
              JSON.stringify(data),
          )
        : ajaxWithExpiredSessionHandler(url, 'DELETE', 'json');

export { ajaxPost, ajaxGet, ajaxGetType, ajaxDelete, ajaxPut, ajaxPatch, ajaxPostFormData };
