import { ColumnModel as GridColumnModel } from '@syncfusion/ej2-react-grids';
import { ColumnModel as TreeGridColumnModel } from '@syncfusion/ej2-react-treegrid';
import { GainShareRateDetail } from 'components/ProviderData/IpaAdministration/atoms/Models';

export const TEXTBOX_ATTRS = { showSpinButton: false, validateDecimalOnType: true, min: 0, strictMode: true };

export const DECIMAL_4 = {
    ...TEXTBOX_ATTRS,
    placeholder: '0.0000',
    format: '0.0000',
    decimals: 4,
};

export const DECIMAL_2 = {
    ...TEXTBOX_ATTRS,
    placeholder: '0000.00',
    format: '0.00',
    decimals: 2,
};

export const BASE_URI = 'api/IpaGainShareRate';

export enum HistoryViewMode {
    Pending = 'Pending',
    All = 'All',
}

export const gainShareChangeHistoryColumns: (GridColumnModel & { field: keyof GainShareChangeHistory })[] = [
    {
        field: 'name',
        headerText: 'Field Name',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
        valueAccessor: (_, data) => GainShareLabel[data['name']] ?? data['name'],
    },
    {
        field: 'currentValue',
        headerText: 'Updated From',
        width: 200,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'newValue',
        headerText: 'Updated To',
        width: 200,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'updatedBy',
        headerText: 'Updated By',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'updatedAt',
        headerText: 'Updated On',
        sortComparer: (a, b) => new Date(b as string).getTime() - new Date(a as string).getTime(),
    },
    {
        field: 'approvedBy',
        headerText: 'Approved By',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'approvedAt',
        headerText: 'Approved On',
        clipMode: 'EllipsisWithTooltip',
        type: 'DateTime',
        format: 'MM/dd/yyyy hh:mm:ss a',
        autoFit: true,
    },
];

export const gainShareRatesColumns: (TreeGridColumnModel & { field: keyof GainShareRateDetail })[] = [
    {
        field: 'sequence',
        width: 170,
        headerText: 'Sequence',
        type: 'text',
    },
    {
        field: 'status',
        width: 100,
        headerText: 'Status',
        type: 'text',
    },
    {
        field: 'rateId',
        width: 80,
        headerText: 'ID',
        type: 'text',
    },
    {
        field: 'effectiveStartDate',
        width: 140,
        headerText: 'Effective Date',
        type: 'date',
        format: 'MM/dd/yyyy',
    },
    {
        field: 'effectiveEndDate',
        width: 120,
        headerText: 'End Date',
        type: 'date',
        format: 'MM/dd/yyyy',
    },
    {
        field: 'createdById',
        width: 120,
        headerText: 'Created By',
        type: 'text',
    },
    {
        field: 'createdAt',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        format: 'MM/dd/yyyy hh:mm:ss',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'lastUpdatedById',
        width: 120,
        headerText: 'Updated By',
        type: 'text',
    },
    {
        field: 'lastUpdatedAt',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        format: 'MM/dd/yyyy hh:mm:ss',
        clipMode: 'EllipsisWithTooltip',
    },
];

export const defaultValuesGainShareRates = {
    profitSharePercent: 0,
    revenuePercent: 0,
    partCRevenuePmPm: 0,
    partDRevenuePmPm: 0,
    effectiveStartDate: new Date(),
    effectiveEndDate: new Date(),
    isPartDExclusion: false,
    isDeficitCarryover: false,
    isSupplementalPmPm: false,
    isClinicalModelPmPm: false,
    isSgaRevenuePercent: false,
    sgaRevenuePercent: 0,
    isSgaPmPm: false,
    sgaPmPm: 0,
    notes: '',
    isDeleted: false,
    gainShareRateTiers: [],
};

export const defaultValuesGainShareTieredRates = {
    profitSharePercent: 0,
    revenuePercent: 0,
    partCRevenuePmPm: 0,
    partDRevenuePmPm: 0,
    effectiveStartDate: new Date(),
    effectiveEndDate: new Date(),
    isPartDExclusion: false,
    isDeficitCarryover: false,
    isSupplementalPmPm: false,
    isClinicalModelPmPm: false,
    isSgaRevenuePercent: false,
    sgaRevenuePercent: 0,
    isSgaPmPm: false,
    sgaPmPm: 0,
    notes: '',
    id: '',
    isDeleted: false,
};

export enum GainShareLabel {
    capRevenue = 'Cap Revenue',
    ipaCode = 'IPA Code',
    profitSharePercent = 'Profit Share %',
    revenuePercent = 'Revenue Share %',
    partCRevenuePmPm = 'PartC Revenue PMPM',
    partDRevenuePmPm = 'PartD Revenue PMPM',
    effectiveStartDate = 'Effective Date',
    effectiveEndDate = 'End Date',
    isPartDExclusion = 'Part D Exclusion?',
    isDeficitCarryover = 'Deficit Carryover?',
    isSupplementalPmPm = 'Supplemental PMPM',
    isClinicalModelPmPm = 'Clinical Model PMPM',
    sgaRevenuePercent = 'SG&A Revenue %',
    sgaPmPm = 'SG&A PMPM',
    notes = 'Notes',
    status = 'Status',
    memberCountStart = 'Member Count Start',
    memberCountEnd = 'Member Count End',
    sequence = 'Sequence',
}

export interface GainShareChangeHistory {
    name: string | null;
    currentValue: string | null;
    newValue: string | null;
    updatedAt: string | null;
    updatedBy: string | null;
    approvedAt: string | null;
    approvedBy: string | null;
}
