import { requests } from 'common/axios';
import { FeatureFlag } from 'interfaces/FeatureFlag';
import {
    IsActiveIPARequest,
    IsActiveIPAResponse,
    PCPDetailsRequest,
    ProviderSearchParameters,
    SubmissionType,
} from './MemberEnrollmentDetails/Model';

const baseUrl = 'api/MemberEnrollment';
const lookupBaseUrl = 'api/MemberEnrollmentLookup';
const statisticBaseUrl = 'api/MemberEnrollmentStatistic';
const documentBaseUrl = 'api/MemberEnrollmentDocument';
const configurationBaseUrl = 'api/MemberEnrollmentConfiguration';
const auditHistoryBaseUrl = 'api/MemberEnrollmentAuditHistory';

export const MemberEnrollmentApi = {
       GetFaxServerData: (params: any): Promise<any> =>
           requests.get(`${statisticBaseUrl}/GetWorklistStatusCount`, params),

    GetPCPReasonCodes: (): Promise<FeatureFlag[]> =>
        requests.get(`${configurationBaseUrl}/GetDescriptionsByPartition?partitionName=
LetterReasonCode`),

    GetFeatureFlags: (): Promise<FeatureFlag[]> =>
        requests.get(`${configurationBaseUrl}/GetMemberEnrollmentFeatureFlags`),

    GetSourceFeatureFlags: (): Promise<FeatureFlag[]> =>
        requests.get(`${configurationBaseUrl}/GetAVAEnrollmentFeatureFlags`),

    UploadOntoExistingEnrollmentApplication: (params: any): Promise<any> =>
        requests.post(`${documentBaseUrl}/UploadOntoExistingEnrollmentApplication`, params),

    SearchMemberEnrollments: (params: any): Promise<any> => requests.get(`${baseUrl}/SearchEnrollments`, params),

    GetWorklistStatusCount: (params: any): Promise<any> =>
        requests.get(`${statisticBaseUrl}/GetWorklistStatusCount`, params),

    ReassignEnrollment: (data: any, params: any): Promise<any> =>
        requests.put(`${baseUrl}/ReassignEnrollment`, data, params),

    GetMemberEnrollmentDetailsById: (params: any): Promise<any> =>
        requests.get(`${baseUrl}/GetMemberEnrollmentDetailsById`, params),

    GetEnrollmentReviewers: (params: any): Promise<any> =>
        requests.get(`${lookupBaseUrl}/FetchEnrollmentReviewers`, params),

    GetEnrollmentManagers: (): Promise<any> => requests.get(`${lookupBaseUrl}/GetEnrollmentManagers`),

    GetIsBuyupOption: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetIsOptionalBuyUp`, params),

    CheckIsEnrollmentProcessorManager: (): Promise<any> =>
        requests.get(`${lookupBaseUrl}/CheckIsEnrollmentProcessorManager`),

    CheckIsEnrollmentApplicationViewer: (): Promise<any> =>
        requests.get(`${lookupBaseUrl}/CheckIsEnrollmentApplicationViewer`),

    GetUserGroups: (): Promise<any> => requests.get(`${lookupBaseUrl}/GetUserGroups`),

    CheckDuplicateMember: (params: any): Promise<any> => requests.get(`${baseUrl}/CheckDuplicateMember`, params),

    GetEligibilities: (params: any): Promise<any> => requests.get(`${baseUrl}/GetEligibilities`, params),

    UploadNewEnrollmentApplicationFile: (params: any): Promise<any> =>
        requests.post(`${documentBaseUrl}/UploadNewEnrollmentApplicationFile`, params),

    GetZipCodeCity: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetZipCodeCity`, params),

    GetMailingZipCodeCity: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetZipCodeCity`, params),

    GetCountyByStateAndZip: (params: any): Promise<any> =>
        requests.get(`${lookupBaseUrl}/GetCountyByStateAndZip`, params),

    GetAllPlansByYear: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetAllPlansByYear`, params),

    GetAllBuyupByPlans: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetAllBuyUpByPlans`, params),

    ContractPBPCodeDetails: (params: any): Promise<any> =>
        requests.get(`${lookupBaseUrl}/ContractPBPCodeDetails`, params),

    GetActiveContractPBPCodeDetails: (params: any): Promise<any> =>
        requests.get(`${lookupBaseUrl}/GetActiveContractPBPCodeDetails`, params),

    HPOptionDetails: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/HPOptionDetails`, params),

    GetContractCodeList: (): Promise<any> => requests.get(`${lookupBaseUrl}/GetContractCodeList`),

    DownloadEnrollmentDocuments: (params: any): Promise<any> =>
        requests.get(`${documentBaseUrl}/DownloadEnrollmentDocuments`, params),

    GetDescriptionsByPartition: (params: any): Promise<any> =>
        requests.get(`${configurationBaseUrl}/GetDescriptionsByPartition`, params),

    GetSalesRepDetails: (params: any): Promise<any> => requests.get(`${baseUrl}/GetSalesRepDetails`, params),

    GetSalesRepLookupDetails: (params: any): Promise<any> =>
        requests.get(`${lookupBaseUrl}/GetSalesRepLookupList`, params),

    GetSalesTypeLookupDetails: (params: any): Promise<any> =>
        requests.get(`${configurationBaseUrl}/GetSalesTypes`, params),

    GetReferenceBrokerDetails: (params: any): Promise<any> =>
        requests.get(`${baseUrl}/GetReferenceBrokerDetails`, params),

    GetRefBrokerByIDDetails: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetRefBroker`, params),

    GetHealthPlans: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetHealthPlans`, params),

    GetGender: (): Promise<any> => requests.get(`${lookupBaseUrl}/Gender`),

    GetSelfIdentify: (): Promise<any> => requests.get(`${lookupBaseUrl}/SelfIdentify`),

    SaveEnrollment: (data: any): Promise<any> => requests.put(`${baseUrl}/SaveMemberEnrollment`, data),

    SubmitAndExit: (data: any, submissionType: number): Promise<any> =>
        submissionType === SubmissionType.SubmitInvalidMemberPortal
            ? requests.post(`${baseUrl}/SaveInvalidMemberPortal`, data)
            : requests.post(`${baseUrl}/SubmitEnrollment`, data),

    GetEnrollmentDocumentDetails: (params: any): Promise<any> =>
        requests.get(`${baseUrl}/GetEnrollmentDocumentDetails`, params),

    GetState: (params: any): Promise<any> => requests.get(`${lookupBaseUrl}/GetState`, params),

    GetMailingStateAndCity: (params: any): Promise<any> =>
        requests.get(`${lookupBaseUrl}/GetMailingStateAndCity`, params),

    VerifyMemberAsync: (data: any): Promise<any> => requests.put(`${baseUrl}/VerifyMember`, data),

    UpdateEnrollmentProcessingStatus: (data: any, params: any): Promise<any> =>
        requests.put(`${baseUrl}/UpdateEnrollmentProcessingStatus`, data, params),

    SearchPCPs: (params: ProviderSearchParameters): Promise<any> =>
        requests.get(`${lookupBaseUrl}/SearchProviders`, params),

    GetPCPDetails: (params: PCPDetailsRequest): Promise<any> => requests.get(`${baseUrl}/GetPcpDetails`, params),

    GetIsActiveIPA: (params: IsActiveIPARequest): Promise<IsActiveIPAResponse> =>
        requests.get(`${lookupBaseUrl}/IsActiveIPA`, params),

    GetDocumentApiBaseUrl: () => {
        return documentBaseUrl;
    },
    UpdateDsnpDetails: (data: any, params: { id?: string }): Promise<any> =>
        requests.put(`${baseUrl}/UpdateDsnpDetails`, data, params),

    CheckUserEnrollmentAccess: (params: any): Promise<any> =>
        requests.get(`${lookupBaseUrl}/CheckUserEnrollmentAccess`, params),

    GetDuplicateEnrollments: (params: any): Promise<any> =>
        requests.get(`${baseUrl}/CheckDuplicateEnrollments`, params),

    GetPoaState: (): Promise<any> => requests.get(`${lookupBaseUrl}/GetPoaState`),

    syncData: (params: any): Promise<any> => requests.post(`${documentBaseUrl}/SyncWebEnrollmentRecords`, params),

    GetVoiceRecordongs: (params: any): Promise<any> => requests.get(`${baseUrl}/GetVoiceRecordingDetails`, params),

    validateAddress: (params: any): Promise<any> =>
        requests.get(`api/MemberEnrollmentValidation/ValidateAddress`, params),

    GetChangeHistory: (params: any): Promise<any> =>
        requests.get(`${baseUrl}/GetMemberEnrollmentChangeHistoryByEnrollmentId`, params),

    SendEnrollmentsToServiceBus: (data: any): Promise<any> =>
        requests.post(`${baseUrl}/SendEnrollmentsToServiceBus`, data),

    resetEnrollmentStatus: (data: any): Promise<any> => requests.post(`${baseUrl}/ResetEnrollmentStatus`, data),

    GetLockedEnrollments: (): Promise<any> => requests.get(`${baseUrl}/GetLockedWebEnrollments`),

    GetMemberEnrollmentProcessingAuditHistory: (params: any): Promise<any> => {
        const query = new URLSearchParams(params);
        return requests.post(`${auditHistoryBaseUrl}/GetMemberEnrollmentProcessingAuditHistory?${query}`, params)
    },

    GetMemberEnrollmentAuditHistoryFeatureCount: (params: any): Promise<any> => {
        const query = new URLSearchParams(params);
        return requests.post(`${auditHistoryBaseUrl}/GetMemberEnrollmentAuditHistoryFeatureCount?${query}`, params)
    },

    GetMemberEnrollmentProcessingFaxHistory: (data: any): Promise<any> => {
        return requests.post(`${auditHistoryBaseUrl}/GetFaxEnrollmentTransactionHistory`, data)
    },
};
