import React from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, showSpinner, hideSpinner, createSpinner } from '@syncfusion/ej2-react-popups';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import _uniqueId from 'lodash/uniqueId';
import * as ReactDOM from 'react-dom';
import { dialogService, dialogOpenedService, dialogClosedSubject, dialogCloseSubject } from 'subjects/common/DialogService';

const spinnerPositionRight = { position: 'Right' };

export default class GlobalDialog extends React.Component {
    constructor(prop) {
        super(prop);

        this.state = {
            buttons: [{}],
            position: { X: 'center', Y: 'center' },
            content: null,
            footerButtons: null,
            isLoading: false,
            beforeCloseHandler: null,
        };

        this.hasSpinnerButton = false;
    }

    componentDidMount() {
        this.loadingSubscription = dialogService.isLoading$.subscribe({
            next: (isLoading) => {
                const targetElement = document.querySelector('#globalDialog');
                if (!targetElement) return;
                if (isLoading) {
                    showSpinner(targetElement);
                    this.setState({ isLoading: true });
                } else {
                    hideSpinner(targetElement);
                    this.setState({ isLoading: false });
                }
            },
        });
        this.subscription = dialogService.get().subscribe({
            next: ({ header, content, buttons, height, width, position, contentId, beforeCloseHandler }) => {
                let customButtons = null;

                if (position) {
                    this.dialog.position = position;
                    this.dialog.refreshPosition();
                }
                this.hasSpinnerButton =
                    buttons && buttons.find((element) => element.buttonModel.spinnerButton) ? true : false;

                if (this.hasSpinnerButton) {
                    const builtInCloseButton = document.querySelector('#globalDialog_dialog-header > button');
                    const buttonsRefArray = [];
                    customButtons = buttons.map((button, index) => {
                        const buttonRefName = _uniqueId('spinner-button-menu-');
                        buttonsRefArray.push(buttonRefName);
                        if (button.buttonModel.spinnerButton) {
                            return (
                                <ProgressButtonComponent
                                    type="button"
                                    key={index}
                                    iconCss={button.buttonModel.iconCss}
                                    cssClass={button.buttonModel.cssClass + ' e-flat'}
                                    spinSettings={spinnerPositionRight}
                                    disabled={this.state.isLoading}
                                    ref={(ref) => {
                                        this[buttonRefName] = ref;
                                    }}
                                    progress={0}
                                    onClick={() => {
                                        if (this[buttonRefName].disabled) {
                                            return;
                                        }
                                        builtInCloseButton.disabled = true;
                                        buttonsRefArray.forEach((element) => {
                                            this[element].disabled = true;
                                        });
                                        button.click(() => {
                                            if (this[buttonRefName]) {
                                                builtInCloseButton.disabled = false;
                                                this[buttonRefName].progressComplete();
                                                buttonsRefArray.forEach((element) => {
                                                    this[element].disabled = false;
                                                });
                                            }
                                        });
                                    }}
                                >
                                    {button.buttonModel.content}
                                </ProgressButtonComponent>
                            );
                        } else {
                            return (
                                <ButtonComponent
                                    type="button"
                                    key={index}
                                    iconCss={button.buttonModel.iconCss}
                                    cssClass={button.buttonModel.cssClass + ' e-flat'}
                                    disabled={this.state.isLoading}
                                    ref={(ref) => {
                                        this[buttonRefName] = ref;
                                    }}
                                    onClick={() => {
                                        if (this[buttonRefName].disabled || this.state.isLoading) {
                                            return;
                                        }
                                        button.click();
                                    }}
                                >
                                    {button.buttonModel.content}
                                </ButtonComponent>
                            );
                        }
                    });

                    builtInCloseButton.addEventListener('focus', (e) => {
                        builtInCloseButton.blur();
                    });
                }

                this.setState({
                    header,
                    content: typeof content === 'function' ? content() : null,
                    buttons: this.hasSpinnerButton ? null : buttons,
                    width,
                    height,
                    footerButtons: <div className="e-footer-content">{customButtons}</div>,
                    contentId: contentId ?? '',
                    beforeCloseHandler: beforeCloseHandler ?? null,
                });
            },
        });

        dialogService.getButtonUpdates().subscribe({
            next: (buttons) => {
                this.setState({ buttons });
            },
        });

        dialogService.dialogUpdated().subscribe({
            next: (newContent) => {
                this.setState({ content: typeof newContent === 'function' ? newContent() : null });
            },
        });

        this.closeSubscription = dialogCloseSubject.subscribe({
            next: () => {
                // this will fire automatically the close method
                this.dialog.hide();
                this.setState({
                    footerButtons: null,
                });
            },
        });

        createSpinner({
            target: document.querySelector('#globalDialog'),
        });

        document.onclick = (args) => {
            if (args.target.className === 'e-dlg-overlay') {
                this.dialog.hide();
            }
        };
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
        this.loadingSubscription.unsubscribe();
    }

    onClose = () => {
        if (this.dialog) {
            this.setState({
                content: null,
                isLoading: false,
            });
        }
        dialogClosedSubject.next(this.state.contentId);
    };

    dialogOpen() {
        // The below service will let subscribed children know when the dialog opens,
        // since some SyncFusion components need to refresh UI if they are
        // placed within a Dialog(modal), e.g. the RichTextEditor, a bug arises
        // if the mentioned editor lives in the DialogComponent.
        // More details here: https://support.syncfusion.com/support/tickets/418903
        dialogOpenedService.update();
    }

    render() {
        const defaultPosition = { X: 'center', Y: 'center' };
        const element = (
            <DialogComponent
                key="globaldialog"
                ref={(dialog) => (this.dialog = dialog)}
                id={'globalDialog'}
                cssClass={'globalDialog'}
                showCloseIcon={true}
                width={this.state.width || '800'}
                height={this.state.height || 'auto'}
                isModal={true}
                position={this.state.position || defaultPosition}
                visible={this.state.content}
                allowDragging={true}
                close={this.onClose}
                header={this.state.header}
                contentId={this.state.contentId}
                buttons={this.state.buttons}
                open={this.dialogOpen.bind(this)}
                beforeClose={this.state.beforeCloseHandler}
                zIndex={1100}
            >
                <div data-testid='global-dialog'>{this.state.content}</div>
                {this.hasSpinnerButton && this.state.footerButtons}
            </DialogComponent>
        );
        return ReactDOM.createPortal(element, document.body);
    }
}
