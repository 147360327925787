import { Observable, Subject } from 'rxjs';
const closeTabSubject = new Subject<void>();

const tabService = {
    close: (tabId: any = undefined) => {
        closeTabSubject.next(tabId);
    },
    get$: (): Observable<void> => closeTabSubject.asObservable(),
};

export { tabService };
