import React, { useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { ControlledSyncfusionDatePicker } from 'components/Common/SyncfusionWrappers/Syncfusion';
import { DOFRContract, DOFRContractTemplate } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { dofrContractForm } from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContract.metadata';
import { dofrService } from 'components/ProviderData/subjects/IpaAdministrationService';
import { useUnsubscribe } from '@hooks';

type DOFRContractFormProps = {
    isReadOnlyViewer: boolean;
    isAdd?: boolean;
    ipaCode: string;
    dateBoundaries: { min: Date; max: Date };
    contract?: DOFRContract | null;
    templates: DOFRContractTemplate[];
    onSubmit: (data: DOFRContract | null) => void;
};
const DOFRContractForm = ({
    isReadOnlyViewer,
    isAdd = false,
    ipaCode,
    dateBoundaries: { min, max },
    contract = null,
    templates,
    onSubmit,
}: DOFRContractFormProps) => {
    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm<DOFRContract>();
    const pushSubscription = useUnsubscribe();
    const [effectiveBeginDate, effectiveEndDate] = watch(['effectiveBeginDate', 'effectiveEndDate']);

    useEffect(() => {
        setValue('isAdd', isAdd);
        setValue('ipaCode', ipaCode);
    }, [isAdd, ipaCode, setValue]);

    useEffect(() => {
        if (!isAdd && contract)
            reset({
                ...contract,
                effectiveBeginDate: moment(contract.effectiveBeginDate).toDate(),
                effectiveEndDate: moment(contract.effectiveEndDate).toDate(),
            });
    }, [isAdd, contract, reset]);

    useEffect(() => {
        pushSubscription(
            dofrService.get$().subscribe(({ type, isContract }) => {
                if (isContract && type === 'submit') {
                    handleSubmit((formData) => {
                        const data = Object.keys(dirtyFields).length <= 0 ? null : { ...formData, isAdd };
                        onSubmit(data);
                    })();
                }
            }),
        );
    }, [handleSubmit, dirtyFields, isAdd, onSubmit]);

    return (
        <form>
            <fieldset disabled={isReadOnlyViewer}>
                <Row className="mb-3">
                    <Col md={12}>
                        <label className="mb-1 fw-bolder">Template Name</label>
                        <Controller
                            name="templateName"
                            control={control}
                            rules={{ required: 'This field is required' }}
                            render={({ field: { onChange, value } }) => (
                                <DropDownListComponent
                                    fields={{ text: 'text', value: 'name' }}
                                    dataSource={templates as any}
                                    enabled={isAdd}
                                    placeholder="Select a template"
                                    value={value}
                                    onChange={(args) => {
                                        if (isAdd) {
                                            const selectedTemplate = args.target?.itemData as DOFRContractTemplate;
                                            if (selectedTemplate) {
                                                setValue('assignments', selectedTemplate.assignments);
                                                setValue('templateType', selectedTemplate.templateType);
                                                setValue('id', selectedTemplate.id);
                                            }
                                        }
                                        onChange(args.value);
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="templateName"
                            render={({ message }) => <span className="error-form">{message}</span>}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <label className="mb-1 fw-bolder">Start Date</label>
                        <TooltipComponent content={`Min Date: ${moment(min).format('MM/DD/yyyy')}`}>
                            <ControlledSyncfusionDatePicker
                                name="effectiveBeginDate"
                                control={control}
                                isRetrievingDateAsString={true}
                                dpAttrs={{
                                    ...dofrContractForm.datePickerAttrs,
                                    min: new Date(min),
                                    max: new Date(
                                        moment(effectiveEndDate ?? max)
                                            .subtract(1, 'days')
                                            .toISOString(),
                                    ),
                                }}
                                rules={{ required: 'This field is required' }}
                            />
                        </TooltipComponent>
                        <ErrorMessage
                            errors={errors}
                            name="effectiveBeginDate"
                            render={({ message }) => <span className="error-form">{message}</span>}
                        />
                    </Col>
                    <Col md={6}>
                        <label className="mb-1 fw-bolder">End Date</label>
                        <TooltipComponent content={`Max Date: ${moment(max).format('MM/DD/yyyy')}`}>
                            <ControlledSyncfusionDatePicker
                                name="effectiveEndDate"
                                control={control}
                                isRetrievingDateAsString={true}
                                dpAttrs={{
                                    ...dofrContractForm.datePickerAttrs,
                                    min: new Date(moment(effectiveBeginDate).add(1, 'days').toISOString()),
                                    max: new Date(max),
                                }}
                                rules={{ required: 'This field is required' }}
                            />
                        </TooltipComponent>
                        <ErrorMessage
                            errors={errors}
                            name="effectiveEndDate"
                            render={({ message }) => <span className="error-form">{message}</span>}
                        />
                    </Col>
                </Row>
            </fieldset>
        </form>
    );
};

export default DOFRContractForm;
