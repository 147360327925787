import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Notepad from './Notepad';
import { BeforeOpenEventArgs } from '@syncfusion/ej2-popups/src/dialog/dialog';

interface CallNotesDialogProps {
    talkdeskId: string;
    target: string;
    header: string;
    visible: boolean;
    setVisible: (visible: boolean) => void;
    notes?: string | null;
}

export default function CallNotesDialog({ talkdeskId, target, header, visible, setVisible, notes }: CallNotesDialogProps) {
    // syncfusion was setting the maxHeight to 236;
    const beforeOpen = (args: BeforeOpenEventArgs) => (args.maxHeight = 'unset');

    return (
        <DialogComponent
            target={target}
            visible={visible}
            width="800"
            isModal={false}
            showCloseIcon={false}
            close={() => setVisible(false)}
            beforeOpen={beforeOpen}
            position={{ X: 'right', Y: 'top' }}
            allowDragging={true}
            cssClass={`popup-window`}
            zIndex={1001}
            buttons={[{}]}
            closeOnEscape={false}
        >
            <Notepad talkdeskId={talkdeskId} notes={notes} header={header} setVisible={setVisible} />
        </DialogComponent>
    );
}
