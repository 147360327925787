import { useEffect, useRef } from 'react';

export default function useInterval(callback: () => void, active: boolean) {
    const savedCallback = useRef<() => void>();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => savedCallback.current && savedCallback.current();
        const interval = active ? setInterval(tick, 1000) : null;
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [active]);
}
