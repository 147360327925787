import { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Auth from 'Auth';
import { requests } from 'common/axios';
import { formatISOLocalDateTime } from 'common/widgets';
import {
    ControlledSyncfusionCheckBox,
    ControlledSyncfusionDatePicker,
    ControlledSyncfusionNumericTextBox,
    ControlledSyncfusionTextBox,
} from 'components/Common/SyncfusionWrappers/Syncfusion';
import { GainShareRateDetail, GainShareTieredRate } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { showConfirmation } from 'components/ProviderData/IpaAdministration/atoms/utils';
import {
    BASE_URI,
    GainShareLabel,
} from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/GainShareRates.Metadata';
import styles from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/GainShareRates.module.scss';
import { gainShareRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { SectionHeader } from 'components/UI/BoxSection';
import { useToast } from '@hooks';
import { dialogService } from 'subjects/common/DialogService';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const CREATE_ENDPOINT = '/CreateGainShareTieredRate';
const UPDATE_ENDPOINT = '/UpdateGainShareTieredRate';

type GainShareTieredRateFormProps = {
    data: GainShareTieredRate;
    isReadOnlyViewer: boolean;
    setPendingSelection: (data) => void;
};
const GainShareTieredRateForm = ({ data, isReadOnlyViewer, setPendingSelection }: GainShareTieredRateFormProps) => {
    const {
        control,
        formState: { errors, isDirty, dirtyFields },
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors,
        watch,
        getValues,
    } = useForm<GainShareTieredRate | any>({ mode: 'onSubmit' });
    const { showErrorToast } = useToast();

    const [loading, setLoading] = useState(false);
    const [maxMemberCount, setMaxMemberCount] = useState<number>(0);
    const [listOfGainShareTieredRate, setListOfGainShareTieredRate] = useState<GainShareTieredRate[] | undefined>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const user = Auth.currentUser();
    const [isSgaRevenuePercent, isSgaPmPm] = watch([
        'isSgaRevenuePercent',
        'isSgaPmPm',
        'memberCountStart',
        'memberCountEnd',
    ]);
    const isFormClean = !isDirty || Object.keys(dirtyFields).length <= 0;

    const populateUpdateData = (gainShareTieredRateData: GainShareTieredRate) => {
        setLoading(true);
        gainShareTieredRateData.lastUpdatedAt = new Date().toISOString();
        gainShareTieredRateData.lastUpdatedById = user.emails?.at(0)?.split('@')?.at(0);
        return gainShareTieredRateData;
    };

    const validateMemberCount = (field: string, count: number) => {
        const fieldName = field === 'memberCountStart' ? 'member count start' : 'member count end';
        const overLapCount = listOfGainShareTieredRate?.filter(
            (tieredRate) => tieredRate.memberCountStart <= count && count <= tieredRate.memberCountEnd,
        );

        if (overLapCount && overLapCount?.length > 0) {
            setError(field, {
                message: `The ${fieldName} should not overlap with the member count range of another record.`,
            });
        } else {
            clearErrors(field);
        }

        setDisabled(Object.keys(errors).length > 0 ? true : false);
    };

    const saveInformation = async (data: GainShareTieredRate) => {
        populateUpdateData(data);
        if (data?.id) onSave(data);
        else onCreate(data);
    };

    const onCreate = (gainShareTieredRateData: GainShareTieredRate) => {
        gainShareTieredRateData.createdById = gainShareTieredRateData.lastUpdatedById;
        gainShareTieredRateData.createdAt = gainShareTieredRateData.lastUpdatedAt;
        dialogService.loading(true);
        let isSuccessful = false;
        requests
            .post<GainShareRateDetail>(BASE_URI + CREATE_ENDPOINT, { data: gainShareTieredRateData })
            .then((data) => {
                setPendingSelection(data.gainShareTieredRates.at(data.gainShareTieredRates.length - 1));
                dialogService.close();
                isSuccessful = true;
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to create record. Please try again later');
            })
            .finally(() => {
                setLoading(false);
                dialogService.loading(false);
                if (isSuccessful) gainShareRateService.submit();
            });
    };

    const onSave = (gainShareTieredRateData: GainShareTieredRate) => {
        let isSuccessful = false;
        requests
            .put(BASE_URI + UPDATE_ENDPOINT, { data: gainShareTieredRateData })
            .then(() => {
                setPendingSelection(gainShareTieredRateData);
                isSuccessful = true;
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to update record. Please try again later');
            })
            .finally(() => {
                setLoading(false);
                if (isSuccessful) gainShareRateService.submit();
            });
    };

    useEffect(() => {
        reset({
            ...data,
            effectiveEndDate: moment(data?.effectiveEndDate).format('MM/DD/YYYY'),
            effectiveStartDate: moment(data?.effectiveStartDate).format('MM/DD/YYYY'),
        });

        const listOfTieredRates = data.parentRateData?.gainShareTieredRates.filter(
            (tieredRate) => !data.parentRateId || tieredRate.rateId != data?.rateId,
        );

        setListOfGainShareTieredRate(listOfTieredRates);

        let previousEndCount =
            listOfTieredRates && listOfTieredRates?.length > 0
                ? data.memberCountStart
                    ? data.memberCountStart - 1
                    : 0
                : 0;

        previousEndCount =
            listOfTieredRates
                ?.sort((a, b) => b.memberCountEnd - a.memberCountEnd)
                .filter((tieredRate) => !data.memberCountStart || tieredRate.memberCountEnd < data.memberCountStart)[0]
                ?.memberCountEnd ?? previousEndCount;

        setMaxMemberCount(previousEndCount);
    }, [data, reset]);

    return (
        <div style={{ border: '1px solid #c8c8c8', padding: '0' }}>
            {data.id && (
                <SectionHeader title="Gain Share Details">
                    <button
                        type="button"
                        className="e-btn avaButton text-capitalize"
                        disabled={loading}
                        onClick={async () => {
                            if (!isReadOnlyViewer) {
                                const shouldProceed = isFormClean
                                    ? isFormClean
                                    : await showConfirmation({
                                          message:
                                              'You have unsaved changes, which may be lost if you proceed. Do you want to continue?',
                                      });
                                if (!shouldProceed) return;
                            }
                            gainShareRateService.viewHistory(data);
                        }}
                    >
                        View Change History
                    </button>
                </SectionHeader>
            )}

            <DisabledWrapper disabled={isReadOnlyViewer || loading} withSpinner={loading}>
                <form onSubmit={handleSubmit(saveInformation)} style={{ padding: '10px' }}>
                    <Row className="mb-3">
                        <u>
                            <i>Gain Share Tiered Rate for {data.parentRateId ?? ''}</i>
                        </u>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.memberCountStart}</label>
                                <TooltipComponent content={`Min Member Count Start: ${maxMemberCount + 1}`}>
                                    <ControlledSyncfusionNumericTextBox
                                        name="memberCountStart"
                                        control={control}
                                        rules={{
                                            required: 'Member Count Start is required',
                                        }}
                                        boxAttrs={{
                                            showSpinButton: false,
                                            format: 'n0',
                                            min: 1,
                                            strictMode: true,
                                            decimals: 0,
                                            validateDecimalOnType: true,
                                        }}
                                        onChangeHandler={(arg) => {
                                            validateMemberCount('memberCountStart', getValues('memberCountStart'));
                                        }}
                                    />
                                </TooltipComponent>
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="memberCountStart"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.memberCountEnd}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="memberCountEnd"
                                    control={control}
                                    rules={{ required: 'Member Count End is required' }}
                                    boxAttrs={{
                                        showSpinButton: false,
                                        format: 'n0',
                                        min: (() => {
                                            const countStart = getValues('memberCountStart');
                                            if (typeof countStart === 'number') return countStart + 1;
                                            return 2;
                                        })(),
                                        strictMode: true,
                                        decimals: 0,
                                        validateDecimalOnType: true,
                                    }}
                                    onChangeHandler={() => {
                                        validateMemberCount('memberCountEnd', getValues('memberCountEnd'));
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="memberCountEnd"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.profitSharePercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="profitSharePercent"
                                    control={control}
                                    boxAttrs={{
                                        showSpinButton: false,
                                        format: '0.0000',
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="profitSharePercent"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.revenuePercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="revenuePercent"
                                    control={control}
                                    boxAttrs={{ showSpinButton: false, format: '0.0000' }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="revenuePercent"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.partCRevenuePmPm}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="partCRevenuePmPm"
                                    control={control}
                                    boxAttrs={{ showSpinButton: false }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="PartC Revenue PMPM"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.partDRevenuePmPm}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="partDRevenuePmPm"
                                    control={control}
                                    boxAttrs={{ showSpinButton: false }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="partDRevenuePmPm"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.effectiveStartDate}</label>
                                <ControlledSyncfusionDatePicker
                                    name="effectiveStartDate"
                                    control={control}
                                    dpAttrs={{
                                        enabled: false,
                                    }}
                                    isRetrievingDateAsString={true}
                                    rules={{ required: 'Effective Date is required' }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="effectiveStartDate"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{GainShareLabel.effectiveEndDate}</label>
                                <ControlledSyncfusionDatePicker
                                    name="effectiveEndDate"
                                    control={control}
                                    dpAttrs={{
                                        enabled: false,
                                    }}
                                    isRetrievingDateAsString={true}
                                    rules={{ required: 'End Date is required' }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="effectiveEndDate"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={12}>
                            <ControlledSyncfusionCheckBox
                                name="isPartDExclusion"
                                control={control}
                                cbAttrs={{ cssClass: styles.inlineCheckbox, label: GainShareLabel.isPartDExclusion }}
                            />
                            <ControlledSyncfusionCheckBox
                                name="isDeficitCarryover"
                                control={control}
                                cbAttrs={{ cssClass: styles.inlineCheckbox, label: GainShareLabel.isDeficitCarryover }}
                            />
                            <ControlledSyncfusionCheckBox
                                name="isSupplementalPmPm"
                                control={control}
                                cbAttrs={{ cssClass: styles.inlineCheckbox, label: GainShareLabel.isSupplementalPmPm }}
                            />
                            <ControlledSyncfusionCheckBox
                                name="isClinicalModelPmPm"
                                control={control}
                                cbAttrs={{ cssClass: styles.inlineCheckbox, label: GainShareLabel.isClinicalModelPmPm }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <p className={`${styles.sectionHeader} header-margin`}>SG&A Configuration</p>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name="isSgaRevenuePercent"
                                control={control}
                                cbAttrs={{ label: GainShareLabel.sgaRevenuePercent, cssClass: 'mb-2' }}
                                onChangeHandler={(value) => {
                                    setValue('sgaRevenuePercent', 0);
                                    if (value) {
                                        setValue('isSgaPmPm', false);
                                        setValue('sgaPmPm', 0);
                                    }
                                }}
                            />
                            <ControlledSyncfusionNumericTextBox
                                name="sgaRevenuePercent"
                                control={control}
                                boxAttrs={{
                                    enabled: isSgaRevenuePercent ?? false,
                                    showSpinButton: false,
                                    format: '0.0000',
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name="isSgaPmPm"
                                control={control}
                                cbAttrs={{ label: GainShareLabel.sgaPmPm, cssClass: 'mb-2' }}
                                onChangeHandler={(value) => {
                                    setValue('sgaPmPm', 0);
                                    if (value) {
                                        setValue('isSgaRevenuePercent', false);
                                        setValue('sgaRevenuePercent', 0);
                                    }
                                }}
                            />
                            <ControlledSyncfusionNumericTextBox
                                name="sgaPmPm"
                                control={control}
                                boxAttrs={{ enabled: isSgaPmPm ?? false, showSpinButton: false, format: '0.0000' }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <div className={`mb-3 ${styles.hideNotes}`}>
                            <p className={styles.sectionHeader} style={{ margin: '10px 0' }} />
                            <div>
                                <label className={styles.label}>{GainShareLabel.notes}</label>
                                <ControlledSyncfusionTextBox
                                    name="notes"
                                    control={control}
                                    multiline
                                    boxAttrs={{ htmlAttributes: { rows: '3' } }}
                                />
                            </div>
                        </div>
                    </Row>

                    <Row className="mt-2 mb-3">
                        {data?.id ? (
                            <Col>
                                <Row className="mb-2">
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created By</label>
                                            <input type="text" readOnly value={data?.createdById ?? ''} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated By</label>
                                            <input type="text" readOnly value={data?.lastUpdatedById ?? ''} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approved By</label>
                                            <input type="text" readOnly value={data?.lastApprovedById ?? ''} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    data?.createdAt ? formatISOLocalDateTime(data.createdAt) : undefined
                                                }
                                                value={data?.createdAt ? formatISOLocalDateTime(data.createdAt) : ''}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    data?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(data.lastUpdatedAt)
                                                        : undefined
                                                }
                                                value={
                                                    data?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(data.lastUpdatedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approval Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    data?.lastApprovedAt
                                                        ? formatISOLocalDateTime(data.lastApprovedAt)
                                                        : undefined
                                                }
                                                value={
                                                    data?.lastApprovedAt
                                                        ? formatISOLocalDateTime(data.lastApprovedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ) : null}
                        <Col className="mt-3 d-flex justify-content-end">
                            <div className={styles.formButtonsGroup}>
                                <button
                                    type="button"
                                    className={`btn btn-secondary btn-flat ${styles.formButton}`}
                                    onClick={() => gainShareRateService.cancel()}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={`btn btn-primary btn-flat ${styles.formButton}`}
                                    disabled={isFormClean || disabled}
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </DisabledWrapper>
        </div>
    );
};

export default GainShareTieredRateForm;
