import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import Auth from 'Auth';

const reactPlugin = new ReactPlugin();
let appInsights;

function getAppInsights() {
    if (appInsights) {
        const user = Auth.currentUser();
        const isUserEmpty = Object.keys(user).length === 0;
        if (!isUserEmpty) {
            appInsights.context.user.id = user?.emails?.[0];
            appInsights.context.user.name = user?.name;
        }
        return appInsights;
    }

    return {
        trackEvent: function (eventData) {
            console.log('Application Insights trackEvent: ' + JSON.stringify(eventData));
        },
    };
}

function initAppInsights(instrumentationKey, browserHistory) {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });

    appInsights.loadAppInsights();
    const user = Auth.currentUser();
    const isUserEmpty = Object.keys(user).length === 0;
    var telemetryInitializer = (envelope) => {
        if (!isUserEmpty) {
            envelope.tags['ai.user.id'] = user?.emails?.[0];
            envelope.tags['ai.user.name'] = user?.name;
        }
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);

    return appInsights;
}

function trackEvent(eventName, severityLevel = SeverityLevel.Information, properties = {}) {
    var eventInfo = {
        name: eventName,
        properties: { ...Auth.currentUser(), ...properties },
        severityLevel,
    };
    appInsights.trackEvent(eventInfo);
}

export { getAppInsights, initAppInsights, reactPlugin, trackEvent };
