import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CallTimer.css';

interface CallTimerProps {
    callDuration: number;
}

export default function CallTimer({ callDuration }: CallTimerProps) {
    const format = (number: number) => number.toString().padStart(2, '0');
    const minutes = Math.floor(callDuration / 60);
    const seconds = Math.floor(callDuration - minutes * 60);
    const blink = callDuration % 2 == 0 ? 'call-timer-blue' : 'call-timer-red';

    return (
        <>
            <span className={blink}>
                <FontAwesomeIcon icon={faCircleDot} size="lg" />
            </span>
            <span className={'call-timer-text'}>{` Ongoing call ${format(minutes)}:${format(seconds)}`}</span>
        </>
    );
}
