import { Observable, Subject } from 'rxjs';

const selectedIpaAdministrationSubject = new Subject<any>();
const submitCapRateSubject = new Subject<{
    type: 'add' | 'submit' | 'cancel' | 'addCapTier' | 'addCapSpecialRate' | 'deleteCapSpecialRate' | 'copyCapRate';
    capRateData?: any;
    id?: string;
}>();

const gainShareRateSubject = new Subject<{
    type:
        | 'add'
        | 'submit'
        | 'cancel'
        | 'approve'
        | 'addGainShareTieredRate'
        | 'copy'
        | 'deactivate'
        | 'activate'
        | 'viewHistory';
    gainShareRateData?: any;
}>();

const riskPoolRateSubject = new Subject<{
    type:
        | 'add'
        | 'submit'
        | 'cancel'
        | 'approve'
        | 'addRiskPoolTieredRate'
        | 'copy'
        | 'deactivate'
        | 'activate'
        | 'viewHistory';
    riskPoolRateData?: any;
    id?: string;
}>();

type DOFRSubject = { type: 'submit' | 'refresh'; isContract?: boolean };
const dofrSubject = new Subject<DOFRSubject>();

const selectedIpaAdministrationService = {
    select: (ipa: any) => {
        selectedIpaAdministrationSubject.next(ipa);
    },
    get$: (): Observable<any> => selectedIpaAdministrationSubject.asObservable(),
};

const submitCapRateService = {
    submit: () => submitCapRateSubject.next({ type: 'submit' }),
    add: () => submitCapRateSubject.next({ type: 'add' }),
    addCapTier: (capRate) => submitCapRateSubject.next({ type: 'addCapTier', capRateData: capRate }),
    addCapSpecialRate: (capRate) => submitCapRateSubject.next({ type: 'addCapSpecialRate', capRateData: capRate }),
    deleteCapSpecialRate: (id) => submitCapRateSubject.next({ type: 'deleteCapSpecialRate', id: id }),
    copyCapRate: (capRate) => submitCapRateSubject.next({ type: 'copyCapRate', capRateData: capRate }),
    cancel: () => submitCapRateSubject.next({ type: 'cancel' }),
    error: () => submitCapRateSubject.next({ type: 'copyCapRate' }),
    get$: (): Observable<any> => submitCapRateSubject.asObservable(),
};
const riskPoolRateService = {
    submit: () => riskPoolRateSubject.next({ type: 'submit' }),
    add: () => riskPoolRateSubject.next({ type: 'add' }),
    addRiskPoolTieredRate: (riskPoolRate) =>
        riskPoolRateSubject.next({ type: 'addRiskPoolTieredRate', riskPoolRateData: riskPoolRate }),
    approve: (riskPoolRate) => riskPoolRateSubject.next({ type: 'approve', riskPoolRateData: riskPoolRate }),
    copy: (riskPoolRate) => riskPoolRateSubject.next({ type: 'copy', riskPoolRateData: riskPoolRate }),
    cancel: () => riskPoolRateSubject.next({ type: 'cancel' }),
    viewHistory: (riskPoolRate) => riskPoolRateSubject.next({ type: 'viewHistory', riskPoolRateData: riskPoolRate }),
    error: () => riskPoolRateSubject.next({ type: 'deactivate' }),
    get$: (): Observable<any> => riskPoolRateSubject.asObservable(),
};

const gainShareRateService = {
    submit: () => gainShareRateSubject.next({ type: 'submit' }),
    add: () => gainShareRateSubject.next({ type: 'add' }),
    addGainShareTieredRate: (gainShareRate) =>
        gainShareRateSubject.next({ type: 'addGainShareTieredRate', gainShareRateData: gainShareRate }),
    copy: (gainShareRate) => gainShareRateSubject.next({ type: 'copy', gainShareRateData: gainShareRate }),
    approve: (gainShareRate) => gainShareRateSubject.next({ type: 'approve', gainShareRateData: gainShareRate }),
    cancel: () => gainShareRateSubject.next({ type: 'cancel' }),
    viewHistory: (gainShareRate) =>
        gainShareRateSubject.next({ type: 'viewHistory', gainShareRateData: gainShareRate }),
    error: () => gainShareRateSubject.next({ type: 'deactivate' }),
    get$: (): Observable<any> => gainShareRateSubject.asObservable(),
};

const dofrService = {
    submit: (isContract = true) => dofrSubject.next({ type: 'submit', isContract }),
    refresh: () => dofrSubject.next({ type: 'refresh' }),
    get$: (): Observable<DOFRSubject> => dofrSubject.asObservable(),
};

const performOperationTabSubject = new Subject<any>();
const refreshTabService = {
    performAction: (action) => performOperationTabSubject.next(action),
    getAction: () => performOperationTabSubject.asObservable(),
};

export {
    selectedIpaAdministrationService,
    submitCapRateService,
    riskPoolRateService,
    refreshTabService,
    gainShareRateService,
    dofrService,
};
