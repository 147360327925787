import React, { useEffect } from 'react';
import { map, Observable } from 'rxjs';
import ExportPdfButton from '../ExportPdfButton';
import TabLayout, { TabProps } from '../TabLayout/TabLayout';

interface ModuleLayoutProps {
    beforeCloseTab?: (e: React.MouseEvent, next: () => void, tab) => void;
    header: string;
    exportPdf?: {
        title?: string;
        url?: string;
        memberPDFServiceUrl?: string;
    };
    tabs: TabProps[];
    tab$?: Observable<TabProps>;
    selectedTab?: number;
    flagCMCases?: boolean;
    onChangeTab?: (tabIdx: number) => void;
    onMount?: () => void;
    parentProps?: any;
}

const getColorFromClassName = (className) => {
    if (className == "new-member")
    {
        return "#05ad0d"
    }
    if (className == "term-date")
    {
        return "#ff5050"
    }
    if (className == "inactiveMember" || className == "inactive")
    {
        return "gray"
    }
    if (className == "frequent-caller")
    {
        return "#fd7e14"
    }
    return "#00b3e3"
}

const syncfusionTabConverter = (syncfusionTabModel, id): TabProps => ({
    id,
    title: syncfusionTabModel.header?.text ?? syncfusionTabModel.displayName,
    content:
        typeof syncfusionTabModel.content === 'function'
            ? syncfusionTabModel.content
            : () => syncfusionTabModel.content,

    closable: false,
    color: getColorFromClassName(syncfusionTabModel.cssClass),
    onSelect: syncfusionTabModel.onSelect,
});

// use this temporarily to ease the transition to the new material tab
export const syncfusionObservableConverter = (synfusionImplementation: Observable<any>) => {
    return synfusionImplementation.pipe(
        map((tabWrapper) => [tabWrapper.tab, tabWrapper.id]),
        map(([tab, id]) => syncfusionTabConverter(tab, id)),
        map((tab) => ({ ...tab, closable: true })),
    );
};

export const syncfusionTabItemsConverter = (syncfusionTabItems: any[]) => {
    return syncfusionTabItems.map(syncfusionTabConverter);
};

const ModuleLayout = ({
    beforeCloseTab,
    header,
    tabs,
    tab$,
    exportPdf,
    selectedTab,
    flagCMCases,
    onChangeTab,
    onMount,
    parentProps,
}: ModuleLayoutProps) => {
    useEffect(() => {
        if (typeof onMount === 'function') {
            onMount();
        }
    }, []);

    return (
        <div className="main-section" data-testid="module-layout-main-section">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <h1>{header}</h1>
                {exportPdf ? <ExportPdfButton source={header} {...exportPdf} /> : null}
            </div>
            <div className="control-pane">
                <div className="control-section tab-control-section">
                    <TabLayout
                        initialTabs={tabs}
                        theme="primary"
                        newTab$={tab$}
                        selectedTab={selectedTab}
                        flagCMCases={flagCMCases}
                        beforeCloseTab={beforeCloseTab}
                        onChangeTab={onChangeTab}
                        parentProps={parentProps}
                    />
                </div>
            </div>
        </div>
    );
};

export default ModuleLayout;
