import React, { LegacyRef, useRef } from 'react';
import { DataManager } from '@syncfusion/ej2-data/src/manager';
import { Query, DataManager as DataManagerToQuery } from '@syncfusion/ej2-data';

import { CheckBoxComponent, CheckBoxModel, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
    DatePickerComponent,
    DateTimePickerComponent,
    ChangedEventArgs as dpChangedEventArgs,
    DatePickerModel,
    Inject,
    DateTimePickerModel,
    MaskedDateTime,
} from '@syncfusion/ej2-react-calendars';
import {
    DropDownListComponent,
    ChangeEventArgs,
    DropDownListModel,
    AutoCompleteComponent,
    MultiSelectModel,
    AutoCompleteModel,
    MultiSelectComponent,
    CheckBoxSelection,
    FieldSettingsModel,
    FilteringEventArgs,
} from '@syncfusion/ej2-react-dropdowns';
import {
    TextBoxComponent,
    ChangedEventArgs as tbChangedEventArgs,
    NumericTextBoxComponent,
    NumericTextBoxModel,
    TextBoxModel,
    MaskedTextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import moment from 'moment';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type DropDownData = {
    id: any;
    desc: string;
    scope?: string;
};

type ControlledProps = {
    name: string;
    control: Control<any>;
    defaultValue?: any;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | null;
    onChangeHandler?: (value: any) => void;
};

export type DropDownListProps = ControlledProps & {
    dataSource: DropDownData[] | { [key: string]: any }[];
    filtering?: any;
    ddlAttrs?: DropDownListModel;
    fields?: FieldSettingsModel;
    onOpenHandler?: (value) => void;
};

type AutoCompleteProps = ControlledProps & {
    dataSource?: any[] | undefined;
    filtering?: any;
    autoCompleteAttrs?: AutoCompleteModel;
    fields?: FieldSettingsModel | undefined | { text: string; value: string };
    enabled?: boolean;
};

export const ControlledSyncfusionAutocomplete = React.forwardRef(
    (props: AutoCompleteProps, ref: LegacyRef<AutoCompleteComponent>) => (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.rules ?? {}}
            defaultValue={props.defaultValue as any}
            render={({ field: { onChange, value } }) => (
                <AutoCompleteComponent
                    ref={ref}
                    {...(props.autoCompleteAttrs ?? {})}
                    dataSource={props.dataSource}
                    fields={{ text: 'desc', value: 'id' }}
                    change={({ itemData }: ChangeEventArgs) => onChange(itemData ? (itemData as any).id : null)}
                    filtering={props.filtering}
                    value={value}
                    enabled={props.enabled}
                />
            )}
        />
    ),
);

type RestrictedAutoCompleteProps = ControlledProps & {
    dataSource?: any[] | undefined;
    autoCompleteAttrs?: Omit<AutoCompleteModel, 'filtering' | 'fields' | 'allowFiltering'>;
    fields: { text: string; value: string };
    enabled?: boolean;
};

export const ControlledSyncfusionRestrictedAutocomplete = ({
    autoCompleteAttrs,
    control,
    dataSource,
    defaultValue,
    fields,
    enabled,
    name,
    rules,
}: RestrictedAutoCompleteProps) => {
    const autoCompleteRef = useRef<any>(null);
    const dataSourceToUse = new DataManagerToQuery(dataSource);
    const filteringType = autoCompleteAttrs?.filterType ? autoCompleteAttrs?.filterType : 'StartsWith';

    const valueExistsInDataSource = (value: string) =>
        autoCompleteRef.current.listData.some(({ description }) => description === value);

    const changeHandler = ({ itemData, value }: ChangeEventArgs, onChangeFn: (event: any[]) => void) => {
        if (value !== '' && value !== null && !valueExistsInDataSource(value as string)) {
            autoCompleteRef.current.value = '';
        } else {
            const valueToUse = itemData !== null && itemData.value !== '' ? (itemData as any).value : null;
            onChangeFn(valueToUse);
        }
    };

    const filteringHandler = (event: FilteringEventArgs) => {
        const query = event.text !== '' ? new Query().where(fields.text, filteringType, event.text, true) : new Query();
        event.updateData(dataSourceToUse, query);
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules ?? {}}
            defaultValue={defaultValue as any}
            render={({ field: { onChange, value } }) => (
                <AutoCompleteComponent
                    {...(autoCompleteAttrs ?? {})}
                    ref={autoCompleteRef}
                    dataSource={dataSourceToUse}
                    fields={fields}
                    change={(event: ChangeEventArgs) => changeHandler(event, onChange)}
                    filtering={filteringHandler}
                    value={value}
                    enabled={enabled}
                />
            )}
        />
    );
};

export const ControlledSyncfusionDropDownList = ({
    name,
    control,
    defaultValue = null,
    rules = null,
    dataSource,
    ddlAttrs = {},
    fields,
    onChangeHandler,
    onOpenHandler,
}: DropDownListProps) => (
    <Controller
        name={name}
        control={control}
        rules={rules ?? {}}
        defaultValue={defaultValue as any}
        render={({ field: { onChange, value } }) => (
            <DropDownListComponent
                {...ddlAttrs}
                dataSource={dataSource}
                fields={fields || { text: 'desc', value: 'id' }}
                change={({ itemData }: ChangeEventArgs) => {
                    const updatedVal = itemData ? (itemData as any)[fields?.value ?? 'id'] : null;
                    onChange(updatedVal);
                    if (onChangeHandler) onChangeHandler(updatedVal);
                }}
                value={dataSource?.length && value}
                open={(value) => onOpenHandler && onOpenHandler(value)}
            />
        )}
    />
);

type DatePickerProps = ControlledProps & {
    format?: string;
    dpAttrs?: DatePickerModel;
    isRetrievingDateAsString?: boolean;
};
export const ControlledSyncfusionDatePicker = ({
    name,
    control,
    defaultValue = null,
    rules = null,
    format,
    dpAttrs = {},
    onChangeHandler,
    isRetrievingDateAsString = false,
}: DatePickerProps) => (
    <Controller
        name={name}
        control={control}
        rules={rules ?? {}}
        defaultValue={defaultValue ? new Date(defaultValue) : undefined}
        render={({ field: { onChange, value } }) => {
            minMaxDateTimeValidation(dpAttrs);
            return (
                <DatePickerComponent
                    format={format ? format : 'MM/dd/yyyy'}
                    change={({ value }: dpChangedEventArgs) => {
                        const valueToUse = isRetrievingDateAsString
                            ? value != null
                                ? moment(value).format('MM/DD/YYYY')
                                : null
                            : value;

                        onChange(valueToUse);
                        if (onChangeHandler) {
                            const dataToSendToHandler = isRetrievingDateAsString
                                ? { value: valueToUse, valueAsDate: value, onChange }
                                : { value, onChange };

                            onChangeHandler(dataToSendToHandler);
                        }
                    }}
                    value={value}
                    {...dpAttrs}
                >
                    <Inject services={[MaskedDateTime]} />
                </DatePickerComponent>
            );
        }}
    />
);

type DateTimePickerProps = ControlledProps & {
    format?: string;
    dpAttrs?: DateTimePickerModel;
};
export const ControlledSyncfusionDateTimePicker = ({
    name,
    control,
    defaultValue = null,
    rules = null,
    format,
    dpAttrs = {},
    onChangeHandler,
}: DateTimePickerProps) => (
    <Controller
        name={name}
        control={control}
        rules={rules ?? {}}
        defaultValue={defaultValue ? new Date(defaultValue) : undefined}
        render={({ field: { onChange, value } }) => {
            minMaxDateTimeValidation(dpAttrs);
            return (
                <DateTimePickerComponent
                    format={format ? format : 'MM/dd/yyyy h:mm a'}
                    change={({ value }: dpChangedEventArgs) => {
                        onChange(value);
                        if (onChangeHandler) onChangeHandler({ value, onChange });
                    }}
                    value={value}
                    {...dpAttrs}
                />
            );
        }}
    />
);

type MaskedTextBoxProps = ControlledProps & {
    boxAttrs?: TextBoxModel;
    mask: string;
};

export const ControlledSyncfusionMaskedTexBox = ({
    name,
    control,
    mask,
    defaultValue = null,
    rules = null,
    boxAttrs,
    onChangeHandler,
}: MaskedTextBoxProps) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue as any}
        rules={rules ?? {}}
        render={({ field: { onChange, value } }) => (
            <MaskedTextBoxComponent
                {...boxAttrs}
                change={({ value }: tbChangedEventArgs) => {
                    onChange(value);
                    if (onChangeHandler) onChangeHandler(value);
                }}
                value={value}
                mask={mask}
            />
        )}
    />
);

type TextBoxProps = ControlledProps & {
    multiline?: boolean;
    boxAttrs?: TextBoxModel;
    limit?: number;
};

export const ControlledSyncfusionTextBox = ({
    name,
    control,
    defaultValue = null,
    rules = null,
    multiline = false,
    boxAttrs,
    onChangeHandler,
    limit = multiline ? 2056 : 128,
}: TextBoxProps) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue as any}
        rules={rules ?? {}}
        render={({ field: { onChange, value } }) => (
            <TextBoxComponent
                {...boxAttrs}
                htmlAttributes={{
                    ...boxAttrs?.htmlAttributes,
                    maxLength: (boxAttrs?.htmlAttributes?.maxLength ?? limit).toString(),
                }}
                multiline={multiline}
                change={({ value }: tbChangedEventArgs) => {
                    onChange(value?.length ? value : null);
                    if (onChangeHandler) onChangeHandler(value);
                }}
                value={value}
            />
        )}
    />
);

type NumericTextBoxProps = ControlledProps & {
    boxAttrs?: NumericTextBoxModel;
};
export const ControlledSyncfusionNumericTextBox = ({
    name,
    control,
    defaultValue = null,
    rules,
    boxAttrs = {},
    onChangeHandler,
}: NumericTextBoxProps) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue as any}
        rules={rules ?? {}}
        render={({ field: { onChange, value } }) => (
            <NumericTextBoxComponent
                value={value}
                {...boxAttrs}
                change={(arg) => {
                    onChange(arg.value);
                    if (onChangeHandler) {
                        onChangeHandler(arg);
                    }
                }}
            />
        )}
    />
);

type RadioButtonsProps = ControlledProps & {
    dataSource: DropDownData[];
    inputName: string;
    formValsTodbVals: object;
    radioClass?: string;
};
export const ControlledSyncfusionRadioButtons = ({
    name,
    control,
    defaultValue = null,
    dataSource,
    inputName,
    formValsTodbVals,
    rules,
}: RadioButtonsProps) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue as any}
        rules={rules ?? {}}
        render={({ field: { onChange, value } }) => (
            <div>
                {dataSource.map(({ id, desc }) => (
                    <RadioButtonComponent
                        key={id}
                        name={inputName}
                        label={desc}
                        value={id}
                        checked={value === formValsTodbVals[id]}
                        onChange={({ value }) => onChange(formValsTodbVals[value])}
                    />
                ))}
            </div>
        )}
    />
);

type CheckBoxProps = ControlledProps & {
    label?: string;
    cbAttrs?: CheckBoxModel;
};
export const ControlledSyncfusionCheckBox = ({
    name,
    control,
    defaultValue = null,
    label = '',
    cbAttrs = {},
    onChangeHandler,
    rules,
}: CheckBoxProps) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue as any}
        rules={rules ?? {}}
        render={({ field: { onChange, value } }) => (
            <CheckBoxComponent
                label={label}
                {...cbAttrs}
                checked={value}
                change={({ checked }) => {
                    onChange(checked);
                    if (onChangeHandler) onChangeHandler(checked);
                }}
                value={value}
            />
        )}
    />
);

type MultiSelectProps = ControlledProps & {
    dataSource: { [key: string]: object }[] | DataManager | string[] | number[] | boolean[];
    multiSelectAttrs: MultiSelectModel;
};

export const ControlledSyncfusionMultiSelect = ({
    name,
    control,
    defaultValue = null,
    multiSelectAttrs,
    dataSource,
    rules,
}: MultiSelectProps) => (
    <Controller
        name={name}
        control={control}
        rules={rules ?? {}}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
            <MultiSelectComponent
                dataSource={dataSource}
                value={value}
                change={({ value }) => {
                    onChange(value);
                }}
                {...multiSelectAttrs}
            >
                <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
        )}
    />
);

type RadioButtonProps = ControlledProps & {
    label?: string;
};
export const ControlledSyncfusionRadioButton = ({ control, name, label, onChangeHandler }: RadioButtonProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <RadioButtonComponent
                    name={name}
                    checked={field.value}
                    label={label}
                    onChange={(e) => {
                        if (onChangeHandler) onChangeHandler(field);
                        field.onChange(e.target.checked);
                    }}
                />
            )}
        />
    );
};

function minMaxDateTimeValidation(dpAttrs: DateTimePickerModel) {
    if (dpAttrs.hasOwnProperty('min') && !moment(dpAttrs.min).isValid()) dpAttrs.min = undefined;
    if (dpAttrs.hasOwnProperty('max') && !moment(dpAttrs.max).isValid()) dpAttrs.max = undefined;

    if (dpAttrs.hasOwnProperty('min') && dpAttrs.hasOwnProperty('max')) {
        dpAttrs.min =
            dpAttrs.max === undefined || dpAttrs.max === null || moment(dpAttrs.min).isBefore(moment(dpAttrs.max))
                ? dpAttrs.min
                : undefined;

        dpAttrs.max =
            dpAttrs.min === undefined || dpAttrs.min === null || moment(dpAttrs.max).isAfter(moment(dpAttrs.min))
                ? dpAttrs.max
                : undefined;
    }
}
