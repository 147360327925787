import { Subject, BehaviorSubject } from 'rxjs';

const subject = new Subject();
const subjectHide = new Subject();
const subjectWindowIsAvailable = new Subject();
const subjectBtn = new Subject();
const joinAnyTimeSubject = new BehaviorSubject(null);

export const VideoService = {
    showVideo: (roomName, appointment, telehealthModuleType, notificationContent: any = {}) =>
        subject.next({ roomName, appointment, telehealthModuleType, notificationContent }),
    getVideo: () => subject.asObservable(),
    setWindowIsAvailable: (value) => subjectWindowIsAvailable.next(value),
    getWindow: () => subjectWindowIsAvailable.asObservable(),
    updateBtnsState: (state) => subjectBtn.next(state),
    getBtnsState: () => subjectBtn.asObservable(),
    hideObservable: () => subjectHide.asObservable(),
    hideVideo: () => subjectHide.next(null),
    setJoinAnyTimeValue: (value) => joinAnyTimeSubject.next(value),
    joinSubscriber: joinAnyTimeSubject,
};
