const getSupplementalBenefitIncentiveGridCols = () => [
    {
        field: 'code',
        width: 100,
        headerText: 'Code',
        type: 'string',
    },
    {
        field: 'name',
        width: 100,
        headerText: 'Name',
        type: 'string',
    },
    {
        field: 'type',
        width: 100,
        headerText: 'Type',
        type: 'string',
    },
];

export default getSupplementalBenefitIncentiveGridCols;
