import { ButtonModel } from '@syncfusion/ej2-react-buttons';
import { Subject } from 'rxjs';
import { Dialog } from '../../interfaces/Dialog';

const dialogSubject = new Subject();
const dialogButtonsSubject = new Subject();
const dialogCloseSubject = new Subject<void>();
const dialogClosedSubject = new Subject();
const loadingSubject = new Subject();
const forceUpdateSubject = new Subject();

const dialogService = {
    open: (dialog: Dialog) => {
        dialogSubject.next(dialog);
    },
    close: () => {
        dialogService.loading(false);
        return dialogCloseSubject.next();
    },
    closed: () => dialogClosedSubject.asObservable(),
    get: () => dialogSubject.asObservable(),
    getButtonUpdates: () => dialogButtonsSubject.asObservable(),
    updateButtons: (buttons: { buttonModel: ButtonModel; click: () => void }[]) => dialogButtonsSubject.next(buttons),
    forceUpdate: (newContent: () => string | JSX.Element | null) => forceUpdateSubject.next(newContent),
    dialogUpdated: () => forceUpdateSubject.asObservable(),
    loading: (isLoading: boolean) => loadingSubject.next(isLoading),
    isLoading$: loadingSubject.asObservable(),
};

// The below observer will let subscribed children know when the dialog opens,
// since some SyncFusion components need to refresh UI if they are
// placed within a Dialog(modal), e.g. the RichTextEditor, a bug arises
// if the mentioned editor lives in the DialogComponent.
// More details here: https://support.syncfusion.com/support/tickets/418903
const dialogOpenedSubject = new Subject();
const dialogOpenedService = {
    get: () => dialogOpenedSubject.asObservable(),
    update: () => {
        dialogOpenedSubject.next('Dialog updated');
    },
};

export { dialogService, dialogCloseSubject, dialogClosedSubject, dialogOpenedService };
