import decodeJWT from 'jwt-decode';
import { AuthProviderVariables } from 'AuthProvider';

// these are pretty much duplicates of the functions in AuthProvider.
// ideally we want to use the AuthProvider context properly, but
// that would require changes to hundreds of files and refactoring
// across all modules
class Auth {
    getToken() {
        // reset last accessed time
        sessionStorage.setItem(AuthProviderVariables.ACCESS_TOKEN_API_LAST_ACCESSED, Date.now() as any);
        return (
            sessionStorage.getItem(AuthProviderVariables.ACCESS_TOKEN) ||
            sessionStorage.getItem(AuthProviderVariables.CC_ACCESS_TOKEN)
        );
    }
    currentUser() {
        const token = this.getToken();

        if (!token) return {};

        const decoded: any = decodeJWT(token);
        return {
            name: decoded.name,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            emails: decoded.emails,
            city: decoded.city,
            country: decoded.country,
        };
    }
    isExpired() {
        const token = this.getToken();

        if (!token) {
            return true;
        }

        const decoded: any = decodeJWT(token);

        if (!decoded.exp) {
            return true;
        }

        return Date.now() > decoded.exp * 1000;
    }
}

export default new Auth();
