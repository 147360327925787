export const AllNavItems = [
    {
        nodeId: '01',
        nodeText: 'AVA Home',
        iconKey: 'Hospital',
        url: '/',
    },
    {
        nodeId: '02',
        nodeText: 'Patient 360',
        iconKey: 'SearchPatient',
        nodeChild: [
            {
                nodeId: '02.01',
                nodeText: 'Patient 360',
                url: '/p360/patient360',
                moduleId: 12,
            },
            {
                nodeId: '02.02',
                nodeText: 'Clinical Journey',
                url: '/p360/clinicaljourney',
                moduleId: 77,
            },
            {
                nodeId: '02.03',
                nodeText: 'User Guides/Trainings',
                url: '/p360/UserGuidesAndTrainings',
                moduleId: 12,
            },
        ],
    },
    {
        nodeId: '03.01',
        nodeText: 'Eligibility and Benefits',
        iconKey: 'Eligibility',
        nodeChild: [
            {
                nodeId: '03.01.01',
                nodeText: 'Eligibility and Benefits',
                url: '/eligibility',
                iconKey: 'Eligibility',
                moduleId: 28,
            },
            {
                nodeId: '03.01.02',
                nodeText: 'User Guide',
                url: '/eligibility/userguide',
                moduleId: 28,
            },
        ],
    },
    {
        nodeId: '03.01a',
        nodeText: 'Eligibility & Benefits (S)',
        iconKey: 'Eligibility',
        nodeChild: [
            {
                nodeId: '03.01a.01',
                nodeText: 'Eligibility and Benefits (S)',
                url: '/ebspecialist',
                moduleId: 31,
            },
            {
                nodeId: '03.01a.02',
                nodeText: 'User Guide',
                url: '/ebspecialist/userguide',
                moduleId: 31,
            },
        ],
    },
    {
        nodeId: '03.01b',
        nodeText: 'Reports Submission',
        iconKey: 'ReportsSubmission',
        nodeChild: [
            {
                nodeId: '03.01b.01',
                nodeText: 'Reports Submission',
                url: '/provider-tools/report-upload',
                moduleId: 90,
            },
            {
                nodeId: '03.01b.02',
                nodeText: 'User Guide',
                url: '/provider-tools/report-upload/userguide',
                moduleId: 90,
            },
        ],
    },
    {
        nodeId: '03.02',
        nodeText: 'Authorizations',
        iconKey: 'Authorizations',
        nodeChild: [
            {
                nodeId: '03.02.01',
                nodeText: 'Authorizations',
                url: '/authorizations',
                moduleId: 30,
            },
            {
                nodeId: '03.02.02',
                nodeText: 'User Guide',
                url: '/authorizations/userguide',
                moduleId: 30,
            },
        ],
    },
    {
        nodeId: '03.03',
        nodeText: 'Claims Inquiry',
        iconKey: 'Claims',
        nodeChild: [
            {
                nodeId: '03.03.01',
                nodeText: 'Claims Inquiry',
                url: '/claims',
                moduleId: 29,
            },
            {
                nodeId: '03.03.02',
                nodeText: 'User Guide',
                url: '/claims/userguide',
                moduleId: 29,
            },
        ],
    },
    {
        nodeId: '03.04',
        nodeText: 'Utilization Management',
        iconKey: 'UtilizationManagement',
        nodeChild: [
            {
                nodeId: '03.04.01',
                nodeText: 'Authorization Management',
                url: '/um/authorizations',
                moduleId: 66,
            },
            {
                nodeId: '03.04.02',
                nodeText: 'Fax Management',
                url: '/um/faxes',
                moduleId: 141,
            },
            {
                nodeId: '03.04.03',
                nodeText: 'UM Productivity',
                url: '/um/productivity',
                moduleId: 143,
            },
            {
                nodeId: '03.04.04',
                nodeText: 'UM Admin',
                url: '/um/admin',
                moduleId: 154,
            },
            {
                nodeId: '03.04.05',
                nodeText: 'Authorization Metrics',
                url: '/um/auth-metrics',
                moduleId: 169,
            },
        ],
    },
    {
        nodeId: '03.05',
        nodeText: 'Agent Worklists',
        iconKey: 'Headphone',
        nodeChild: [
            {
                nodeId: '03a.01',
                nodeText: 'Eligibility and Benefits',
                url: '/agents/eligibility',
                moduleId: 65,
            },
            {
                nodeId: '03a.03',
                nodeText: 'Claims',
                url: '/agents/claims',
                moduleId: 67,
            },
        ],
    },
    {
        nodeId: '03b',
        nodeText: 'Care Management',
        iconKey: 'CareManagement',
        nodeChild: [
            {
                nodeId: '03b.02',
                nodeText: 'SNP Program Manager',
                url: '/caremanagement/snp-program-manager',
                moduleId: 144,
            },
            {
                nodeId: '03b.03',
                nodeText: 'SNP Team Member',
                url: '/caremanagement/snp-team-member',
                moduleId: 145,
            },
            {
                nodeId: '03b.04',
                nodeText: 'Post Discharge Review',
                url: '/caremanagement/post-discharge-program-manager',
                moduleId: 146,
            },
            {
                nodeId: '03b.06',
                nodeText: 'Outpatient Team',
                url: '/caremanagement/outpatient-team-member',
                moduleId: 148,
            },
            {
                nodeId: '03b.07',
                nodeText: 'Dashboards',
                url: '/caremanagement/dashboards',
                moduleId: 113,
            },
            {
                nodeId: '03b.08',
                nodeText: 'Paper HRA',
                url: '/caremanagement/paper-hra',
                moduleId: 161,
            },
        ],
    },
    {
        nodeId: '04',
        nodeText: 'Patient Priority',
        iconKey: 'PatientPriority',
        nodeChild: [
            {
                nodeId: '04.01',
                nodeText: 'Worklist',
                url: '/patientpriority/worklist',
                iconKey: 'fa-address-book',
                moduleId: 13,
            },
            {
                nodeId: '04.02',
                nodeText: 'Coding Review',
                url: '/patientpriority/gapsreview',
                iconKey: 'fa-gavel',
                moduleId: 43,
            },
            {
                nodeId: '04.03',
                nodeText: 'All Patients',
                url: '/patientpriority/patients',
                iconKey: 'fa-list-ul',
                moduleId: 14,
            },
            {
                nodeId: '04.04',
                nodeText: 'Dashboards',
                url: '/patientpriority/dashboards',
                iconKey: 'Dashboard',
                moduleId: 15,
            },
            {
                nodeId: '04.05',
                nodeText: 'Guidelines',
                url: '/patientpriority/guidelines',
                iconKey: 'fa-comment',
                moduleId: 16,
            },
            {
                nodeId: '04.06',
                nodeText: 'Audit',
                url: '/patientpriority/audit',
                iconKey: 'fa-search',
                moduleId: 166,
            },
        ],
    },
    {
        nodeId: '28',
        nodeText: 'AVA Scheduler',
        iconKey: 'Scheduler',
        nodeChild: [
            {
                nodeId: '28.01',
                nodeText: 'Provider Calendars',
                url: '/avascheduler/calendar',
                moduleId: 103,
            },
        ],
    },
    {
        nodeId: '05',
        nodeText: 'Dashboards',
        iconKey: 'Dashboard',
        nodeChild: [
            {
                nodeId: '05.01',
                nodeText: 'IPA Dashboard',
                url: '/dashboards/ipa',
                moduleId: 17,
            },
            {
                nodeId: '05.02',
                nodeText: 'NC IPA Dashboard',
                url: '/dashboards/ncipa',
                moduleId: 27,
            },
            {
                nodeId: '05.03',
                nodeText: 'STAR Dashboard',
                url: '/dashboards/star',
                moduleId: 25,
            },
            {
                nodeId: '05.04',
                nodeText: 'PCP Dashboard',
                url: '/dashboards/pcp',
                moduleId: 26,
            },
        ],
    },
    {
        nodeId: '07',
        nodeText: 'JSA',
        iconKey: 'JSA',
        nodeChild: [
            {
                nodeId: '07.01',
                nodeText: 'Assessment',
                url: '/JSA/Assessment',
                moduleId: 34,
            },
            {
                nodeId: '07.04',
                nodeText: 'JSA Search',
                url: '/JSA/JSA_Search/JSASearch',
                moduleId: 142,
            },
            {
                nodeId: '07.02',
                nodeText: 'Outreach',
                url: '/JSA/Outreach',
                moduleId: 33,
            },
            {
                nodeId: '07.05',
                nodeText: 'Admin',
                url: '/JSA/Admin',
                moduleId: 134,
            },
        ],
    },
    {
        nodeId: '08',
        nodeText: 'User Management',
        iconKey: 'UM',
        nodeChild: [
            {
                nodeId: '08.01',
                nodeText: 'Users',
                url: '/usermanagement/users',
                moduleId: 18,
            },
            {
                nodeId: '08.02',
                nodeText: 'Groups',
                url: '/usermanagement/groups',
                moduleId: 19,
            },
            {
                nodeId: '08.03',
                nodeText: 'Organizations',
                url: '/usermanagement/organizations',
                moduleId: 23,
            },
            {
                nodeId: '08.04',
                nodeText: 'User Report',
                url: '/usermanagement/userreport',
                moduleId: 44,
            },
            {
                nodeId: '08.05',
                nodeText: 'Providers',
                url: '/usermanagement/providers',
                moduleId: 20,
            },
            {
                nodeId: '08.06',
                nodeText: 'User Events',
                url: '/usermanagement/userevents',
                moduleId: 74,
            },
        ],
    },
    {
        nodeId: '09',
        nodeText: 'Audit History',
        url: '/audit',
        iconKey: 'History',
        moduleId: 36,
    },
    {
        nodeId: '10',
        nodeText: 'Census',
        iconKey: 'Census',
        nodeChild: [
            {
                nodeId: '10.01',
                nodeText: 'Daily Census',
                url: '/census/dailycensus',
                moduleId: 37,
            },
            {
                nodeId: '10.02',
                nodeText: 'Daily Discharge',
                url: '/census/dailydischarge',
                moduleId: 38,
            },
            {
                nodeId: '10.03',
                nodeText: 'Admissions',
                url: '/census/admission',
                moduleId: 117,
            },
        ],
    },
    {
        nodeId: '11',
        nodeText: 'CRM',
        iconKey: 'Headphone',
        nodeChild: [
            {
                nodeId: '11.02',
                nodeText: 'My Queue',
                url: '/crm/myqueue',
                moduleId: 49,
            },
            {
                nodeId: '11.03',
                nodeText: 'Dashboards',
                url: '/crm/dashboards',
                moduleId: 42,
            },
            {
                nodeId: '11.04',
                nodeText: 'Advanced Search',
                url: '/crm/advancedsearch',
                moduleId: 50,
            },
            {
                nodeId: '11.05',
                nodeText: 'Agents Productivity',
                url: '/crm/productivity',
                moduleId: 91,
            },
            {
                nodeId: '11.07',
                nodeText: 'Campaign Configurator',
                url: '/crm/campaign-config',
                moduleId: 69,
            },
            {
                nodeId: '11.08',
                nodeText: 'Benefit Configurator',
                url: '/crm/benefit-config',
                moduleId: 69,
            },
            {
                nodeId: '11.10',
                nodeText: 'Login Accounts',
                url: '/crm/login-accounts/search',
                moduleId: 83,
            },
            {
                nodeId: '25.021',
                nodeText: 'Member Portal Audit History',
                url: '/crm/member-portal-audit-history',
                moduleId: 157,
            },
            {
                nodeId: '11.11',
                nodeText: 'Campaign Stats',
                url: '/crm/campaign-stats',
                moduleId: 178,
            },
            {
                nodeId: '11.12',
                nodeText: 'Member Portal Admin Console',
                url: '/crm/member-portal-admin-console',
                moduleId: 179,
            },
        ],
    },
    {
        nodeId: '23',
        nodeText: 'CRM Admin',
        iconKey: 'FormBuilder',
        nodeChild: [
            {
                nodeId: '23.01',
                nodeText: 'Text Replace Settings',
                url: '/crm-admin/text-replace',
                moduleId: 69,
            },
            {
                nodeId: '23.02',
                nodeText: 'CRM Case Auto-assign',
                url: '/crm-admin/case-autoassign',
                moduleId: 156,
            },
            {
                nodeId: '23.03',
                nodeText: 'Form Builder',
                url: '/crm-admin/form-builder',
                moduleId: 116,
            },
            {
                nodeId: '23.04',
                nodeText: 'Campaigns',
                url: '/crm-admin/campaigns',
                moduleId: 116,
            },
            {
                nodeId: '23.05',
                nodeText: 'Service Actions',
                url: '/crm-admin/service-actions',
                moduleId: 175,
            },
            {
                nodeId: '23.06',
                nodeText: 'Queues',
                url: '/crm-admin/queues',
                moduleId: 116,
            },
            {
                nodeId: '23.07',
                nodeText: 'Request Plan Materials',
                url: '/crm-admin/request-plan-materials',
                moduleId: 116,
            },
            {
                nodeId: '23.08',
                nodeText: 'Sticky Notes',
                url: '/crm-admin/sticky-notes',
                moduleId: 116,
            },
            {
                nodeId: '23.09',
                nodeText: 'Communications Manager',
                url: '/crm-admin/communications-manager',
                moduleId: 120,
            },
            {
                nodeId: '23.10',
                nodeText: 'Benefits',
                url: '/crm-admin/benefits',
                moduleId: 116,
            },
        ],
    },
    {
        nodeId: '12',
        nodeText: 'Coder 360',
        iconKey: 'Coder360',
        nodeChild: [
            {
                nodeId: '12.01',
                nodeText: 'Chart Review',
                url: '/coder360/chartreview',
                moduleId: 70,
            },
            {
                nodeId: '12.02',
                nodeText: 'Submissions',
                url: '/coder360/submissions',
                moduleId: 71,
            },
            {
                nodeId: '12.03',
                nodeText: 'My CRRs - Admin',
                url: '/coder360/allcrrs',
                moduleId: 72,
            },
            {
                nodeId: '12.04',
                nodeText: 'My CRRs',
                url: '/coder360/mycrrs',
                moduleId: 73,
            },
        ],
    },
    {
        nodeId: '13',
        nodeText: 'Claims Auditor',
        iconKey: 'ClaimsAuditor',
        nodeChild: [
            {
                nodeId: '13.01',
                nodeText: 'Claims',
                url: '/claimsauditor/claims',
                moduleId: 45,
            },
            {
                nodeId: '13.02',
                nodeText: 'Admin',
                url: '/claimsauditor/admin',
                moduleId: 60,
            },
            {
                nodeId: '13.03',
                nodeText: 'Roles',
                url: '/claimsauditor/roles',
                moduleId: 88,
            },
            {
                nodeId: '13.04',
                nodeText: 'Advanced Audits',
                url: '/claimsauditor/advancedaudits',
                moduleId: 98,
            },
        ],
    },
    {
        nodeId: '31',
        nodeText: 'Encounters 360',
        iconKey: 'Encounters',
        nodeChild: [
            {
                nodeId: '31.01',
                nodeText: 'Search',
                url: '/encounters/search',
                moduleId: 110,
            },
        ],
    },
    {
        nodeId: '14',
        nodeText: 'Charts',
        iconKey: 'AvaCharts',
        nodeChild: [
            {
                nodeId: '14.01',
                nodeText: 'Search',
                url: '/charts/search',
                moduleId: 46,
            },
            {
                nodeId: '14.02',
                nodeText: 'Upload',
                url: '/charts/upload',
                moduleId: 46,
            },
            {
                nodeId: '14.03',
                nodeText: 'Split & Validation',
                url: '/charts/splitvalidation',
                moduleId: 46,
            },
            {
                nodeId: '14.04',
                nodeText: 'Coder View - STARS',
                url: '/charts/coderstars',
                moduleId: 46,
            },
            {
                nodeId: '14.05',
                nodeText: 'Coder View - MRA',
                url: '/charts/codermra',
                moduleId: 46,
            },
            {
                nodeId: '14.06',
                nodeText: 'STARS Admin',
                url: '/charts/starsadmin',
                moduleId: 46,
            },
            {
                nodeId: '14.07',
                nodeText: 'Dashboard',
                url: '/charts/dashboard',
                moduleId: 46,
            },
        ],
    },
    {
        nodeId: '15',
        nodeText: 'Panel Management',
        iconKey: 'PanelManagement',
        nodeChild: [
            {
                nodeId: '15.01',
                nodeText: 'My Panel',
                url: '/panelmgt/mypanel',
                moduleId: 51,
            },
            {
                nodeId: '15.03',
                nodeText: 'Admin',
                url: '/panelmgt/admin',
                moduleId: 53,
            },
        ],
    },
    {
        nodeId: '16',
        nodeText: 'Virtual PCP',
        iconKey: 'VirtualPCP',
        nodeChild: [
            {
                nodeId: '16.01',
                nodeText: 'My Virtual Panel/Patients',
                url: '/virtualpcp/mypanel',
                moduleId: 55,
            },
            {
                nodeId: '16.02',
                nodeText: 'Message Center',
                url: '/virtualpcp/messagecenter',
                moduleId: 57,
            },
            {
                nodeId: '16.03',
                nodeText: 'Audit History',
                url: '/virtualpcp/audithistory',
                moduleId: 105,
            },
            {
                nodeId: '16.04',
                nodeText: 'Telehealth Live Dashboard',
                url: '/virtualpcp/livedashboard',
                moduleId: 56,
            },
            {
                nodeId: '16.05',
                nodeText: 'Admin',
                url: '/virtualpcp/admin',
                moduleId: 101,
            },
        ],
    },
    {
        nodeId: '18',
        nodeText: 'Messages',
        iconKey: 'Messages',
        nodeChild: [
            {
                nodeId: '18.01',
                nodeText: 'Mailbox',
                url: '/messages',
                moduleId: 59,
            },
            {
                nodeId: '18.02',
                nodeText: 'Member Inquiries',
                url: '/memberinquiries',
                moduleId: 97,
            },
        ],
    },
    {
        nodeId: '19',
        nodeText: 'Care on Demand',
        iconKey: 'CareOnDemand',
        nodeChild: [
            {
                nodeId: '19.01',
                nodeText: 'My Panel/Patients',
                url: '/careondemand/mypanel',
                moduleId: 62,
            },
            {
                nodeId: '19.02',
                nodeText: 'Message Center',
                url: '/careondemand/messagecenter',
                moduleId: 63,
            },
            {
                nodeId: '19.03',
                nodeText: 'Audit History',
                url: '/careondemand/audithistory',
                moduleId: 64,
            },
            {
                nodeId: '19.04',
                nodeText: 'Admin',
                url: '/careondemand/admin',
                moduleId: 102,
            },
            {
                nodeId: '19.05',
                nodeText: 'Telehealth Live Dashboard',
                url: '/careondemand/livedashboard',
                moduleId: 56,
            },
        ],
    },
    {
        nodeId: '21',
        nodeText: 'My Office',
        iconKey: 'Eligibility',
        nodeChild: [
            {
                nodeId: '21.01',
                nodeText: 'Dashboard',
                url: '/myoffice/dashboard',
                moduleId: 72,
            },
        ],
    },
    {
        nodeId: '22',
        nodeText: 'Telehealth',
        iconKey: 'VirtualPCP',
        nodeChild: [
            {
                nodeId: '22.01',
                nodeText: 'My Panel/Patients',
                url: '/telehealth/mypanel',
                moduleId: 73,
            },
            {
                nodeId: '22.02',
                nodeText: 'Appointment Requests',
                url: '/telehealth/AppointmentRequests',
                moduleId: 75,
            },
        ],
    },
    {
        nodeId: '24',
        nodeText: 'Member Enrollment 360',
        iconKey: 'Member',
        nodeChild: [
            // {
            //     nodeId: '24.01',
            //     nodeText: 'Member Services',
            //     nodeChild: [
            //         {
            //             nodeId: '24.011',
            //             nodeText: 'Membership Info',
            //             url: '/ezm/member/admin',
            //             moduleId: 76,
            //         },
            //     ],
            // },
            // {
            //     nodeId: '24.02',
            //     nodeText: 'Broker & Web Enrollments',
            //     url: '/webenrollment/search',
            //     moduleId: 139,
            // },
            {
                nodeId: '24.03',
                nodeText: 'Paper Enrollment Intake',
                url: '/MemberEnrollmentUpload',
                moduleId: 150,
            },
            {
                nodeId: '24.04',
                nodeText: 'Enrollment Processing',
                url: '/MemberEnrollment',
                moduleId: 139,
            },
            {
                nodeId: '24.05',
                nodeText: 'Invalid Enrollment',
                url: '/ezm/invalid-member-portal',
                moduleId: 153,
            },
            {
                nodeId: '24.06',
                nodeText: 'Eob Notifications History',
                url: '/ezm/eob-notifications-history',
                moduleId: 163,
            },
            {
                nodeId: '24.07',
                nodeText: 'Reports and Utilities',
                url: '/MemberEnrollment/ReportsAndUtilities',
                moduleId: 172,
            },
            {
                nodeId: '24.08',
                nodeText: 'Admin And Support',
                url: '/MemberEnrollment/AdminAndSupport',
                moduleId: 176,
            },
            // {
            //     nodeId: '24.03',
            //     nodeText: 'System Admin',
            //     nodeChild: [
            //         {
            //             nodeId: '24.03',
            //             nodeText: 'CMS Imports',
            //             nodeChild: [
            //                 {
            //                     nodeId: '24.031',
            //                     nodeText: 'Batch Job Status Report',
            //                     url: '/ezm/cms/batch-status-report/',
            //                     moduleId: 76,
            //                 },
            //                 {
            //                     nodeId: '24.032',
            //                     nodeText: 'Batch Job Error Report',
            //                     url: '/ezm/cms/batch-error-report/',
            //                     moduleId: 76,
            //                 },
            //             ],
            //         },
            //     ],
            // },
            // {
            //     nodeId: '24.05',
            //     nodeText: 'Utilities',
            //     moduleId: 76,
            //     nodeChild: [
            //         {
            //             nodeId: '24.0501',
            //             nodeText: 'Token Viewer',
            //             url: '/ezm/utilities/token-viewer/',
            //             moduleId: 76,
            //         },
            //     ],
            // },
            // {
            //     nodeId: '24.06',
            //     nodeText: 'Broker Portal Admin',
            //     moduleId: 76,
            //     nodeChild: [
            //         {
            //             nodeId: '24.061',
            //             nodeText: 'Users',
            //             url: '/ezm/broker-portal-admin/account-search/',
            //             moduleId: 76,
            //         },
            //     ],
            // },
            // {
            //     nodeId: '24.07',
            //     nodeText: 'Member Portal Admin',
            //     moduleId: 76,
            //     nodeChild: [
            //         {
            //             nodeId: '24.071',
            //             nodeText: 'Member Accounts',
            //             url: '/ezm/member-portal-admin/account-search/',
            //             moduleId: 76,
            //         },
            //     ],
            // },
        ],
    },
    {
        nodeId: '25',
        nodeText: 'Member Portal Admin',
        iconKey: 'MemberPortalAdmin',
        nodeChild: [
            {
                nodeId: '25.03',
                nodeText: 'Provider Portal Admin',
                moduleId: 85,
                nodeChild: [
                    {
                        nodeId: '25.030',
                        nodeText: 'Login Accounts',
                        url: '/ezm/provider-portal-admin/account-search/',
                        moduleId: 85,
                    },
                    {
                        nodeId: '25.031',
                        nodeText: 'Registration Manager',
                        url: '/ezm/provider-portal-admin/registration-manager/',
                        moduleId: 85,
                    },
                ],
            },
        ],
    },
    {
        nodeId: '26',
        nodeText: 'Health Plan Management',
        iconKey: 'VirtualPCP',
        moduleId: 86,
        nodeChild: [
            {
                nodeId: '26.010',
                nodeText: 'Plan Editor',
                url: '/ezm/health-plan-management/search/',
                moduleId: 86,
            },
            {
                nodeId: '26.011',
                nodeText: 'Benefit Editor',
                url: '/ezm/health-plan-management-admin/benefit-editor/',
                moduleId: 87,
            },
        ],
    },
    // {
    //     nodeId: '27',
    //     nodeText: 'Provider Tools',
    //     iconKey: 'VirtualPCP',
    //     moduleId: 90,
    //     nodeChild: [
    //         {
    //             nodeId: '27.010',
    //             nodeText: 'Member Eligibility Check',
    //             url: '/provider-tools/eligibility-check',
    //             moduleId: 90,
    //         },
    //         {
    //             nodeId: '27.011',
    //             nodeText: 'Reports Submission',
    //             url: '/provider-tools/report-upload',
    //             moduleId: 90,
    //         },
    //     ],
    // },
    {
        nodeId: '29',
        nodeText: 'Admin',
        iconKey: 'Admin',
        nodeChild: [
            {
                nodeId: '29.01',
                nodeText: 'Applications',
                url: '/admin/applications',
                moduleId: 106,
            },
            {
                nodeId: '29.03',
                nodeText: 'Static Files Manager',
                url: '/admin/static-file-manager',
                moduleId: 119,
            },
            {
                nodeId: '29.04',
                nodeText: 'Content Manager',
                url: '/content-manager',
                /* temporarily use admin config module*/
                moduleId: 106,
            },
            {
                nodeId: '29.05',
                nodeText: 'Content Library',
                url: '/content-library',
                /* temporarily use admin config module*/
                moduleId: 106,
            },
            {
                nodeId: '29.06',
                nodeText: 'Performance View',
                url: '/performance-view',
                moduleId: 140,
            },
        ],
    },
    {
        nodeId: '30',
        nodeText: 'AVA Broker CRM',
        iconKey: 'BrokerPortal',
        nodeChild: [
            {
                nodeId: '30.09',
                nodeText: 'Broker and Agent Admin',
                url: '/broker/crm',
                moduleId: 159,
            },
            {
                nodeId: '30.010',
                nodeText: 'Commissions',
                url: '/broker/commissions',
                moduleId: 165,
            },
            {
                nodeId: '30.03',
                nodeText: 'Reports',
                url: '/broker/reports',
                moduleId: 127,
            },
            {
                nodeId: '30.04',
                nodeText: 'Analytics',
                url: '/broker/analytics',
                moduleId: 129,
            },
            {
                nodeId: '30.05',
                nodeText: 'User Security',
                url: '/broker/admin',
                moduleId: 84,
            },
            {
                nodeId: '30.06',
                nodeText: 'User Guides',
                url: '/broker/user-guides',
                moduleId: 173,
            },
        ],
    },
    {
        nodeId: '32',
        nodeText: 'Provider Data',
        iconKey: 'ProviderData',
        nodeChild: [
            {
                nodeId: '32.01',
                nodeText: 'IPA Admin',
                url: '/ezm/medical-group/ipa-administration/',
                moduleId: 99,
            },
            {
                nodeId: '32.02',
                nodeText: 'Contract Admin',
                url: '/ezm/network/contract-info',
                moduleId: 94,
            },
            {
                nodeId: '32.023',
                nodeText: 'Organization Admin',
                url: '/ezm/network/service-organization-admin/',
                moduleId: 92,
            },
            {
                nodeId: '32.024',
                nodeText: 'Facility Admin',
                url: '/ezm/network/hospital-maintenance',
                moduleId: 108,
            },
            {
                nodeId: '32.025',
                nodeText: 'DOFR Admin',
                url: '/ezm/network/template-manager',
                moduleId: 138,
            },
            {
                nodeId: '32.027',
                nodeText: 'NOC Request',
                url: '/ezm/provider-data-update-request',
                moduleId: 158,
            },
        ],
    },
    {
        nodeId: '33',
        nodeText: 'Appeals and Grievances',
        iconKey: 'AG360',
        nodeChild: [
            {
                nodeId: '33.01',
                nodeText: 'A&G 360',
                url: '/ezm/appeals-grievance',
                moduleId: 104,
            },
        ],
    },
    {
        nodeId: '34',
        nodeText: 'Sales Agent Certification',
        iconKey: 'VirtualPCP',
        nodeChild: [
            {
                nodeId: '34.01',
                nodeText: 'Sales Agents 360',
                nodeChild: [
                    {
                        nodeId: '34.011',
                        nodeText: 'Certification Session',
                        url: '/ezm/certification-session-manager',
                        moduleId: 137,
                    },
                    {
                        nodeId: '34.012',
                        nodeText: 'Notification Manager',
                        url: '/ezm/notification-manager',
                        moduleId: 149,
                    },
                    {
                        nodeId: '34.013',
                        nodeText: 'Compliance Report',
                        url: '/compliancereport/search',
                        moduleId: 136,
                    },
                    {
                        nodeId: '34.014',
                        nodeText: 'Sales Rep',
                        url: '/ezm/sales-rep',
                        moduleId: 151,
                    },
                ],
            },
        ],
    },
    {
        nodeId: '35',
        nodeText: 'Provider Search',
        url: '/providersearch',
        iconKey: 'ProviderData',
        moduleId: 152,
    },
    {
        nodeId: '37',
        nodeText: 'Provider Tools Admin',
        url: '/provider-tools-admin',
        iconKey: 'Admin',
        moduleId: 164,
    },
    {
        nodeId: '38',
        nodeText: 'Internal Escalations',
        url: '/crm/member-escalation',
        iconKey: 'Headphone',
        moduleId: 181,
    },
];
