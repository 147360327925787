import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { renderToStaticMarkup } from 'react-dom/server';

import styles from './LoadingScreen.module.scss';

type LoadingScreenProps = {
    width?: string;
    height?: string;
    text?: string;
    coverParent?: boolean;
    parentId?: string;
    spinnerColor?: string;
};
const LoadingScreen = ({
    width = 'auto',
    height = '500px',
    text = '',
    coverParent = false,
    parentId = '',
    spinnerColor = '',
}: LoadingScreenProps) => {
    const [appendedElseWhere, setAppendedElseWhere] = useState(false);

    useEffect(() => {
        if (parentId.length) {
            const parentElm = document.getElementById(
                parentId[0] === '#' ? parentId.slice(1) : parentId
            );
            if (parentElm) {
                setAppendedElseWhere(true);
                parentElm.style.position = 'relative';
                const loadingScreenComponent = (
                    <LoadingScreenContent
                        width={coverParent ? '100%' : width}
                        height={coverParent ? '100%' : height}
                        text={text}
                        spinnerColor={spinnerColor}
                    />
                );
                const loadingScreenElm = renderToStaticMarkup(loadingScreenComponent);
                const loadingScreenWrapper = document.createElement('div');
                loadingScreenWrapper.setAttribute(
                    'style',
                    'position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: white; z-index: 99999'
                );
                loadingScreenWrapper.innerHTML = loadingScreenElm;
                parentElm.appendChild(loadingScreenWrapper);

                return () => {
                    parentElm.removeChild(loadingScreenWrapper);
                };
            }
        }
    }, [coverParent, parentId, width, height, text, spinnerColor]);

    return appendedElseWhere ? null : (
        <LoadingScreenContent width={width} height={height} text={text} />
    );
};

const LoadingScreenContent = ({ width, height, text, spinnerColor }: LoadingScreenProps) => {
    return (
        <div className={styles.screen} style={{ width, height }} data-testid='loading-screen'>
            <div className={styles.content}>
                <Spinner
                    animation="border"
                    className={styles.spinner}
                    style={{ color: spinnerColor ? spinnerColor : '#0099a8' }}
                />
                <div className={styles.text}>{text}</div>
            </div>
        </div>
    );
};

export default LoadingScreen;
