import React, { useEffect, useState } from 'react';
import { DatePickerComponent, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Inject } from '@syncfusion/ej2-react-grids';
import { AxiosError } from 'axios';
import _ from 'lodash';
import moment from 'moment/moment';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { requests } from 'components/AvaEzm/AxiosApi';
import { IpaPlan } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { ipaPlanService } from 'components/ProviderData/subjects/IpaPlanService';
import { dialogService } from 'subjects/common/DialogService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';

export const IpaPlanAndCountyMappingForm = ({
    ipaPlan,
    contractCodes,
    companyCode,
    ipaCode,
    ipaId,
    classes,
    specialties,
    isViewer,
}) => {
    const [pbpList, setPbpList] = useState<string[]>([]);
    const [states, setStates] = useState<string[]>([]);
    const [counties, setCounties] = useState<any[]>([]);
    const [operatingCounties, setOperatingCounties] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [editCounty, setEditCounty] = useState<boolean>(false);
    const [overlapError, setOverlapError] = useState<string>();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            isActive: true,
            endDate: new Date(9999, 11, 31),
        },
    });
    let overlapMessage = '';
    const fetchStates = async () => {
        const _states: string[] = await requests.get('/api/CountyPlanMapping/GetStates');
        setStates(_states);
    };
    const fetchCounties = async (stateCode) => {
        const _counties: any[] = await requests.get(
            `/api/CountyPlanMapping/GetCountiesByStateCode?stateCode=${stateCode}`
        );
        setCounties(_counties.map((c) => ({ ...c, countyName: `${c.countyDescr} - ${c.countyCode}` })));
    };
    const fetchOperatingCounties = async () => {
        if (ipaPlan.pbp) {
            const _opCounties: any[] = await requests.get(
                `/api/CountyPlanMapping/GetOperatingCountyNamesByPbpCode?pbpCode=${ipaPlan.pbpId}`
            );
            setOperatingCounties(_opCounties);
        }
    };

    const saveInformation = async (data: IpaPlan) => {
        try {
            dialogService.loading(true);
            if (data.pbps) {
                for (const pbp of data.pbps) {
                    if (!pbp) {
                        continue;
                    }
                    const overlap = await verifyIfOverlaps(pbp);
                    if (!overlap) {
                        const adaptedData = _.omit(
                            {
                                ...data,
                                pbp,
                                county: Array.isArray(data.county) ? data.county.join(',') : '',
                                record_Id: ipaPlan?.id ? ipaPlan?.id : 0,
                                operationType: ipaPlan?.id ? 'UPDATE' : 'INSERT',
                                startDate: moment(data?.startDate).format('MM/DD/YYYY'),
                                endDate: moment(data?.endDate).format('MM/DD/YYYY'),
                                specialty: data?.specialty?.join(),
                            },
                            ['isDeleted']
                        );
                        const url = `/api/IpaPlan/AddOrUpdateIpaPlan`;
                        await requests.post(url, adaptedData);
                        dialogService.loading(false);
                        dialogService.close();
                        ipaPlanService.submit({ error: false });
                    } else {
                        ipaPlanService.submit({ error: true, message: overlapMessage });
                    }
                }
            }
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            ipaPlanService.submit({ error: true, message });
            dialogService.loading(false);
        }
    };

    const verifyIfOverlaps = async (pbp: string) => {
        const operationType = ipaPlan?.id ? 'UPDATE' : 'INSERT';
        const { contractCode, ipaCode, startDate, endDate } = getValues();
        const contractStartDate = moment(startDate).format('MM/DD/YYYY');
        const contractEndDate = moment(endDate).format('MM/DD/YYYY');
        const url = `/api/IpaPlan/IsPlanOverlapping?OperationType=${operationType}&ContractCode=${contractCode}&PBP=${pbp}&IpaCode=${ipaCode}&StartDate=${contractStartDate}&EndDate=${contractEndDate}`;
        const result: boolean = await requests.get(url);
        dialogService.loading(false);
        if (result) {
            overlapMessage = `Selected Contract Code= ${contractCode}, PBP= ${pbp}, Start Date= ${contractStartDate}and End Date= ${contractEndDate} Record is Overlapping with Existing Records`;
            dialogService.loading(false);
            setOverlapError(overlapMessage);
        }
        return result;
    };
    const fetchPbpCodes = async (contractCode: string) => {
        const response = await requests.get<string[]>(
            `/api/IpaPlan/GetPBPCodes?companyCode=${companyCode}&contractCode=${contractCode}`
        );
        setPbpList(response);
    };

    useEffect(() => {
        fetchStates();
        fetchOperatingCounties();
        const subscription = ipaPlanService.submit$().subscribe((validated) => {
            if (validated === undefined) {
                handleSubmit(saveInformation)();
            }
        });
        if (ipaPlan.id) {
            for (const c in ipaPlan) {
                if (c === 'county' || c === 'class' || c === 'specialty') {
                    if (ipaPlan[c]) {
                        setValue(c, ipaPlan[c].split(','));
                    }
                } else {
                    setValue(c, ipaPlan[c]);
                }
            }
            setValue('ipaId', ipaId);
            setValue('pbps', [ipaPlan.pbp]);
            if (ipaPlan.contractCode) {
                fetchPbpCodes(ipaPlan.contractCode);
            }
        } else {
            reset();
            setValue('ipaCode', ipaCode);
            setValue('companyCode', companyCode);
        }
        return () => {
            subscription.unsubscribe();
        };
    }, [ipaPlan]);

    return (
        <Row>
            <DisabledWrapper disabled={isViewer}>
                <form className="ipa-tab-form" style={{ padding: '5px' }}>
                    <div className="row">
                        {ipaPlan?.id && (
                            <div className="col-md-4">
                                <div className="text-value">
                                    <label className="float-input-field">IPA ID</label>
                                    <label className="float-input-value">{ipaId}</label>
                                    <span className="e-float-line" />
                                </div>
                            </div>
                        )}
                        <div className="col-md-4">
                            <div className="text-value">
                                <label className="float-input-field">IPA Code</label>
                                <label className="float-input-value">{ipaCode}</label>
                                <span className="e-float-line" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-value" style={{ margin: '0px' }}>
                                <label className="float-input-field">Company Code</label>
                                <label className="float-input-value">{companyCode}</label>
                                <span className="e-float-line" />
                            </div>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className="col-md-6 mt-3">
                            <div>
                                <label className="e-label-select mb-2">Start Date *</label>
                                <DatePickerComponent
                                    format="MM/dd/yyyy"
                                    enableMask={true}
                                    id="calendar_start"
                                    {...register('startDate', { required: 'Start Date is required' })}
                                    change={({ value }) => {
                                        setValue('startDate', value);
                                        setStartDate(value);
                                    }}
                                    min={undefined}
                                    max={new Date(9999, 11, 31)}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                            </div>
                            {errors.startDate && <p className="error-form">{errors.startDate.message}</p>}
                        </div>

                        <div className="col-md-6 mt-3">
                            <div>
                                <label className="e-label-select mb-2">End Date *</label>
                                <DatePickerComponent
                                    format="MM/dd/yyyy"
                                    enableMask={true}
                                    id="calendar_end"
                                    {...register('endDate', { required: 'End Date is required' })}
                                    change={({ value }) => setValue('endDate', value)}
                                    min={startDate}
                                    max={new Date(9999, 11, 31)}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                            </div>
                            {errors.endDate && <p className="error-form">{errors.endDate.message}</p>}
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className="col-md-6 mt-3">
                            <div>
                                <label className="e-label-select mb-2">Contract Code *</label>
                                <DropDownListComponent
                                    id="contractCode"
                                    dataSource={contractCodes}
                                    value={getValues().contractCode}
                                    placeholder="Select a Contract Code"
                                    change={({ value }) => {
                                        setValue('contractCode', value);
                                        fetchPbpCodes(value);
                                    }}
                                    popupHeight="220px"
                                    enabled={!ipaPlan?.id}
                                    {...register('contractCode', {
                                        required: 'Contract Code is required',
                                    })}
                                />
                            </div>
                            {errors.contractCode && <p className="error-form">{errors.contractCode.message}</p>}
                        </div>

                        {!ipaPlan?.id ? (
                            <div className="col-md-6  mt-3">
                                <div>
                                    <label className="e-label-select mb-2">PBP Code *</label>
                                    <MultiSelectComponent
                                        id="pbp"
                                        dataSource={pbpList}
                                        value={[String(getValues().pbps)]}
                                        mode="Default"
                                        placeholder="Select a PBP Code"
                                        disabled={!!ipaPlan?.id}
                                        change={({ value }) => {
                                            setValue('pbps', value);
                                        }}
                                        {...register('pbps', {
                                            required: 'PBP Code is required',
                                        })}
                                    />
                                </div>
                                {errors.pbps && <p className="error-form">{errors.pbps.message}</p>}
                            </div>
                        ) : (
                            <div className="col-md-6  mt-3">
                                <label className="e-label-select mb-2">PBP *</label>
                                <div
                                    className={`e-float-input e-input-group disable-field`}
                                    style={{ marginTop: '0px' }}
                                >
                                    <input
                                        type="text"
                                        {...register('pbp', {
                                            required: 'PBP Code is required',
                                        })}
                                        style={{ paddingLeft: '10px' }}
                                    />
                                    <span className="e-float-line" />
                                </div>
                                {errors.pbp && <p className="error-form">{errors.pbp.message}</p>}
                            </div>
                        )}
                    </div>
                </form>
            </DisabledWrapper>
            <p className="error-form">{overlapError}</p>
        </Row>
    );
};
