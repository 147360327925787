import { Subject } from 'rxjs';
import { HubConnection } from '@microsoft/signalr';
import { buildHubConnection } from '../../components/CareManagement/CareManagementPanelHelpers';

const snpPDTabUpdatedSubject = new Subject();
const memberGridAndTilesUpdatedSubject = new Subject();
const memberContactsUpdateSubject = new Subject();
const taskUpdateSubject = new Subject();
const carePlanUpdateSubject = new Subject();
const carePlanProblemNoteSubject = new Subject();
const attatchmentsUpdateSubject = new Subject();
const memberDetailsUpdateSubject = new Subject()

export const updateAttachmentTabService = {
    get: () => attatchmentsUpdateSubject.asObservable(),
    update: (args) => {
        attatchmentsUpdateSubject.next(args);
    },
};

// Updates CarePlanTab after it is saved
export const updateCarePlanTabService = {
    get: () => carePlanUpdateSubject.asObservable(),
    update: (args) => {
        carePlanUpdateSubject.next(args);
    },
};

// Updates Problem Item after a note is added
export const updateCarePlanProblemNoteService = {
    get: () => carePlanProblemNoteSubject.asObservable(),
    update: (args) => {
        carePlanProblemNoteSubject.next(args);
    },
};

export const updateTaskTabService = {
    get: () => taskUpdateSubject.asObservable(),
    update: (args) => {
        taskUpdateSubject.next(args);
    },
};

// updates Member Contacts tab after new contact is saved
export const updateMemberContactsTabService = {
    get: () => memberContactsUpdateSubject.asObservable(),
    update: (args) => {
        memberContactsUpdateSubject.next(args);
    },
};

export const updateMemberDetailsTabService = {
    get: () => memberDetailsUpdateSubject.asObservable(),
    update: (args) => {
        memberDetailsUpdateSubject.next(args);
    },
};

export const updateSNPPostDischargeGridService = {
    get: () => snpPDTabUpdatedSubject.asObservable(),
    update: (args) => {
        snpPDTabUpdatedSubject.next(args);
    },
};

export const updateGridAndTilesService = {
    get: () => memberGridAndTilesUpdatedSubject.asObservable(),
    update: (args) => {
        memberGridAndTilesUpdatedSubject.next(args);
    },
};

const updateMemberLettersGrid = new Subject();
export const updateMemberLettersGridService = {
    get: () => updateMemberLettersGrid.asObservable(),
    update: (args) => {
        updateMemberLettersGrid.next(args);
    },
};

let hubConectionCM: HubConnection;
const createHubConnection = new Subject();
const getConnectionsSubject = new Subject();
const notificationSubject = new Subject();
const messageCMSubject = new Subject();

const bindNotificationStream = (connection: HubConnection) => {
    connection.on(
        'Notification',
        (message: any, memberId: any, addOrRemove: any, triggerAddOrRemove: any, providerName: any) => {
            const notificationObject = {
                message,
                memberId,
                addOrRemove,
                triggerAddOrRemove,
                providerName,
            };

            notificationSubject.next(notificationObject);
        },
    );
};

const bindMessageStream = (connection: HubConnection) => {
    connection.on('Message', (message: any, member: any, status: any, tabId: any) => {
        const messageObject = {
            message,
            member,
            status,
            tabId,
        };
        messageCMSubject.next(messageObject);
    });
};

export const createHubConnectionService = {
    next: (hubPath: string) => {
        hubConectionCM = buildHubConnection(hubPath);

        hubConectionCM
            .start()
            .then(() => {
                hubConectionCM.invoke('GetConnections').then((response) => {
                    if (response) {
                        getConnectionsSubject.next(response);
                    }
                });
            })
            .catch((err) => console.log(err));

        bindNotificationStream(hubConectionCM);
        bindMessageStream(hubConectionCM);
    },
    get$: () => createHubConnection.asObservable(),
    getHubConnection: () => hubConectionCM,
    invokeSaveConnection: (tabId: string, tabIndex: number) => {
        hubConectionCM.invoke('SaveConnectionAsync', tabId, tabIndex);
    },
    invokeProvidersOnMember: (memberId: string) => {
        hubConectionCM.invoke('GetProvidersOnThisMember', memberId).then((response) => {
            return response;
        });
    },
    invokeFreeConnection: (memberId: string) => {
        hubConectionCM.invoke('FreeConnectionAsync', memberId);
    },
    closeAllStreams: () => {
        hubConectionCM.off('Notification');
        hubConectionCM.off('Message');
        hubConectionCM.stop();
    },
};
export const notificationSubjectService = {
    get$: () => notificationSubject.asObservable(),
};

export const messageCMSubjectService = {
    get$: () => messageCMSubject.asObservable(),
};
