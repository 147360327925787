import React, { useEffect, useState } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { formatISOLocalDateTime } from 'common/widgets';
import { requests } from 'components/AvaEzm/AxiosApi';
import {
    ControlledSyncfusionCheckBox,
    ControlledSyncfusionDatePicker,
    ControlledSyncfusionDropDownList,
    ControlledSyncfusionNumericTextBox,
    ControlledSyncfusionTextBox,
} from 'components/Common/SyncfusionWrappers/Syncfusion';
import { GainShareRateDetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { showConfirmation } from 'components/ProviderData/IpaAdministration/atoms/utils';
import {
    DECIMAL_2,
    DECIMAL_4,
    defaultValuesGainShareRates,
    defaultValuesGainShareTieredRates,
    GainShareLabel,
} from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/GainShareRates.Metadata';
import styles from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/GainShareRates.module.scss';
import { gainShareRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { SectionHeader } from 'components/UI/BoxSection';
import { useToast } from '@hooks';
import { dialogService } from 'subjects/common/DialogService';

type GainShareRateProps = {
    ipaCode: string;
    ipaId?: number;
    nextSequence?: number;
    gainShareRate?: GainShareRateDetail;
    capRevenues?: any;
    setPendingSelection: (data) => void;
    prevDatesGain:
        | {
              effectiveStartDate: Date;
              effectiveEndDate: Date;
          }[]
        | null;
    ipaInfo: {
        dates: {
            effDate: Date;
            endDate: Date;
        };
    };
    maxEndDate?: Date | undefined;
    isReadOnlyViewer: boolean;
};

export const GainShareRateForm = (props: GainShareRateProps) => {
    const {
        ipaCode,
        nextSequence,
        gainShareRate,
        capRevenues,
        ipaInfo,
        prevDatesGain,
        maxEndDate,
        isReadOnlyViewer,
        setPendingSelection,
    } = props;
    const { dates } = ipaInfo;
    const {
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        getValues,
        reset,
        watch,
        formState: { errors, isDirty, dirtyFields },
        control,
    } = useForm<GainShareRateDetail | any>({ mode: 'all' });
    const [loading, setLoading] = useState(false);
    const [formula, setFormula] = useState<string>();
    const [effMinDate, setEffMinDate] = useState<string | Date | undefined>();
    const [isSgaPmPm, isSgaRevenuePercent] = watch(['isSgaPmPm', 'isSgaRevenuePercent']);
    const { showErrorToast } = useToast();
    const minDate = gainShareRate?.isCopy
        ? gainShareRate?.effectiveStartDate
        : prevDatesGain && prevDatesGain?.length > 0 && !gainShareRate?.rateId
        ? prevDatesGain?.find((p) => p)?.effectiveEndDate
        : dates?.effDate;
    const isFormClean = !isDirty || Object.keys(dirtyFields).length <= 0;

    const saveInformation = async (data: GainShareRateDetail) => {
        try {
            const url = `/api/IpaGainShareRate/`;
            let action = 'CreateGainShareRate';

            const formattedData = {
                ...gainShareRate,
                ...data,
                ipaCode,
                sequence: gainShareRate?.rateId ? gainShareRate.sequence : nextSequence,
                formula,
            };
            let rate;
            setLoading(true);
            if (formattedData?.rateId) {
                action = 'UpdateGainShareRate';
                rate = await requests.put(`${url}${action}`, formattedData);
            } else rate = await requests.post(`${url}${action}`, formattedData);
            setLoading(false);
            setPendingSelection(rate);
            gainShareRateService.submit();
            dialogService.close();
        } catch (e) {
            console.error(e);
            dialogService.loading(false);
            setLoading(false);
            const message = `${(e as AxiosError)?.response?.data}`;
            showErrorToast(message ?? `Failed to ${data?.rateId ? 'update' : 'create'} record. Please try again later`);
        }
    };

    const validateGainShareDates = (field: string, date: any) => {
        const fieldName = field === 'effectiveStartDate' ? 'effective' : 'end';
        if (prevDatesGain) {
            for (const prevDate of prevDatesGain) {
                const effectiveStartDate = moment(prevDate?.effectiveStartDate).startOf('day');
                const effectiveEndDate = moment(prevDate?.effectiveEndDate).startOf('day');
                const dateToCompare =
                    field === 'effectiveStartDate'
                        ? moment(date.value || date).add(-1, 'day')
                        : moment(date.value || date).add(1, 'day');
                if (prevDate?.effectiveEndDate && dateToCompare.isBetween(effectiveStartDate, effectiveEndDate)) {
                    setError(field, {
                        message: `The ${fieldName} date range should not overlap with date range of another record.`,
                    });
                    return;
                } else {
                    clearErrors(field);
                }
            }
        }
    };

    const changeCheckBoxHandler = (field, checked) => {
        const checkBoxControls = ['isSgaRevenuePercent', 'isSgaPmPm'];

        const unselectedControls = checkBoxControls.filter((r) => r !== field);
        const unselected = checked ? unselectedControls[0] : field;

        setValue(unselected as keyof GainShareRateDetail, false);
        if (unselected == 'isSgaRevenuePercent') {
            setValue('sgaRevenuePercent', '0');
            setValue('isSgaRevenuePercent', false);
        }

        if (unselected == 'isSgaPmPm') {
            setValue('sgaPmPm', '0');
            setValue('isSgaPmPm', false);
        }

        if (checked) {
            if (field == 'isSgaRevenuePercent') {
                setValue('isSgaRevenuePercent', true);
            }

            if (field == 'isSgaPmPm') {
                setValue('isSgaPmPm', true);
            }
        }
    };

    useEffect(() => {
        let formData: GainShareRateDetail = { ...defaultValuesGainShareRates } as any;
        if (gainShareRate?.id || gainShareRate?.isCopy)
            formData = {
                ...gainShareRate,
                effectiveStartDate: moment(gainShareRate.effectiveStartDate).format('MM/DD/YYYY'),
                effectiveEndDate: moment(gainShareRate.effectiveEndDate).format('MM/DD/YYYY'),
            };

        const effDate =
            gainShareRate?.id || !prevDatesGain || prevDatesGain?.length == 0
                ? minDate
                : moment(minDate).add(1, 'day').toDate();
        setEffMinDate(effDate);
        if (!gainShareRate?.id) {
            formData.effectiveStartDate = moment(effDate).toDate();
            formData.effectiveEndDate = moment(dates?.endDate).toDate();
        }

        reset(formData);

        if (gainShareRate?.isCopy) {
            setValue('effectiveStartDate', moment(gainShareRate?.effectiveStartDate).add(1, 'day').toDate());
        }
    }, [gainShareRate]);

    return (
        <div style={{ border: '1px solid #c8c8c8', padding: '0' }}>
            {gainShareRate?.id && (
                <SectionHeader title="Gain Share Details">
                    <button
                        type="button"
                        className="e-btn avaButton text-capitalize"
                        disabled={loading}
                        onClick={async () => {
                            if (!isReadOnlyViewer) {
                                const shouldProceed = isFormClean
                                    ? isFormClean
                                    : await showConfirmation({
                                          message:
                                              'You have unsaved changes, which may be lost if you proceed. Do you want to continue?',
                                      });
                                if (!shouldProceed) return;
                            }
                            gainShareRateService.viewHistory(gainShareRate);
                        }}
                    >
                        View Change History
                    </button>
                </SectionHeader>
            )}
            <DisabledWrapper disabled={isReadOnlyViewer || loading} withSpinner={loading}>
                <form onSubmit={handleSubmit(saveInformation)} style={{ padding: '10px' }}>
                    <Row className="center-fields mb-3 mt-1 col-12 mx-auto">
                        <Col md={3}>
                            <div>
                                <label className="e-label-select mb-2">{GainShareLabel.capRevenue} *</label>
                                <ControlledSyncfusionDropDownList
                                    name="capRevenue"
                                    control={control}
                                    dataSource={capRevenues}
                                    fields={{ text: 'text', value: 'code' }}
                                    ddlAttrs={{
                                        placeholder: 'Select a Cap Revenue',
                                        popupHeight: '220px',
                                        popupWidth: '900px',
                                    }}
                                    onChangeHandler={(value) =>
                                        setFormula(capRevenues?.find((r) => r.code === value)?.description)
                                    }
                                />
                            </div>
                            <Form.Text className="text-danger">{errors.capRevenue?.message}</Form.Text>
                        </Col>
                        <p className="formula-description mb-0 col-md-9 mt-3 ">{formula && formula}</p>
                    </Row>

                    <Row className="center-fields border-bottom border-2 mb-0 pb-3 col-12 mx-auto">
                        <Col md={6}>
                            <TooltipComponent content={`Min Date: ${moment(effMinDate).format('MM/DD/yyyy')}`}>
                                <div className="form-group">
                                    <div className="reversed">
                                        <Form.Label>{GainShareLabel.effectiveStartDate}</Form.Label>
                                        <ControlledSyncfusionDatePicker
                                            name="effectiveStartDate"
                                            control={control}
                                            rules={{ required: 'Effective Date is required' }}
                                            isRetrievingDateAsString={true}
                                            dpAttrs={{
                                                strictMode: true,
                                                min: effMinDate ? new Date(effMinDate) : undefined,
                                                max: dates?.endDate,
                                            }}
                                            onChangeHandler={(value) => {
                                                validateGainShareDates('effectiveStartDate', value);
                                            }}
                                        />
                                    </div>
                                    <Form.Text className="text-danger">{errors.effectiveStartDate?.message}</Form.Text>
                                </div>
                            </TooltipComponent>
                        </Col>
                        <Col md={6}>
                            <TooltipComponent content={`Max Date: ${moment(dates?.endDate).format('MM/DD/yyyy')}`}>
                                <div className="form-group">
                                    <div className="reversed">
                                        <Form.Label>{GainShareLabel.effectiveEndDate}</Form.Label>
                                        <ControlledSyncfusionDatePicker
                                            name="effectiveEndDate"
                                            control={control}
                                            dpAttrs={{
                                                strictMode: true,
                                                min: getValues().effectiveStartDate,
                                                max: dates?.endDate,
                                            }}
                                            isRetrievingDateAsString={true}
                                            rules={{ required: 'End Date is required' }}
                                            onChangeHandler={(value) => {
                                                validateGainShareDates('effectiveEndDate', value);
                                            }}
                                        />
                                    </div>
                                    <Form.Text className="text-danger">{errors.effectiveEndDate?.message}</Form.Text>
                                </div>
                            </TooltipComponent>
                        </Col>
                    </Row>

                    <Row className="center-field py-3 col-12 mx-auto">
                        <Col md={3}>
                            <div className="form-group">
                                <div className="reversed">
                                    <Form.Label>{GainShareLabel.profitSharePercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name="profitSharePercent"
                                        control={control}
                                        defaultValue={gainShareRate?.profitSharePercent}
                                        boxAttrs={DECIMAL_4}
                                    />
                                </div>
                                <Form.Text className="text-danger">{errors.profitSharePercent?.message}</Form.Text>
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className="form-group">
                                <div className="reversed">
                                    <Form.Label>{GainShareLabel.revenuePercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name="revenuePercent"
                                        control={control}
                                        defaultValue={gainShareRate?.revenuePercent}
                                        boxAttrs={DECIMAL_4}
                                    />
                                </div>
                                <Form.Text className="text-danger">{errors.revenuePercent?.message}</Form.Text>
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className="form-group">
                                <div className="reversed">
                                    <Form.Label>{GainShareLabel.partCRevenuePmPm}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name="partCRevenuePmPm"
                                        control={control}
                                        defaultValue={gainShareRate?.partCRevenuePmPm}
                                        boxAttrs={DECIMAL_2}
                                    />
                                </div>
                                <Form.Text className="text-danger">{errors.partCRevenuePmPm?.message}</Form.Text>
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className="form-group">
                                <div className="reversed">
                                    <Form.Label>{GainShareLabel.partDRevenuePmPm}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name="partDRevenuePmPm"
                                        control={control}
                                        defaultValue={gainShareRate?.partDRevenuePmPm}
                                        boxAttrs={DECIMAL_2}
                                    />
                                </div>
                                <Form.Text className="text-danger">{errors.partDRevenuePmPm?.message}</Form.Text>
                            </div>
                        </Col>
                    </Row>

                    <Row className="center-fields border-2 col-12 mx-auto">
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name="isPartDExclusion"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: GainShareLabel.isPartDExclusion,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name="isDeficitCarryover"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: GainShareLabel.isDeficitCarryover,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name="isSupplementalPmPm"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: GainShareLabel.isSupplementalPmPm,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name="isClinicalModelPmPm"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: GainShareLabel.isClinicalModelPmPm,
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="row col-12 mx-auto">
                        <p className="section-container-header header-margin">SG&A Configuration</p>
                        <Row className="center-fields row-section col-9">
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <div className="reversed">
                                        <ControlledSyncfusionCheckBox
                                            name="isSgaRevenuePercent"
                                            control={control}
                                            defaultValue={gainShareRate?.isSgaRevenuePercent}
                                            label={GainShareLabel.sgaRevenuePercent}
                                            onChangeHandler={(checked) => {
                                                changeCheckBoxHandler('isSgaRevenuePercent', checked);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="reversed">
                                        <ControlledSyncfusionNumericTextBox
                                            name="sgaRevenuePercent"
                                            control={control}
                                            defaultValue={gainShareRate?.sgaRevenuePercent}
                                            boxAttrs={{
                                                enabled: isSgaRevenuePercent,
                                                showSpinButton: false,
                                                validateDecimalOnType: true,
                                                min: 0,
                                                strictMode: true,
                                                placeholder: '0.0000',
                                                format: '0.0000',
                                                decimals: 4,
                                            }}
                                        />
                                    </div>
                                    <Form.Text className="text-danger">{errors.sgaRevenuePercent?.message}</Form.Text>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <div className="reversed">
                                        <ControlledSyncfusionCheckBox
                                            name="isSgaPmPm"
                                            control={control}
                                            defaultValue={gainShareRate?.isSgaPmPm}
                                            label={GainShareLabel.sgaPmPm}
                                            onChangeHandler={(checked) => {
                                                changeCheckBoxHandler('isSgaPmPm', checked);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="reversed">
                                        <ControlledSyncfusionNumericTextBox
                                            name="sgaPmPm"
                                            control={control}
                                            defaultValue={gainShareRate?.sgaPmPm}
                                            boxAttrs={{
                                                enabled: isSgaPmPm,
                                                showSpinButton: false,
                                                validateDecimalOnType: true,
                                                min: 0,
                                                strictMode: true,
                                                placeholder: '0.0000',
                                                format: '0.0000',
                                                decimals: 4,
                                            }}
                                        />
                                    </div>
                                    <Form.Text className="text-danger">{errors.sgaPmPm?.message}</Form.Text>
                                </div>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="center-fields col-12 mx-auto">
                        <Col md={12}>
                            <div className="form-group">
                                <div className="reversed">
                                    <Form.Label>{GainShareLabel.notes}</Form.Label>
                                    <ControlledSyncfusionTextBox
                                        name="notes"
                                        control={control}
                                        defaultValue={gainShareRate?.notes}
                                        multiline={true}
                                    />
                                </div>
                                <Form.Text className="text-danger">{errors.notes?.message}</Form.Text>
                            </div>
                        </Col>
                    </Row>

                    {gainShareRate?.id && (
                        <Row className="center-fields field-margin-top">
                            <Col md={5}>
                                <Row>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created By</label>
                                            <input type="text" readOnly value={gainShareRate?.createdById ?? ''} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated By</label>
                                            <input type="text" readOnly value={gainShareRate?.lastUpdatedById ?? ''} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approved By</label>
                                            <input type="text" readOnly value={gainShareRate?.lastApprovedById ?? ''} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={7} className="mt-3 d-flex justify-content-end">
                                <div className={styles.formButtonsGroup}>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            gainShareRateService.addGainShareTieredRate({
                                                ...defaultValuesGainShareTieredRates,
                                                isGainShareRate: false,
                                                effectiveStartDate: gainShareRate?.effectiveStartDate,
                                                effectiveEndDate: gainShareRate?.effectiveEndDate,
                                                status: gainShareRate?.status,
                                                ipaCode: gainShareRate.ipaCode,
                                                parentRateId: gainShareRate?.rateId,
                                                gainShareRateId: gainShareRate?.id,
                                                createdAt: new Date(),
                                                parentRateData: gainShareRate,
                                            })
                                        }
                                        className={`btn btn-primary btn-flat ${styles.formButton}`}
                                    >
                                        Add New Tiered Rate
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            gainShareRateService.copy({
                                                ...gainShareRate,
                                                effectiveStartDate: maxEndDate,
                                                isCapRate: true,
                                                capRevenues,
                                                ipaCode,
                                                sequence: nextSequence,
                                                id: null,
                                                rateId: 0,
                                                maxEndDate,
                                            });
                                        }}
                                        className={`btn btn-primary btn-flat btn-orange ${styles.formButton}`}
                                    >
                                        Copy Gain Share Rate
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    )}

                    <Row className="center-fields">
                        <Col md={5}>
                            {gainShareRate?.id && (
                                <Row>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    gainShareRate?.createdAt
                                                        ? formatISOLocalDateTime(gainShareRate.createdAt)
                                                        : undefined
                                                }
                                                value={
                                                    gainShareRate?.createdAt
                                                        ? formatISOLocalDateTime(gainShareRate.createdAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    gainShareRate?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(gainShareRate.lastUpdatedAt)
                                                        : undefined
                                                }
                                                value={
                                                    gainShareRate?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(gainShareRate.lastUpdatedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approved Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    gainShareRate?.lastApprovedAt
                                                        ? formatISOLocalDateTime(gainShareRate.lastApprovedAt)
                                                        : undefined
                                                }
                                                value={
                                                    gainShareRate?.lastApprovedAt
                                                        ? formatISOLocalDateTime(gainShareRate.lastApprovedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Col>

                        <Col md={7} className="mt-2 d-flex justify-content-end">
                            <div className={styles.formButtonsGroup}>
                                <button
                                    type="button"
                                    onClick={() => gainShareRateService.cancel()}
                                    className={`btn btn-secondary btn-flat ${styles.formButton}`}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={`btn btn-primary btn-flat ${styles.formButton}`}
                                    disabled={isFormClean}
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </DisabledWrapper>
        </div>
    );
};
