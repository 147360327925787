import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { IpaHospital } from 'components/ProviderData/IpaAdministration/atoms/Models';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { Col, Row } from 'react-bootstrap';
import styles from 'components/ProviderData/IpaAdministration/organisms/IpaHospital/IpaHospital.module.scss';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';

type IpaHospitalFormProps = {
    ipaHospital: IpaHospital;
};
const IpaHospitalForm = (props: IpaHospitalFormProps) => {
    const { ipaHospital } = props;

    return (
        <>
            <DisabledWrapper disabled={true}>
                <Row>
                    <Col md={6}>
                        <div className={`e-float-input e-input-group`}>
                            <input type="text" value={ipaHospital?.hospitalId} />
                            <span className="e-float-line"></span>
                            <label className="e-float-text">Hospital ID</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`e-float-input e-input-group`}>
                            <input type="text" value={ipaHospital?.organizationName} />
                            <span className="e-float-line"></span>
                            <label className="e-float-text">Organization Name</label>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="6">
                        <div className="no-padding">
                            <label className="e-label-select mb-2">Start Date</label>
                            <DatePickerComponent
                                format="MM/dd/yyyy"
                                id="calendar_start"
                                enableMask
                                value={ipaHospital?.effDate as any}
                                strictMode
                            >
                                <Inject services={[MaskedDateTime]} />
                            </DatePickerComponent>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="no-padding">
                            <label className="e-label-select mb-2">Term. Date</label>
                            <DatePickerComponent
                                format="MM/dd/yyyy"
                                id="calendar_end"
                                enableMask
                                strictMode
                                value={ipaHospital?.termDate as any}
                            >
                                <Inject services={[MaskedDateTime]} />
                            </DatePickerComponent>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className={`e-float-input e-input-group`}>
                            <input type="text" value={ipaHospital?.npi} />
                            <span className="e-float-line"></span>
                            <label className="e-float-text">NPI</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`e-float-input e-input-group`}>
                            <input type="text" value={ipaHospital?.taxId} />
                            <span className="e-float-line"></span>
                            <label className="e-float-text">Tax ID</label>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <label>
                        <b>Contact Information</b>
                    </label>
                    <Col md={6} className="mt-3">
                        <label className="e-label-select mb-2">Street 1</label>
                        <div className={'e-float-input e-input-group mb-3'} style={{ marginTop: '0px' }}>
                            <input type="text" value={ipaHospital?.address1} />
                            <span className="e-float-line"></span>
                        </div>
                    </Col>
                    <Col md={6} className="mt-3">
                        <label className="e-label-select mb-2">Street 2</label>
                        <div className={`e-float-input e-input-group mb-3`} style={{ marginTop: '0px' }}>
                            <input type="text" value={ipaHospital.address2} />
                            <span className="e-float-line"></span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <label className="e-label-select mb-2">City</label>
                        <div className={`e-float-input e-input-group mb-3`} style={{ marginTop: '0px' }}>
                            <input type="text" value={ipaHospital.city} />
                            <span className="e-float-line"></span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={`mb-3`}>
                            <label className="e-label-select mb-2">State</label>
                            <MaskedTextBoxComponent mask="LL" value={ipaHospital.state} />
                            <span className="e-float-line"></span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={`mb-3`}>
                            <label className="e-label-select mb-2">Zip</label>
                            <MaskedTextBoxComponent mask="00000-0000" value={ipaHospital.zip} />
                            <span className="e-float-line"></span>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={`mb-3`}>
                            <label className="e-label-select mb-2">Phone</label>
                            <MaskedTextBoxComponent mask="(000) 000-0000" value={ipaHospital.phone} />
                            <span className="e-float-line"></span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <label>
                        <b>Hospital Services</b>
                    </label>
                </Row>
            </DisabledWrapper>
            <div>
                <ListBoxComponent
                    id="lsthospitalServices"
                    dataSource={ipaHospital.hospitalServices}
                    height="300"
                    cssClass={styles.e_list_item}
                />
            </div>
        </>
    );
};

export default IpaHospitalForm;
