import React, { useState, useEffect, useRef } from 'react';
import { disconnectRoom } from '../../helpers';
import { VideoService } from '../../VideoService';
import { conversationSid$, conversation$ } from '../../VirtualVisitService';

const VideoParticipant = ({ participant, onlyAudio = false, room, setEnableAudio }: any) => {
    const [videoTracks, setVideoTracks]: any = useState([]);
    const [audioTracks, setAudioTracks]: any = useState([]);
    const [dataTracks, setDataTracks]: any = useState([]);
    const [conversation, setConversation]: any = useState(null);

    const [lastEvent, setLastEvent]: any = useState(null);

    const subscriptionsRef: any = useRef([]);

    const videoRef: any = useRef();
    const audioRef: any = useRef();

    const trackpubsToTracks: any = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication: any) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        if (participant.videoTracks) {
            setVideoTracks(trackpubsToTracks(participant.videoTracks));
        }
        if (participant.audioTracks) {
            setAudioTracks(trackpubsToTracks(participant.audioTracks));
        }
        if (participant.dataTracks) {
            setDataTracks(trackpubsToTracks(participant.dataTracks));
        }

        const trackSubscribed = (track) => {
            if (track.kind === 'video') {
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === 'audio') {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            } else if (track.kind === 'data') {
                track.on('message', (data) => {
                    setLastEvent(JSON.parse(data));
                });
                setDataTracks((dataTracks) => [...dataTracks, track]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === 'video') {
                setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
            } else if (track.kind === 'audio') {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            } else if (track.kind === 'data') {
                setDataTracks((dataTracks) => [...dataTracks, track]);
            }
        };

        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);

        participant.on('trackUnpublished', () => {
            //video was removed
            if (participant.constructor.name === 'RemoteParticipant') {
                setVideoTracks([]);
            }
        });

        const conversationSub = conversation$.subscribe({
            next: (conversation) => {
                setConversation(conversation);
            },
        });
        subscriptionsRef.current = [...subscriptionsRef.current, conversationSub];

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners && participant.removeAllListeners();
            if (subscriptionsRef.current.length > 0) {
                subscriptionsRef.current.forEach((sub) => sub.unsubscribe());
            }
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    useEffect(() => {
        if (lastEvent) {
            const { localParticipant } = room;
            switch (lastEvent.eventKind) {
                case 'mute': {
                    const { params } = lastEvent;

                    if (localParticipant.identity === params.identity) {
                        localParticipant.audioTracks.forEach(function (trackPublication) {
                            if (trackPublication.kind === 'audio') {
                                if (trackPublication.isTrackEnabled) {
                                    trackPublication.track.disable();
                                    setEnableAudio(false);
                                } else {
                                    trackPublication.track.enable();
                                    setEnableAudio(true);
                                }
                            }
                        });
                    }

                    break;
                }
                case 'logout': {
                    const { params } = lastEvent;
                    if (localParticipant.identity === params.identity) {
                        disconnectRoom(room);
                        VideoService.showVideo(null, null, undefined);
                    }
                    if (conversation) {
                        conversation.leave();
                    }
                    break;
                }
                case 'conversation': {
                    conversationSid$.next(lastEvent.params.conversationSid);
                    break;
                }
                default:
                    break;
            }
        }
    }, [lastEvent, setEnableAudio, room, conversation]);

    return (
        <div className={`participant ${participant.videoTracks && participant.videoTracks.size === 0 && 'hide-video'}`}>
            {!onlyAudio && <video ref={videoRef} autoPlay={true} />}
            <audio ref={audioRef} autoPlay={true} />
        </div>
    );
};

export default VideoParticipant;
