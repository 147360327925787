import { talkdeskOutboundService } from '../../subjects/crossModules/TalkdeskOutboundService';
import React, { useEffect, useState } from 'react';
import useInterval from './UseInterval';
import { talkdeskInboundService } from '../../subjects/crossModules/TalkdeskInboundService';
import CallTimer from './CallTimer';
import CallNotes from './CallNotes';
import './CallOverlay.css';
import { TalkdeskNote, talkdeskNotesService as notesService } from '../../subjects/TalkdeskNotesService';
import { myQueueService } from '../../subjects/CRM/MyQueueService';
import { notificationService } from '../../subjects/common/NotificationService';
import { CallEvent } from '../../interfaces/Talkdesk';
import { currentUserService } from '../../subjects/common/CurrentUserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop } from '@fortawesome/free-regular-svg-icons';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

export default function CallOverlay() {
    const [ongoingCall, setOngoingCall] = useState(false);
    const [callDuration, setCallDuration] = useState(1);
    const [caller, setCaller] = useState<string | null>(null);
    const [talkdeskId, setTalkdeskId] = useState<string>('Unknown');
    const [userId, setUserId] = useState<string>('');
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    // when ongoingCall == true, the timer triggers
    useInterval(() => setCallDuration(callDuration + 1), ongoingCall);

    // get the current user
    useEffect(() => {
        const currentUser = currentUserService.get().subscribe((user: any) => setUserId(user.userId));
        return () => currentUser.unsubscribe();
    }, []);

    useEffect(() => {
        // get ongoing call data
        const setCall = (ongoingCall: boolean, caller: string | null, talkdeskId: string, callDuration: number) => {
            setShowOverlay(ongoingCall);
            setOngoingCall(ongoingCall);
            setCaller(caller);
            setTalkdeskId(talkdeskId);
            setCallDuration(callDuration);
        };
        const ongoingCall = (call: CallEvent): boolean => !call.ended && call.interaction_id !== '0';
        const outboundCalls = talkdeskOutboundService
            .outboundCallMessages$()
            .subscribe((call) => setCall(ongoingCall(call), call.memberName, call.interaction_id, 1));
        const inboundCalls = talkdeskInboundService
            .inboundCallMessages$()
            .subscribe((call) => setCall(ongoingCall(call), call.contact_phone_number, call.interaction_id, 1));

        // sends the note to the backend, for saving in db
        const noteHandler = (note: TalkdeskNote) => {
            const displayError = () =>
                notificationService.warning({
                    title: `An error occurred while saving the notes for this call.`,
                });
            const resultHandler = (success: boolean) => (success ? notesService.noteSaved$(note) : displayError());
            const savedNote = myQueueService
                .upsertCallNotes$(note.talkdeskId, userId, note.notes)
                .subscribe({ next: resultHandler, error: displayError });
            callNotes.add(savedNote);
        };
        const callNotes = notesService.queue$.subscribe(noteHandler);

        return () => {
            outboundCalls.unsubscribe();
            inboundCalls.unsubscribe();
            callNotes.unsubscribe();
        };
    }, [ongoingCall]);

    return (
        showOverlay && (
            <>
                <div className={'call-timer-container'}>
                    <CallTimer callDuration={callDuration} />
                    <CallNotes caller={caller} talkdeskId={talkdeskId} />
                    <TooltipComponent content={'Dismiss'} target="#talkdesk-call-overlay-dismiss">
                        <span id="talkdesk-call-overlay-dismiss" className={'overlay-icon'}>
                            <FontAwesomeIcon icon={faCircleStop} size="lg" onClick={() => setShowOverlay(false)} />
                        </span>
                    </TooltipComponent>
                </div>
                <div className={'call-timer-popup-container'} />
            </>
        )
    );
}
