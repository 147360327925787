import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import moment from "moment-timezone";

export const maxBatchFileSize = 125829120;

export const maximumFileSize = 20971520;

export const trimValues = (value) => typeof value === 'string' ? value?.trim() : value;

export const DateConverter = (dateStr, dateFormat) => {
    const isValidDate = moment(dateStr, dateFormat).isValid();
    return isValidDate ? moment(dateStr, dateFormat).toDate() : undefined;
};

export function formatDateTZ(date, emptyValue = '') {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!date) return emptyValue;
    const m = moment(date).tz(userTimeZone);
    return m.isValid() ? m.format('yyyy-MM-DD') : date;
}

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return (`${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`);
}

export function uploadedFileGridTemplate(deleteFile) {
    return [
        {
            field: 'name',
            headerText: 'File Name',
            type: 'text',
            autoFit: false,
            width: 400
        },
        {
            field: 'description',
            headerText: 'Description',
            type: 'text',
            autoFit: false,
            width: 300
        },
        {
            field: 'size',
            headerText: 'Size',
            type: 'text',
            autoFit: false,
            width: 100,
            template: (data) => {
                return formatBytes(data.size) ?? '0 Bytes';
            },
        },
        {
            field: 'uploadedBy',
            headerText: 'Uploaded By',
            type: 'text',
            autoFit: false,
            width: 240
        },
        {
            field: 'uploadedDate',
            headerText: 'Uploaded Date',
            type: 'date',
            format: 'MM/dd/yyyy',
            autoFit: false,
            width: 150,
        },
        {
            field: '',
            headerText: '',
            type: 'text',
            autoFit: false,
            width: 70,
            template: (args) => {
                return (<i className="fa fa-trash" aria-hidden="true" title="Delete File" style={{ fontSize: '18px', color: '#03659a', cursor: 'pointer' }} onClick={(e) => { deleteFile(args); }}></i >);
            },
        },
    ];
}

export function panelStatusGridTemplate(changePanelStatus) {
    return [
        {
            field: 'practiceAddress',
            headerText: 'Practice Address',
            type: 'text',
            autoFit: false,
            width: 500
        },
        {
            field: 'currentPanelStatus',
            headerText: 'Current Panel Status',
            type: 'text',
            autoFit: false,
            width: 150,
            template: (args) => {
                return args.currentPanelStatus === true ? 'Yes' : 'No';
            },
        },
        {
            field: 'newPanelStatus',
            headerText: 'Accepting new Patient',
            type: 'text',
            autoFit: false,
            width: 150,
            template: (args) => {
                return (<CheckBoxComponent
                    label=''
                    checked={args.newPanelStatus}
                    change={({ checked }) => {
                        changePanelStatus(args, checked)
                    }}
                />);
            },
        },
    ];
}