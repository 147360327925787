import axios, { AxiosResponse } from 'axios';
import Auth from 'Auth';

export const instance = axios.create({
    timeout: 30000,
});

export const requests = {
    get: <T>(url: string, params?: any): Promise<T> => {
        return instance
            .get(url, {
                headers: {
                    Authorization: `Bearer ${Auth.getToken()}`,
                },
                params: { ...params },
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },
    put: <T>(url: string, data: Record<string, any>, params?: any): Promise<T> => {
        return instance
            .put(url, data, {
                headers: {
                    Authorization: `Bearer ${Auth.getToken()}`,
                },
                params: { ...params },
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },

    post: <T>(url: string, data: Record<string, any>): Promise<T> => {
        return instance
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${Auth.getToken()}`,
                },
                params: {},
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },

    deleteWithParams: <T>(url: string, data: Record<string, any>, params?: any): Promise<T> => {
        return instance
            .put(url, data, {
                headers: {
                    Authorization: `Bearer ${Auth.getToken()}`,
                },
                params: { ...params },
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },

    delete: <T>(url: string): Promise<T> => {
        return instance
            .delete(url, {
                headers: {
                    Authorization: `Bearer ${Auth.getToken()}`,
                },
                params: {},
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },
};
