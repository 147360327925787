//this will be the typescript basecomponent until everyone is converted to typescript

import React from 'react';
import { Observable, Observer, Subscription } from 'rxjs';

export default class BaseComponent<P, S = any> extends React.PureComponent<P, S> {
    subscriptions: Subscription[];

    constructor(props: P) {
        super(props);

        this.subscriptions = [];
    }

    componentWillUnmount() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    subscribe = (observable: Observable<any>, observer: Observer<any>) =>
        observable && this.subscriptions.push(observable.subscribe(observer));
}
