import React, { useEffect, useState } from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ControlledSyncfusionDatePicker } from 'components/Common/SyncfusionWrappers/Syncfusion';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { requests } from 'common/axios';
import { AdditionalContact } from 'components/ProviderData/IpaAdministration/atoms/Models';
import {
    AdditionalContactResponse,
    additionalContactService,
} from 'components/ProviderData/subjects/IpaAdditionalContactService';
import { dialogService } from 'subjects/common/DialogService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';

export const AdditionalContactForm = ({ contact, departments, id, onFormDirtyChange, isReadOnlyViewer, risk }) => {
    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors, isDirty },
        watch,
        trigger,
    } = useForm<any>({
        defaultValues: {
            misDirectedPayerId: '',
            tradingPartnerId: '',
            canAcceptEditInboundOutbound: false,
            canAcceptClaimTypes: false,
            endDt: new Date(9999, 11, 31),
        },
    });
    const [startDate, setStartDate] = useState<Date>();
    const [department, setDepartment] = useState<string>();
    const [claimAddressControlFlag, setClaimAddressControlFlag] = useState(false);
    const [isClaimAddressFlag, setIsClaimAddressFlag] = useState(contact?.isClaimAddress);
    const [claimAddressData, setClaimAddressData] = useState(contact?.claimAddress);
    const saveInformation = async (data: AdditionalContact) => {
        try {
            dialogService.loading(true);
            let action = 'AddAdditionalContact';
            const adaptedData = {
                ...data,
                id: contact?.id ? contact?.id : 0,
                operationType: null,
                department: data.department,
                misDirectedPayerId: data.misDirectedPayerId ? data.misDirectedPayerId : null,
                startDt: moment(data?.startDt).format('MM/DD/YYYY'),
                endDt: moment(data?.endDt).format('MM/DD/YYYY'),
            };
            if (contact?.id) {
                action = 'UpdateAdditionalContact';
            }
            adaptedData.ipaWeb_Id = id;
            const url = `/api/AdditionalContact/${action}`;
            const response = (await requests.post(url, adaptedData)) as AdditionalContactResponse;

            additionalContactService.submit(response);
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            additionalContactService.submit({ error: true, message });
        } finally {
            dialogService.loading(false);
            dialogService.close();
        }
    };

    useEffect(() => {
        const subscription = additionalContactService.submit$().subscribe((validation) => {
            if (validation === undefined) {
                handleSubmit(saveInformation)();
            }
        });
        if (contact.id) {
            setDepartment(contact.department);
            for (const c in getValues()) {
                setValue(c, contact[c]);
            }
        } else {
            reset();
        }
        return () => {
            subscription.unsubscribe();
        };
    }, [contact]);

    useEffect(() => {
        onFormDirtyChange(isDirty);
    }, [watch]);

    return (
        <DisabledWrapper disabled={isReadOnlyViewer}>
            <form>
                <div className="mb-3">
                    <label className="e-label-select mb-2">Department *</label>
                    <DropDownListComponent
                        id="department"
                        {...register('department', { required: 'Department is required' })}
                        dataSource={departments}
                        placeholder="Select a department"
                        value={contact?.department}
                        fields={{ text: 'text', value: 'id' }}
                        change={({ value, itemData }) => {
                            setValue('department', itemData?.id);
                            setDepartment(itemData?.id);
                        }}
                        popupHeight="220px"
                        allowFiltering={true}
                    />
                    {errors.department && <p className="error-form">{errors.department.message}</p>}
                </div>
                <Row className="mb-3">
                    <Col md="6">
                        <div className="no-padding">
                            <label className="e-label-select mb-2">Start Date *</label>
                            <ControlledSyncfusionDatePicker
                                format="MM/dd/yyyy"
                                name="startDt"
                                control={control}
                                dpAttrs={{
                                    showClearButton: true,
                                    placeholder: 'Start date',
                                    min: undefined,
                                    max: new Date(9999, 11, 31),
                                    strictMode: true,
                                    enableMask: true,
                                }}
                                onChangeHandler={({ valueAsDate }: { valueAsDate: Date }) => {
                                    setStartDate(valueAsDate);
                                }}
                                isRetrievingDateAsString={true}
                                rules={{
                                    required: 'Start date is required',
                                }}
                            />
                            {errors.startDt && <p className="error-form">{errors.startDt.message}</p>}
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="no-padding">
                            <label className="e-label-select mb-2">Term. Date *</label>
                            <ControlledSyncfusionDatePicker
                                format="MM/dd/yyyy"
                                name="endDt"
                                control={control}
                                dpAttrs={{
                                    showClearButton: true,
                                    placeholder: 'End date',
                                    min: startDate,
                                    max: new Date(9999, 11, 31),
                                    strictMode: true,
                                    enableMask: true,
                                }}
                                isRetrievingDateAsString={true}
                                rules={{
                                    required: 'End date is required',
                                }}
                            />
                            {errors.endDt && <p className="error-form">{errors.endDt.message}</p>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    {department === 'CLAIM' && risk && risk !== 'C' && risk !== 'F' && (
                        <Col md="12">
                            <div>
                                <div className={`e-float-input e-input-group`}>
                                    <input
                                        type="text"
                                        {...register('claimAddress', {
                                            validate: (value) => {
                                                if (value === '' && claimAddressControlFlag === true) {
                                                    return 'Claim Address is Required';
                                                }
                                            },
                                        })}
                                        onChange={(obj) => {
                                            setValue('claimAddress', obj.currentTarget.value);
                                            setClaimAddressData(obj.currentTarget.value);
                                            trigger(['claimAddress']);
                                        }}
                                        value={claimAddressData}
                                    />
                                    <span className="e-float-line"></span>
                                    <label className="e-float-text">
                                        Claim Address {claimAddressControlFlag === true && '*'}
                                    </label>
                                </div>
                                {errors.claimAddress && <p className="error-form">Claim Address is Required</p>}
                            </div>
                            <div className="mt-3">
                                <CheckBoxComponent
                                    {...register('isClaimAddress')}
                                    checked={isClaimAddressFlag}
                                    label="Is Claim Address Display on IDCard"
                                    change={({ checked }) => {
                                        setIsClaimAddressFlag(checked);
                                        setValue('isClaimAddress', checked);
                                        setClaimAddressControlFlag(checked);
                                        trigger(['claimAddress']);
                                    }}
                                />
                            </div>
                        </Col>
                    )}
                </Row>

                {department === 'CLM' ? (
                    <div>
                        <Row>
                            <Col md={6}>
                                <div className={`e-float-input e-input-group`}>
                                    <input type="text" {...register('misDirectedPayerId')} />
                                    <span className="e-float-line"></span>
                                    <label className="e-float-text">Misdirected Payer ID</label>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`e-float-input e-input-group`}>
                                    <input type="text" {...register('tradingPartnerId')} />
                                    <span className="e-float-line"></span>
                                    <label className="e-float-text">Trading Partner ID </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <CheckBoxComponent
                                    {...register('canAcceptEditInboundOutbound')}
                                    checked={contact.canAcceptEditInboundOutbound}
                                    label="Accept EDI for Inbound and Outbound"
                                    change={({ checked }) => setValue('canAcceptEditInboundOutbound', checked)}
                                />
                            </Col>
                            <Col md={6}>
                                <CheckBoxComponent
                                    {...register('canAcceptClaimTypes')}
                                    checked={contact.canAcceptClaimTypes}
                                    label="Accept Claim Types"
                                    change={({ checked }) => setValue('canAcceptClaimTypes', checked)}
                                />
                            </Col>
                        </Row>
                    </div>
                ) : null}
                <Row className="mt-4">
                    <Col md={6}>
                        <label>
                            <b>Primary Contact</b>
                        </label>
                        <div className="mt-3">
                            <label className="e-label-select mb-2">Title</label>
                            <div className={`e-float-input e-input-group mb-3`} style={{ marginTop: '0px' }}>
                                <input type="text" {...register('primaryContactTitle')} />
                                <span className="e-float-line"></span>
                            </div>
                            <label className="e-label-select mb-2">Name</label>
                            <div
                                className={`e-float-input e-input-group ${errors.primaryContactName ? 'mb-1' : 'mb-3'}`}
                                style={{ marginTop: '0px' }}
                            >
                                <input
                                    type="text"
                                    {...register('primaryContactName', { required: 'Name is required' })}
                                />
                                <span className="e-float-line"></span>
                            </div>
                            {errors.primaryContactName && (
                                <p className="error-form">{errors.primaryContactName.message}</p>
                            )}
                            <label className="e-label-select mb-2">Street 1</label>
                            <div
                                className={`e-float-input e-input-group ${
                                    errors.primaryContactStreet1 ? 'mb-1' : 'mb-3'
                                }`}
                                style={{ marginTop: '0px' }}
                            >
                                <input type="text" {...register('primaryContactStreet1')} />
                                <span className="e-float-line"></span>
                            </div>
                            {errors.primaryContactStreet1 && (
                                <p className="error-form">{errors.primaryContactStreet1.message}</p>
                            )}
                            <label className="e-label-select mb-2">Street 2</label>
                            <div className={`e-float-input e-input-group mb-3`} style={{ marginTop: '0px' }}>
                                <input type="text" {...register('primaryContactStreet2')} />
                                <span className="e-float-line"></span>
                            </div>
                            <label className="e-label-select mb-2">City</label>
                            <div
                                className={`e-float-input e-input-group ${errors.primaryContactCity ? 'mb-1' : 'mb-3'}`}
                                style={{ marginTop: '0px' }}
                            >
                                <input type="text" {...register('primaryContactCity')} />
                                <span className="e-float-line"></span>
                            </div>
                            {errors.primaryContactCity && (
                                <p className="error-form">{errors.primaryContactCity.message}</p>
                            )}
                            <Row>
                                <Col md={6}>
                                    <div className={`${errors.primaryContactState ? 'mb-1' : 'mb-3'}`}>
                                        <label className="e-label-select mb-2">State</label>
                                        <MaskedTextBoxComponent mask="LL" {...register('primaryContactState')} />
                                        <span className="e-float-line"></span>
                                    </div>
                                    {errors.primaryContactState && (
                                        <p className="error-form">{errors.primaryContactState.message}</p>
                                    )}
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <label className="e-label-select mb-2">Zip</label>
                                        <div
                                            className={`e-float-input e-input-group ${
                                                errors.primaryContactZipCode ? 'mb-1' : 'mb-3'
                                            }`}
                                            style={{ marginTop: '0px' }}
                                        >
                                            <input
                                                type="text"
                                                {...register('primaryContactZipCode', {
                                                    pattern: {
                                                        value: /^\d{5}([\-]?\d{4})?$/,
                                                        message:
                                                            'Incorrect input. Should be either xxxxx or xxxxx-xxxx',
                                                    },
                                                })}
                                            />
                                            <span className="e-float-line"></span>
                                        </div>
                                        {errors.primaryContactZipCode && (
                                            <p className="error-form">{errors.primaryContactZipCode.message}</p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <div className={`${errors.primaryContactPhone ? 'mb-1' : 'mb-3'}`}>
                                        <label className="e-label-select mb-2">Phone</label>
                                        <MaskedTextBoxComponent
                                            mask="(000) 000-0000"
                                            {...register('primaryContactPhone', {
                                                pattern: {
                                                    value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                                    message: 'Incorrect format phone number (123)123-1234',
                                                },
                                            })}
                                        />
                                        <span className="e-float-line"></span>
                                    </div>
                                    {errors.primaryContactPhone && (
                                        <p className="error-form">{errors.primaryContactPhone.message}</p>
                                    )}
                                </Col>
                                <Col md={4}>
                                    <label className="e-label-select mb-2">Ext</label>
                                    <div className={`e-float-input e-input-group mb-3`} style={{ marginTop: '0px' }}>
                                        <input type="text" {...register('primaryContactExtn')} />
                                        <span className="e-float-line"></span>
                                    </div>
                                </Col>
                            </Row>
                            <label className="e-label-select mb-2">Email</label>
                            <div
                                className={`e-float-input e-input-group ${
                                    errors.primaryContractEmail ? 'mb-1' : 'mb-3'
                                }`}
                                style={{ marginTop: '0px' }}
                            >
                                <input
                                    type="text"
                                    {...register('primaryContractEmail', {
                                        pattern: {
                                            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                            message: 'Email is not valid',
                                        },
                                    })}
                                />
                                <span className="e-float-line"></span>
                            </div>
                            {errors.primaryContractEmail && (
                                <p className="error-form">{errors.primaryContractEmail.message}</p>
                            )}

                            <div className="mb-3" style={{ marginBottom: 10 }}>
                                <label className="e-label-select mb-2">Fax</label>
                                <MaskedTextBoxComponent
                                    mask="(000) 000-0000"
                                    {...register('primaryContractFax', {
                                        pattern: {
                                            value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                            message: 'Fax is not valid',
                                        },
                                    })}
                                />
                                <span className="e-float-line"></span>
                                {errors.primaryContractFax && (
                                    <p className="error-form">{errors.primaryContractFax.message}</p>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <label style={{ marginLeft: '15px' }}>
                            <b>Secondary Contact</b>
                        </label>
                        <div
                            className="mt-3"
                            style={{
                                borderLeft: '1px solid gray',
                                paddingLeft: '15px',
                                height: '629px',
                            }}
                        >
                            <label className="e-label-select mb-2">Title</label>
                            <div className={`e-float-input e-input-group mb-3`} style={{ marginTop: '0px' }}>
                                <input type="text" {...register('secondaryContactTitle')} />
                                <span className="e-float-line"></span>
                            </div>
                            <label className="e-label-select mb-2">Name</label>
                            <div className={`e-float-input e-input-group mb-3`} style={{ marginTop: '0px' }}>
                                <input type="text" {...register('secondaryContactName')} />
                                <span className="e-float-line"></span>
                            </div>
                            <div className="no-padding mb-1">
                                <label className="e-label-select mb-2">Phone</label>
                                <MaskedTextBoxComponent
                                    mask="(000) 000-0000"
                                    {...register('secondaryContactPhone', {
                                        pattern: {
                                            value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                            message: 'Incorrect format phone number (123)123-1234',
                                        },
                                    })}
                                />
                                <span className="e-float-line"></span>
                            </div>
                            {errors.secondaryContactPhone && (
                                <p className="error-form">{errors.secondaryContactPhone.message}</p>
                            )}
                            <div className="no-padding mb-3" style={{ marginTop: '-4px' }}>
                                <label className="e-label-select mb-2">Fax</label>
                                <MaskedTextBoxComponent
                                    mask="(000) 000-0000"
                                    {...register('secondaryContactFax', {
                                        pattern: {
                                            value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                            message: 'Fax is not valid',
                                        },
                                    })}
                                />
                                <span className="e-float-line"></span>
                                {errors.secondaryContactFax && (
                                    <p className="error-form">{errors.secondaryContactFax.message}</p>
                                )}
                            </div>
                            <label className="e-label-select mb-3">Email</label>
                            <div className={`e-float-input e-input-group mb-1`} style={{ marginTop: '-3px' }}>
                                <input
                                    type="text"
                                    {...register('secondaryContactEmail', {
                                        pattern: {
                                            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                            message: 'Email is not valid',
                                        },
                                    })}
                                />
                                <span className="e-float-line"></span>
                            </div>
                            {errors.secondaryContactEmail && (
                                <p className="error-form">{errors.secondaryContactEmail.message}</p>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="no-padding mb-3" style={{ marginTop: 10 }}>
                            <label className="e-label-select mb-2">Comments</label>
                            <div className={`e-float-input e-input-group`} style={{ marginTop: '0px' }}>
                                <textarea rows={4} {...register('comments')} />
                                <span className="e-float-line"></span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </DisabledWrapper>
    );
};
