import React from 'react';
import ParticipantInfo from './ParticipantInfo';

export const getParticipantInfo = (
    participant,
    participantName,
    optionalAttendees,
    memberConnectionId,
    sendMessage,
    saveAuditTrail
) => {
    let indexOtherParticipant = -1;
    if (optionalAttendees && optionalAttendees.length > 0) {
        indexOtherParticipant = optionalAttendees.findIndex(
            (optionalAttendee) => optionalAttendee?.connectionId === participant.identity
        );
    }

    if (
        participant.identity === memberConnectionId ||
        participant.identity.startsWith('PCP Office') ||
        indexOtherParticipant !== -1
    ) {
        return (
            <ParticipantInfo
                key={participant.sid}
                participant={participant}
                sendMessage={sendMessage}
                saveAuditTrail={saveAuditTrail}
                participantName={participantName}
                isPhoneCall={false}
            />
        );
    } else {
        return (
            <ParticipantInfo
                key={participant.sid}
                participant={participant}
                sendMessage={sendMessage}
                saveAuditTrail={saveAuditTrail}
                participantName={participantName}
                isPhoneCall={true}
            />
        );
    }
};
