import React, { useEffect, useRef, useState } from 'react';
import { map, filter } from 'rxjs/operators';
import PageTemplate from 'components/Common/PageTemplate';
import { IPA } from 'components/ProviderData/IpaAdministration/atoms/Models';
import IpaAdministrationDetailView from 'components/ProviderData/IpaAdministration/organisms/IpaAdministrationDetailView';
import IpaAdministrationSearch from 'components/ProviderData/IpaAdministration/organisms/IpaAdministrationSearch';
import { selectedIpaAdministrationService } from 'components/ProviderData/subjects/IpaAdministrationService';
import ModuleLayout, {
    syncfusionObservableConverter,
    syncfusionTabItemsConverter,
} from 'components/Common/ModuleLayout/ModuleLayout';
import 'css/styles.css';
import './IPAAdministrationPage.scss';

const tabItems = [
    {
        header: {
            text: 'Search',
        },
        cssClass: 'nonClosableTab',

        content: () => (
            <div className="filter-buttons-alignment">
                <IpaAdministrationSearch />
            </div>
        ),
    },
];

const IpaAdministrationPage = () => {
    const [tabs] = useState(tabItems);
    const pageTemplateRef = useRef<PageTemplate>(null);

    useEffect(() => {
        selectedIpaAdministrationService.get$().subscribe((data) => {
            if (pageTemplateRef.current && !data.id && !pageTemplateRef.current?.tabRefs?.find((c) => c.new)) {
                pageTemplateRef.current.addTabRef({ new: true, props: {} });
            }
        });
    }, []);
    const getTabObservable = () => {
        return selectedIpaAdministrationService.get$().pipe(
            filter((ipa) =>
                !ipa.id && Number(pageTemplateRef.current?.tabRefs?.length) > 1
                    ? !pageTemplateRef.current?.tabRefs?.find((c) => c.new)
                    : true
            ),
            map((ipa: IPA) => ({ id: ipa.id, ipa })),
            map(({ ipa, id }) => ({
                id,
                tab: {
                    header: {
                        text: id ? `${id} - ${ipa.ipaCode}, ${ipa?.ipaDesc || ''}` : 'Create IPA',
                    },
                    content: () => {
                        const tab = <IpaAdministrationDetailView tabId={id} ipaId={id} ipaCode={ipa.ipaCode} />;
                        if (pageTemplateRef.current) {
                            pageTemplateRef.current.addTabRef(tab);
                        }
                        return tab;
                    },
                },
            }))
        );
    };
    return (
        <div className='IpaAdministrationContainer'>
            <ModuleLayout
                header="IPA/Medical Group Admin"
                tabs={syncfusionTabItemsConverter(tabs)}
                tab$={syncfusionObservableConverter(getTabObservable())}
            />
        </div>
    );
};

export default IpaAdministrationPage;
