import { Subject } from 'rxjs';

const filterChangedSubject = new Subject();

const filtersChangedService = {
    change: (panelid, name, value) => {
        filterChangedSubject.next({ panelid, name, value });
    },
    get: () => filterChangedSubject.asObservable(),
};

const cleanFilterSubject = new Subject();
const cleanAllFilterService = {
    get: () => cleanFilterSubject.asObservable(),
    clean: (panelid) => {
        cleanFilterSubject.next(panelid);
    },
};

export { filtersChangedService, cleanAllFilterService };
