import { ODataV4Adaptor } from '@syncfusion/ej2-data';
import { getAvaDataManager } from 'common/AVADataManager';

export const initResize = (e) => {
    window.addEventListener('mousemove', Resize, false);
    window.addEventListener('mouseup', stopResize, false);
};

export const Resize = (e) => {
    e.preventDefault();
    const container: any = document.getElementById('virtual-visit-pcp');

    container.style.width = e.clientX - container.offsetLeft + 'px';
    container.style.height = e.clientY - container.offsetTop + 'px';

    return container;
};

export const stopResize = (e) => {
    window.removeEventListener('mousemove', Resize, false);
    window.removeEventListener('mouseup', stopResize, false);
};

export function getUpcomingPastVisitsDataManager(module, typeEndpoint, memberId) {
    return getAvaDataManager(`/odata/${module}/Default.${typeEndpoint}?memberId=${memberId}`, ODataV4Adaptor);
}
