import { BehaviorSubject } from 'rxjs';
import { ajaxPost } from '../../../common/ajax';

const conversationSid$ = new BehaviorSubject<string | null>(null);
const conversation$ = new BehaviorSubject<any>(null);

const virtualVisitService = {
    updateVirtualAppointment$: (appointmentId: string, conversationSid: string) =>
        ajaxPost(`api/virtualappointments/update`, {
            appointmentId: appointmentId,
            conversationSid: conversationSid,
        }),
};

export { conversationSid$, conversation$, virtualVisitService };
