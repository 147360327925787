import { DataManager, UrlAdaptor, ODataV4Adaptor, ODataAdaptor, JsonAdaptor } from '@syncfusion/ej2-data';
import { ExtendedODataV4Adaptor } from './ExtendedODataV4Adaptor';
import { memberDataTypes } from './widgets';
import Auth from '../Auth';

// Patch is the default method for update data for grid components, then if is not defined that will be set as default
function getAvaDataManager(url, Adaptor, updateUrl, updateType = 'PATCH', offlineEnabled = false) {
    if (!Adaptor) {
        Adaptor = UrlAdaptor;
    }

    return new DataManager({
        url,
        updateUrl: updateUrl,
        adaptor: new Adaptor({ updateType: updateType }),
        offline: offlineEnabled,
        headers: [
            {
                Authorization: `Bearer ${Auth.getToken()}`,
            },
        ],
    });
}

function getMemberDataManager(memberId, memberType) {
    return getAvaDataManager(`/api/members/${memberId}/${memberType}`);
}

function getODataV4DataManager(url, updateUrl, updateType) {
    return getAvaDataManager(url, ExtendedODataV4Adaptor, updateUrl, updateType);
}

function getVPCPVisits({ appointmentType, moduleUrl }) {
    moduleUrl = moduleUrl ?? 'VirtualAppointments';
    let url = '';

    switch (appointmentType) {
        case memberDataTypes.myUpcomingVisits:
            url = `/odata/${moduleUrl}/Default.MyUpcoming`;
            break;
        case memberDataTypes.myPastVisits:
            url = `/odata/${moduleUrl}/Default.MyPrevious`;
            break;
        case memberDataTypes.myAllVisits:
            url = `/odata/VirtualAppointments`;
            break;
    }

    if (!url.length) {
        return;
    }

    return getAvaDataManager(url, ODataV4Adaptor);
}

const getProcedureSearchDataManager = () => {
    return getAvaDataManager(`/api/proceduresearch?ProcCodeCategory=CPT,HCPCS,REV,MEDICAID`, ODataAdaptor);
};

const getDiagnosisSearchDataManager = () => {
    return getAvaDataManager(`/api/diagnosissearch`, ODataAdaptor);
};

const getUserQueuesAdaptor = (userId) =>
    getAvaDataManager(`odata/userqueuemap?$expand=user,queue&$filter=userId eq '${userId}'`, ODataV4Adaptor);

const getJSONDataManager = (obj) => {
    return new DataManager({
        json: obj,
        adaptor: new JsonAdaptor(),
    });
};

function getAvaDataManagerWithURLs({ url, Adaptor, updateUrl, insertUrl, removeUrl, updateType = 'PATCH' }) {
    if (!Adaptor) {
        Adaptor = UrlAdaptor;
    }

    return new DataManager({
        url,
        adaptor: new Adaptor({ updateType: updateType }),
        updateUrl: updateUrl,
        insertUrl: insertUrl,
        removeUrl: removeUrl,
        headers: [
            {
                Authorization: `Bearer ${Auth.getToken()}`,
            },
        ],
    });
}

export {
    getODataV4DataManager,
    getAvaDataManager,
    getVPCPVisits,
    getMemberDataManager,
    getProcedureSearchDataManager,
    getDiagnosisSearchDataManager,
    getUserQueuesAdaptor,
    getJSONDataManager,
    getAvaDataManagerWithURLs,
};
