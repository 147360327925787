import { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Auth from 'Auth';
import { requests } from 'common/axios';
import { formatISOLocalDateTime } from 'common/widgets';
import {
    ControlledSyncfusionCheckBox,
    ControlledSyncfusionDatePicker,
    ControlledSyncfusionNumericTextBox,
} from 'components/Common/SyncfusionWrappers/Syncfusion';
import { RiskPoolRateDetail, RiskPoolTieredRate } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { showConfirmation } from 'components/ProviderData/IpaAdministration/atoms/utils';
import { RiskPoolLabel } from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRates.Metadata';
import styles from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRates.module.scss';
import { riskPoolRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { SectionHeader } from 'components/UI/BoxSection';
import { useToast } from '@hooks';
import { dialogService } from 'subjects/common/DialogService';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const BASE_URI = 'api/IpaRiskPoolRate';
const CREATE_ENDPOINT = '/CreateRiskPoolTieredRate';
const UPDATE_ENDPOINT = '/UpdateRiskPoolTieredRate';

type RiskPoolTieredRateFormProps = {
    data: RiskPoolTieredRate;
    isReadOnlyViewer: boolean;
    setPendingSelection: (data) => void;
};

const TEXTBOX_ATTRS = { showSpinButton: false, validateDecimalOnType: true, min: 0, strictMode: true };
const DECIMAL_4 = {
    ...TEXTBOX_ATTRS,
    placeholder: '0.0000',
    format: '0.0000',
    decimals: 4,
};

const RiskPoolTieredRateForm = ({ data, isReadOnlyViewer, setPendingSelection }: RiskPoolTieredRateFormProps) => {
    const {
        control,
        formState: { errors, isDirty, dirtyFields },
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors,
        getValues,
    } = useForm<RiskPoolTieredRate | any>({ defaultValues: data, mode: 'onSubmit' });
    const { showErrorToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [isESRDApplicable, setIsESRDApplicable] = useState(false);
    const [maxMemberCount, setMaxMemberCount] = useState<number>(0);
    const [listOfRiskPoolTieredRate, setListOfRiskPoolTieredRate] = useState<RiskPoolTieredRate[] | undefined>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const user = Auth.currentUser();
    const isFormClean = !isDirty || Object.keys(dirtyFields).length <= 0;

    const populateUpdateData = (riskPoolTieredRateData: RiskPoolTieredRate) => {
        setLoading(true);
        riskPoolTieredRateData.lastUpdatedAt = new Date().toISOString();
        riskPoolTieredRateData.lastUpdatedById = user.emails?.at(0)?.split('@')?.at(0);
        return riskPoolTieredRateData;
    };

    const validateMemberCount = (field: string, count: number) => {
        const fieldName = field === 'memberCountStart' ? 'member count start' : 'member count end';
        const overLapCount = listOfRiskPoolTieredRate?.filter(
            (tieredRate) => tieredRate.memberCountStart <= count && count <= tieredRate.memberCountEnd,
        );

        if (overLapCount && overLapCount?.length > 0) {
            setError(field, {
                message: `The ${fieldName} should not overlap with the member count range of another record.`,
            });
        } else {
            clearErrors(field);
        }

        setDisabled(Object.keys(errors).length > 0 ? true : false);
    };

    const saveInformation = async (data: RiskPoolTieredRate) => {
        const effDt = new Date(data.effectiveStartDate);
        const endDt = new Date(data.effectiveEndDate);
        data.effectiveStartDate = `${effDt.getMonth() + 1}/${effDt.getDate()}/${effDt.getFullYear()}`;
        data.effectiveEndDate = `${endDt.getMonth() + 1}/${endDt.getDate()}/${endDt.getFullYear()}`;
        populateUpdateData(data);
        if (data?.id) onSave(data);
        else onCreate(data);
    };

    const onCreate = (riskPoolTieredRateData: RiskPoolTieredRate) => {
        riskPoolTieredRateData.createdById = riskPoolTieredRateData.lastUpdatedById;
        riskPoolTieredRateData.createdAt = riskPoolTieredRateData.lastUpdatedAt;
        dialogService.loading(true);
        let isSuccessful = false;
        requests
            .post<RiskPoolRateDetail>(BASE_URI + CREATE_ENDPOINT, { data: riskPoolTieredRateData })
            .then((data) => {
                setPendingSelection(data.riskPoolTieredRates.at(data.riskPoolTieredRates.length - 1));
                dialogService.close();
                isSuccessful = true;
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to create record. Please try again later');
            })
            .finally(() => {
                setLoading(false);
                dialogService.loading(false);
                if (isSuccessful) riskPoolRateService.submit();
            });
    };

    const onSave = (riskPoolTieredRateData: RiskPoolTieredRate) => {
        let isSuccessful = false;
        requests
            .put(BASE_URI + UPDATE_ENDPOINT, { data: riskPoolTieredRateData })
            .then(() => {
                setPendingSelection(riskPoolTieredRateData);
                isSuccessful = true;
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to update record. Please try again later');
            })
            .finally(() => {
                setLoading(false);
                if (isSuccessful) riskPoolRateService.submit();
            });
    };

    useEffect(() => {
        reset({
            ...data,
            effectiveEndDate: moment(data?.effectiveEndDate).format('MM/DD/YYYY'),
            effectiveStartDate: moment(data?.effectiveStartDate).format('MM/DD/YYYY'),
        });
        const listOfTieredRates = data.parentRateData?.riskPoolTieredRates.filter(
            (tieredRate) => !data.parentRateId || tieredRate.rateId != data?.rateId,
        );

        setListOfRiskPoolTieredRate(listOfTieredRates);

        let previousEndCount =
            listOfTieredRates && listOfTieredRates?.length > 0
                ? data.memberCountStart
                    ? data.memberCountStart - 1
                    : 0
                : 0;

        previousEndCount =
            listOfTieredRates
                ?.sort((a, b) => b.memberCountEnd - a.memberCountEnd)
                .filter((tieredRate) => !data.memberCountStart || tieredRate.memberCountEnd < data.memberCountStart)[0]
                ?.memberCountEnd ?? previousEndCount;

        setMaxMemberCount(previousEndCount);
        setIsESRDApplicable(data?.isESRDApplicable);
    }, [data, reset]);

    return (
        <div style={{ border: '1px solid #c8c8c8', padding: '0' }}>
            {data.id && (
                <SectionHeader title="Risk Pool Details">
                    <button
                        type="button"
                        className="btn avaButton text-capitalize"
                        disabled={loading}
                        onClick={async () => {
                            if (!isReadOnlyViewer) {
                                const shouldProceed = isFormClean
                                    ? isFormClean
                                    : await showConfirmation({
                                          message:
                                              'You have unsaved changes, which may be lost if you proceed. Do you want to continue?',
                                      });
                                if (!shouldProceed) return;
                            }
                            riskPoolRateService.viewHistory(data);
                        }}
                    >
                        View Change History
                    </button>
                </SectionHeader>
            )}
            <DisabledWrapper disabled={isReadOnlyViewer || loading} withSpinner={loading}>
                <form onSubmit={handleSubmit(saveInformation)} style={{ padding: '10px' }}>
                    <Row className="mb-3">
                        <u>
                            <i>Risk Pool Tiered Rate for {data.parentRateId ?? ''}</i>
                        </u>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.memberCountStart}</label>
                                <TooltipComponent content={`Min Member Count Start: ${maxMemberCount + 1}`}>
                                    <ControlledSyncfusionNumericTextBox
                                        name="memberCountStart"
                                        control={control}
                                        rules={{
                                            required: 'Member Count Start is required',
                                        }}
                                        boxAttrs={{
                                            showSpinButton: false,
                                            format: 'n0',
                                            min: 1,
                                            strictMode: true,
                                            decimals: 0,
                                            validateDecimalOnType: true,
                                        }}
                                        onChangeHandler={(arg) => {
                                            validateMemberCount('memberCountStart', getValues('memberCountStart'));
                                        }}
                                    />
                                </TooltipComponent>
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="memberCountStart"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.memberCountEnd}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="memberCountEnd"
                                    control={control}
                                    rules={{ required: 'Member Count End is required' }}
                                    boxAttrs={{
                                        showSpinButton: false,
                                        format: 'n0',
                                        min: (() => {
                                            const countStart = getValues('memberCountStart');
                                            if (typeof countStart === 'number') return countStart + 1;
                                            return 2;
                                        })(),
                                        strictMode: true,
                                        decimals: 0,
                                        validateDecimalOnType: true,
                                    }}
                                    onChangeHandler={() => {
                                        validateMemberCount('memberCountEnd', getValues('memberCountEnd'));
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="memberCountEnd"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.hospitalBudgetPercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="hospitalBudgetPercent"
                                    control={control}
                                    boxAttrs={DECIMAL_4}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.adminFeePercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="adminFeePercent"
                                    control={control}
                                    boxAttrs={DECIMAL_4}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.deficitSharePercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="deficitSharePercent"
                                    control={control}
                                    boxAttrs={DECIMAL_4}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.surplusSharePercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="surplusSharePercent"
                                    control={control}
                                    boxAttrs={DECIMAL_4}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.esrdPremiumPercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="esrdPremiumPercent"
                                    control={control}
                                    boxAttrs={{
                                        enabled: isESRDApplicable,
                                        showSpinButton: false,
                                        validateDecimalOnType: true,
                                        min: 0,
                                        strictMode: true,
                                        placeholder: '0.0000',
                                        format: '0.0000',
                                        decimals: 4,
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.deficitProrationPercent}</label>
                                <ControlledSyncfusionNumericTextBox
                                    name="deficitProrationPercent"
                                    control={control}
                                    boxAttrs={DECIMAL_4}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.effectiveStartDate}</label>
                                <ControlledSyncfusionDatePicker
                                    name="effectiveStartDate"
                                    control={control}
                                    isRetrievingDateAsString={true}
                                    rules={{ required: 'Effective Date is required' }}
                                    dpAttrs={{
                                        enabled: false,
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="effectiveStartDate"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className={styles.label}>{RiskPoolLabel.effectiveEndDate}</label>
                                <ControlledSyncfusionDatePicker
                                    name="effectiveEndDate"
                                    control={control}
                                    isRetrievingDateAsString={true}
                                    rules={{ required: 'End Date is required' }}
                                    dpAttrs={{
                                        enabled: false,
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="effectiveEndDate"
                                render={({ message }) => <p className="error-form">{message}</p>}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={3} className="mt-2">
                            <ControlledSyncfusionCheckBox
                                name="isESRDApplicable"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isESRDApplicable,
                                }}
                                onChangeHandler={(checked) => {
                                    setIsESRDApplicable(checked);
                                    if (!checked) setValue('esrdPremiumPercent', 0);
                                }}
                            />
                        </Col>
                        <Col md={2} className="mt-2">
                            <ControlledSyncfusionCheckBox
                                name="isCawCost"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isCawCost,
                                }}
                            />
                        </Col>
                        <Col md={2} className="mt-2">
                            <ControlledSyncfusionCheckBox
                                name="isHospitalistPmPm"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isHospitalistPmPm,
                                }}
                            />
                        </Col>
                        <Col md={2} className="mt-2">
                            <ControlledSyncfusionCheckBox
                                name="isJSAPmPm"
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isJSAPmPm,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-3">
                        {data?.id ? (
                            <Col>
                                <Row className="mb-2">
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created By</label>
                                            <input type="text" readOnly value={data?.createdById ?? ''} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated By</label>
                                            <input type="text" readOnly value={data?.lastUpdatedById ?? ''} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approved By</label>
                                            <input type="text" readOnly value={data?.lastApprovedById ?? ''} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    data?.createdAt ? formatISOLocalDateTime(data.createdAt) : undefined
                                                }
                                                value={data?.createdAt ? formatISOLocalDateTime(data.createdAt) : ''}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    data?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(data.lastUpdatedAt)
                                                        : undefined
                                                }
                                                value={
                                                    data?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(data.lastUpdatedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approval Date</label>
                                            <input
                                                type="text"
                                                readOnly
                                                title={
                                                    data?.lastApprovedAt
                                                        ? formatISOLocalDateTime(data.lastApprovedAt)
                                                        : undefined
                                                }
                                                value={
                                                    data?.lastApprovedAt
                                                        ? formatISOLocalDateTime(data.lastApprovedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ) : null}
                        <Col className="mt-3 d-flex justify-content-end">
                            <div className={styles.formButtonsGroup}>
                                <button
                                    type="button"
                                    className={`btn btn-secondary btn-flat ${styles.formButton}`}
                                    onClick={() => riskPoolRateService.cancel()}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={`btn btn-primary btn-flat ${styles.formButton}`}
                                    disabled={isFormClean || disabled}
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </DisabledWrapper>
        </div>
    );
};

export default RiskPoolTieredRateForm;
