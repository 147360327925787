import React from 'react';
import BaseComponent from 'common/BaseComponent';
import { memberDataTypes } from 'common/widgets';
import { exportPdfMembersService } from 'subjects/common/ExportMembersService';
import FiltersPanel from './FiltersPanel';
import GridTemplate from './GridTemplate';

export default class AdvancedSearchComponent extends BaseComponent{
    constructor(props) {
        super(props);
        this.toolbarOptions = props.toolbarOptions || this.getToolbarOptions();
        this.memberDataType = props.memberDataType || memberDataTypes.advancedSearch;
        this.gridTemplateInstance = React.createRef();
        this.rowDataBound = props.rowDataBound;
        this.filtersRef = React.createRef();
        this.selectedItemService = props.selectedItemService;
        this.setFilterData = props.setFilterData;
        this.emptyRecordMessage = props.emptyRecordMessage;
    }

    onSelected() {
        if (
            props.setMemberPdfExportList &&
            typeof this.props.setMemberPdfExportList === 'function'
        ) {
            props.setMemberPdfExportList(
                this.gridTemplateInstance.gridInstance
                    .getSelectedRecords()
                    .map((record) => record.memberId)
            );
        } else {
            exportPdfMembersService.select(
                this.gridTemplateInstance.gridInstance
                    .getSelectedRecords()
                    .map((record) => record.memberId)
            );
        }
    }

    componentDidMount() {
        if (this.props.initialFilter && !this.noSearchOnLoad) {
            this.filters = this.props.initialFilter;
            this.props.updateDataSource(
                this.props.initialFilter,
                this.gridTemplateInstance.gridInstance
            );
        }
    }

    componentDidUpdate() {
        if (this.props.deleteState === 'true') {
            this.filters = this.props.initialFilter;
            this.props.updateDataSource(
                this.props.initialFilter,
                this.gridTemplateInstance.gridInstance
            );
        }
    }

    onClickSearch = (filters) => {
        this.filters = filters;
        if (this.setFilterData) {
            this.setFilterData(filters);
        }
        this.props.updateDataSource(filters, this.gridTemplateInstance.gridInstance);
    };

    getToolbarOptions() {
        return ['ColumnChooser'];
    }

    toolbarClick = (args) => {
        if (args.item.text === 'Create') {
            const button = args.item.controlParent.activeEle;
            button.classList.add('loading', 'animatedBtn');
            return this.selectedItemService?.select({});
        }
    };

    stopSpinner = () => {
        this.filtersRef.current.stopSpinner();
    };

    render() {
        const HeaderContent = this.props.header;
        return (
            <div className="tabDiv">
                {typeof this.props.header !== 'undefined' && <HeaderContent />}

                <FiltersPanel
                    staticFilters={this.props.staticFilters}
                    dynamicFilters={this.props.dynamicFilters}
                    onClickSearch={this.onClickSearch}
                    onClearFilters={this.props.onClearFilters}
                    initialValues={this.props.initialFilter}
                    displaySpinner={this.props.displaySpinner}
                    ref={this.filtersRef}
                />

                <div
                    className="box box-solid box-default"
                    style={{ margin: '0px 10px 10px 10px', width: 'calc(100% - 20px)' }}
                >
                    <div style={{ width: '100%' }}>
                        <GridTemplate
                            uid="advance-search"
                            ref={(grid) => (this.gridTemplateInstance = grid)}
                            memberDataType={this.memberDataType}
                            sortSettings={this.props.sortSettings}
                            onRowSelected={
                                this.props.rowSelected || this.props.selectedItemService?.select
                            }
                            height={this.props.height ?? 450}
                            allowPaging={true}
                            allowGrouping={this.props.allowGrouping}
                            pageSizes={[25, 50, 100]}
                            columns={this.props.columns}
                            toolbarOptions={this.toolbarOptions}
                            toolbarClick={this.props.toolbarClick || this.toolbarClick}
                            getFilters={() => this.filters}
                            rowDataBound={this.rowDataBound}
                            query={this.props.query}
                            dataManager={this.props.dataManager}
                            excelFileName={this.props.excelFileName}
                            stopSpinner={this.stopSpinner}
                            selectedRows={(rows) => {
                                this.props.selectedRows && this.props.selectedRows(rows);
                            }}
                            autoFitColumns={this.props.autoFitColumns}
                            pageSettings={this.props.pageSettings}
                            queryCellInfo={this.props.queryCellInfo}
                            emptyRecordMessage={this.emptyRecordMessage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
