import { filterControlType } from 'common/widgets';
import { GridFilterDefinition } from 'models/Common/GridFilterDefinition';
export const textDateValidation =
    'Please ensure that the new record is created within a non-overlapping time range. However, there are no available time slots left in the effective date range. Please review and adjust the previous records before proceeding.';
export const IpaAdministrationSearchGridColumns = [
    {
        field: 'ipaCode',
        width: 100,
        headerText: 'IPA Code',
        type: 'text',
        allowSorting: true,
        autoFit: false,
        isPrimaryKey: false,
    },
    {
        field: 'ipaDesc',
        width: 100,
        headerText: 'IPA Name',
        type: 'text',
        allowSorting: true,
        autoFit: false,
    },
    {
        field: 'companyCode',
        width: 100,
        headerText: 'Company Code',
        type: 'text',
        autoFit: false,
        allowSorting: true,
    },
    {
        field: 'contractName',
        width: 100,
        headerText: 'Contract Name',
        type: 'text',
        autoFit: false,
        allowSorting: true,
    },
    {
        field: 'vendorId',
        width: 100,
        headerText: 'Vendor ID',
        type: 'text',
        allowSorting: true,
        autoFit: false,
    },
    {
        field: 'vendorNpi',
        width: 100,
        headerText: 'Vendor NPI',
        type: 'text',
        allowSorting: true,
        autoFit: false,
    },
    {
        field: 'vendorNm',
        width: 120,
        headerText: 'Vendor Name',
        type: 'text',
        visible: true,
    },
    {
        field: 'taxId',
        width: 100,
        headerText: 'Tax ID',
        type: 'text',
        autoFit: true,
        visible: true,
    },
    {
        field: 'termed',
        width: 150,
        headerText: 'Termed',
        type: 'text',
        visible: false,
    },
    {
        field: 'webSite',
        width: 150,
        headerText: 'Website',
        type: 'text',
        visible: false,
    },
    {
        field: 'state',
        width: 100,
        headerText: 'State',
        type: 'text',
        visible: false,
    },
    {
        field: 'city',
        width: 120,
        headerText: 'City',
        type: 'text',
        visible: false,
    },
    {
        field: 'zip',
        width: 80,
        headerText: 'Zip',
        type: 'text',
        visible: false,
    },
    {
        field: 'street',
        width: 120,
        headerText: 'Street',
        type: 'text',
        visible: false,
    },
    {
        field: 'street2',
        width: 120,
        headerText: 'Street 2',
        type: 'text',
        autoFit: false,
        visible: false,
    },
    {
        field: 'email',
        width: 100,
        headerText: 'Email',
        type: 'text',
        visible: false,
    },
    {
        field: 'fax',
        width: 120,
        headerText: 'Fax',
        type: 'text',
        visible: false,
    },
    {
        field: 'intlZip',
        width: 80,
        headerText: 'Intl Zip',
        type: 'text',
        visible: false,
    },
    {
        field: 'ip',
        width: 80,
        headerText: 'IP',
        type: 'text',
        visible: false,
    },
    {
        field: 'contractLevelId',
        width: 80,
        headerText: 'Contract LevelId',
        type: 'text',
        visible: false,
    },
    {
        field: 'ipaNameIdCard',
        width: 100,
        headerText: 'Ipa Nanme ID Card',
        type: 'text',
        visible: false,
    },
    {
        field: 'ipaPhone',
        width: 100,
        headerText: 'IPA Phone',
        type: 'text',
        visible: false,
    },
    {
        field: 'ipaPhone2',
        width: 100,
        headerText: 'IPA Phone 2',
        type: 'text',
        visible: false,
    },
    {
        field: 'ipaPhoneIdCard',
        width: 100,
        headerText: 'IPA Phone ID Card',
        type: 'text',
        visible: false,
    },
    {
        field: 'msoName',
        width: 100,
        headerText: 'MSO Name',
        type: 'text',
        allowSorting: true,
        autoFit: false,
    },
    {
        field: 'effDate',
        width: 200,
        headerText: 'Effective Date',
        type: 'text',
        visible: true,
    },
    {
        field: 'endDate',
        width: 200,
        headerText: 'End Date',
        type: 'text',
        visible: true,
    },
    {
        field: 'msoAddress1',
        width: 120,
        headerText: 'MSO Address 1',
        type: 'text',
        visible: false,
    },
    {
        field: 'msoAddress2',
        width: 120,
        headerText: 'MSO Address 2',
        type: 'text',
        visible: false,
    },
    {
        field: 'msoCity',
        width: 120,
        headerText: 'MSO City',
        type: 'text',
        visible: false,
    },
    {
        field: 'msoEmail',
        width: 120,
        headerText: 'MSO Email',
        type: 'text',
        visible: false,
    },
    {
        field: 'msoNotes',
        width: 120,
        headerText: 'MSO Notes',
        type: 'text',
        visible: false,
    },
    {
        field: 'msoPhone',
        width: 120,
        headerText: 'MSO Phone',
        type: 'text',
        visible: false,
    },
    {
        field: 'msoState',
        width: 120,
        headerText: 'MSO State',
        type: 'text',
        visible: false,
    },
    {
        field: 'notes',
        width: 120,
        headerText: 'Notes',
        type: 'text',
        visible: false,
    },
    {
        field: 'risk',
        width: 120,
        headerText: 'Contract Type',
        type: 'text',
        visible: true,
    },
    {
        field: 'type',
        width: 120,
        headerText: 'Type',
        type: 'text',
        visible: false,
    },
    {
        field: 'createdBy',
        width: 150,
        headerText: 'Created By',
        type: 'text',
        autoFit: true,
        visible: false,
    },
    {
        field: 'createdDt',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        autoFit: true,
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
    {
        field: 'updatedBy',
        width: 150,
        headerText: 'Updated By',
        type: 'text',
        autoFit: true,
        visible: false,
    },
    {
        field: 'updatedDt',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        autoFit: true,
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
];
export const IpaAdministrationSearchFilters: Array<GridFilterDefinition> = [
    {
        id: 'companyCode',
        label: 'Company Code',
        controlType: filterControlType.Selection,
        fields: {
            text: 'companyName',
            value: 'companyCode',
        },
        showClearButton: true,
        parameters: {
            placeHolder: 'Company Code',
        },
    },
    {
        id: 'ipaCode',
        label: 'IPA code starts w/',
        controlType: filterControlType.Selection,
        parameters: {
            placeHolder: 'IPA code',
        },
    },
    {
        id: 'msoName',
        label: 'MSO name starts w/',
        controlType: filterControlType.TextBox,
        parameters: {
            placeHolder: 'MSO name',
        },
    },
    {
        id: 'contractType',
        label: 'Contract Type',
        controlType: filterControlType.Selection,
        parameters: {
            placeHolder: 'Contract Type',
        },
    },
    {
        id: 'vendorId',
        label: 'Vendor ID',
        controlType: filterControlType.TextBox,
        parameters: {
            placeHolder: 'Vendor ID',
        },
    },
    {
        id: 'vendorNPI',
        label: 'Vendor NPI',
        controlType: filterControlType.TextBox,
        parameters: {
            placeHolder: 'Vendor NPI',
        },
    },
    {
        id: 'taxId',
        label: 'Tax ID',
        controlType: filterControlType.TextBox,
        parameters: {
            placeHolder: 'Tax ID',
        },
    },
    {
        id: 'ipaStatus',
        label: 'Status',
        controlType: filterControlType.Selection,
        fields: {
            text: 'text',
            value: 'value',
        },
        showClearButton: true,
        parameters: {
            placeHolder: 'Status',
        },
        defaultValue: '0',
    },
];
export const AdditionalContactGridColumns = [
    {
        field: 'department',
        width: 150,
        headerText: 'Department',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactTitle',
        width: 150,
        headerText: 'Primary Contact Title',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactName',
        width: 150,
        headerText: 'Primary Contact Name',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactPhone',
        width: 150,
        headerText: 'Primary Contact Phone',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactExtn',
        width: 150,
        headerText: 'Primary Contact Ext',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactStreet1',
        width: 150,
        headerText: 'Primary Contact Street 1',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactStreet2',
        width: 150,
        headerText: 'Primary Contact Street 2',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactState',
        width: 150,
        headerText: 'Primary Contact State',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'primaryContactCity',
        width: 150,
        headerText: 'Primary Contact City',
        type: 'text',
        visible: true,
    },
    {
        field: 'primaryContactZipCode',
        width: 150,
        headerText: 'Primary Contact Zip Code',
        type: 'text',
        visible: false,
    },
    {
        field: 'primaryContractEmail',
        width: 150,
        headerText: 'Primary Contact Email',
        type: 'text',
        visible: false,
    },
    {
        field: 'primaryContractFax',
        width: 150,
        headerText: 'Primary Contact Fax',
        type: 'text',
        visible: false,
    },
    {
        field: 'secondaryContactEmail',
        width: 150,
        headerText: 'Secondary Contact Email',
        type: 'text',
        visible: false,
    },
    {
        field: 'secondaryContactExtn',
        width: 150,
        headerText: 'Secondary Contact Extn',
        type: 'text',
        visible: false,
    },
    {
        field: 'secondaryContactFax',
        width: 150,
        headerText: 'Secondary Contact Fax',
        type: 'text',
        visible: false,
    },
    {
        field: 'secondaryContactName',
        width: 150,
        headerText: 'Secondary Contact Name',
        type: 'text',
        visible: false,
    },
    {
        field: 'secondaryContactPhone',
        width: 150,
        headerText: 'Secondary Contact Phone',
        type: 'text',
        visible: false,
    },
    {
        field: 'secondaryContactTitle',
        width: 150,
        headerText: 'Secondary Contact Title',
        type: 'text',
        visible: false,
    },
    {
        field: 'claimAddress',
        width: 150,
        headerText: 'Claim Address',
        type: 'text',
        visible: false,
    },
    {
        field: 'isClaimAddress',
        width: 150,
        headerText: 'Is Claim Address',
        type: 'text',
        visible: false,
    },
    {
        field: 'tradingPartnerId',
        width: 150,
        headerText: 'Trading Partner Id',
        type: 'text',
        visible: false,
    },
    {
        field: 'misDirectedPayerId',
        width: 150,
        headerText: 'Mis Directed Payer Id',
        type: 'text',
        visible: false,
    },
    {
        field: 'startDt',
        width: 150,
        headerText: 'Start Date',
        type: 'datetime',
        format: 'MM/dd/yyyy',
        autoFit: true,
    },
    {
        field: 'endDt',
        width: 150,
        headerText: 'End Date',
        type: 'datetime',
        format: 'MM/dd/yyyy',
        autoFit: true,
    },
    {
        field: 'ipaWeb_Id',
        width: 150,
        headerText: 'ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'createdBy',
        width: 150,
        headerText: 'Created By',
        type: 'text',
        visible: false,
    },
    {
        field: 'createdDt',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
    {
        field: 'updatedBy',
        width: 150,
        headerText: 'Updated By',
        type: 'text',
        visible: false,
    },
    {
        field: 'updatedDt',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
];
export const HospitalAffiliationsGridColumns = [
    {
        field: 'hospitalName',
        width: 150,
        headerText: 'Hospital Name',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'hospitalAddress',
        width: 150,
        headerText: 'Hospital Address',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'hospitalCity',
        width: 150,
        headerText: 'Hospital City',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'hospitalPhone',
        width: 150,
        headerText: 'Hospital Phone',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'hospitalState',
        width: 150,
        headerText: 'Hospital State',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'hospitalZipCode',
        width: 150,
        headerText: 'Hospital Zip Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'countyName',
        width: 150,
        headerText: 'County Name',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'licenseNum',
        width: 150,
        headerText: 'License Number',
        type: 'text',
        visible: true,
    },
    {
        field: 'contractStartDate',
        width: 150,
        headerText: 'Contract Start Date',
        type: 'text',
        visible: true,
    },
    {
        field: 'contractEndDate',
        width: 150,
        headerText: 'Contract End Date',
        type: 'text',
        visible: true,
    },
    {
        field: 'dateEffective',
        width: 150,
        headerText: 'Date Effective',
        type: 'text',
        visible: false,
    },
    {
        field: 'dateTerminated',
        width: 150,
        headerText: 'Date Terminated',
        type: 'text',
        visible: false,
    },
    {
        field: 'npi',
        width: 150,
        headerText: 'NPI',
        type: 'text',
        visible: true,
    },
    {
        field: 'numOfBed',
        width: 150,
        headerText: 'Number Of Beds',
        type: 'text',
        visible: true,
    },
    {
        field: 'website',
        width: 150,
        headerText: 'Website',
        type: 'text',
        visible: false,
    },
    {
        field: 'facilityServices',
        width: 150,
        headerText: 'Facility Services',
        type: 'text',
        visible: false,
    },
    {
        field: 'googleLatitude',
        width: 150,
        headerText: 'Google Latitude',
        type: 'text',
        visible: false,
    },
    {
        field: 'googleLongitude',
        width: 150,
        headerText: 'Google Longitude',
        type: 'text',
        visible: false,
    },
    {
        field: 'hospital_Id',
        width: 150,
        headerText: 'Hospital ID',
        type: 'text',
        visible: false,
    },
    {
        field: 'photoFileTitle',
        width: 150,
        headerText: 'Photo File Title',
        type: 'text',
        visible: false,
    },
    {
        field: 'providerId',
        width: 150,
        headerText: 'Provider Id',
        type: 'text',
        visible: false,
    },
    {
        field: 'companyCode',
        width: 150,
        headerText: 'Company Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'createdBy',
        width: 150,
        headerText: 'Created By',
        type: 'text',
        autoFit: true,
        visible: false,
    },
    {
        field: 'created',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        autoFit: true,
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
    {
        field: 'updatedBy',
        width: 150,
        headerText: 'Updated By',
        type: 'text',
        autoFit: true,
        visible: false,
    },
    {
        field: 'updated',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        autoFit: true,
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
];
export const OutOfAreaGridColumns = [
    {
        field: 'ooaDescription',
        width: 150,
        headerText: 'OOA Description',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'distance',
        width: 150,
        headerText: 'Distance',
        type: 'text',
    },
    {
        field: 'distanceKind',
        width: 150,
        headerText: 'Distance Kind',
        type: 'text',
    },
    {
        field: 'addressType',
        width: 150,
        headerText: 'Address Type',
        type: 'text',
    },
    {
        field: 'countyName',
        width: 150,
        headerText: 'County Name',
        type: 'text',
    },
    {
        field: 'countyStatus',
        width: 150,
        headerText: 'County Status',
        type: 'text',
    },
    {
        field: 'comments',
        width: 150,
        headerText: 'Comments',
        type: 'text',
    },
    {
        field: 'exceptionIpaCode',
        width: 150,
        headerText: 'Exception Ipa Code',
        type: 'text',
        visible: false,
    },
    {
        field: 'exceptionProviderId',
        width: 150,
        headerText: 'Exception Provider Id',
        type: 'text',
        visible: false,
    },
    {
        field: 'withinAreaShortReason',
        width: 150,
        headerText: 'Within Area Reason',
        type: 'text',
        visible: false,
    },
    {
        field: 'outOfAreaShortReason',
        width: 150,
        headerText: 'Out of Area Reason',
        type: 'text',
        visible: false,
    },
    {
        field: 'startDate',
        width: 150,
        headerText: 'Start Date',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'endDate',
        width: 150,
        headerText: 'End Date',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'id',
        width: 150,
        headerText: 'ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'ipaId',
        width: 150,
        headerText: 'IPA ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'ipaCode',
        width: 150,
        headerText: 'IPA Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'createdBy',
        width: 150,
        headerText: 'Created By',
        type: 'text',
        autoFit: true,
        visible: false,
    },
    {
        field: 'createdDt',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        autoFit: true,
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
    {
        field: 'updatedBy',
        width: 150,
        headerText: 'Updated By',
        type: 'text',
        autoFit: true,
        visible: false,
    },
    {
        field: 'updatedDt',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        autoFit: true,
        visible: false,
        format: 'MM/dd/yyyy hh:mm:ss',
    },
];
export const IpaPlanGridColumns = [
    {
        field: 'contractCode',
        width: 150,
        headerText: 'Contract Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'pbp',
        width: 150,
        headerText: 'PBP',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'providerId',
        width: 150,
        headerText: 'Provider Id',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'startDate',
        width: 150,
        headerText: 'Start Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy',
    },
    {
        field: 'endDate',
        width: 150,
        headerText: 'End Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy',
    },
    {
        field: 'id',
        width: 150,
        headerText: 'ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'ipaCode',
        width: 150,
        headerText: 'IPA Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'companyCode',
        width: 250,
        headerText: 'Company Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'createdBy',
        width: 150,
        headerText: 'Created By',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'created',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy hh:mm:ss a',
    },
    {
        field: 'updatedBy',
        width: 150,
        headerText: 'Updated By',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'updated',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy hh:mm:ss a',
    },
    {
        field: 'pbpId',
        width: 150,
        headerText: 'PBP Id',
        type: 'text',
        visible: false,
    },
];
export const IpaCountyMapGridColumns = [
    {
        field: 'countyName',
        width: 250,
        headerText: '',
        type: 'text',
        autoFit: true,
        showInColumnChooser: false,
        template: (args) => {
            if (!args.countyName) {
                if (args.combinedCounty.length !== 0) {
                    return <b>County Name</b>;
                } else {
                    return '';
                }
            } else {
                return args.countyName;
            }
        },
    },
    {
        field: 'countyType',
        width: 250,
        headerText: '',
        type: 'text',
        autoFit: true,
        showInColumnChooser: false,
        template: (args) => {
            if (!args.countyType) {
                if (args.combinedCounty.length !== 0) {
                    return <b>County Type</b>;
                } else {
                    return '';
                }
            } else {
                const countyType = args.countyType.split(' ')[0];
                return countyType.charAt(0).toUpperCase() + countyType.slice(1);
            }
        },
    },
    {
        field: 'effectiveBeginDate',
        width: 150,
        headerText: 'Start Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy',
        showInColumnChooser: false,
    },
    {
        field: 'effectiveEndDate',
        width: 150,
        headerText: 'End Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy',
        showInColumnChooser: false,
    },
    {
        field: 'createdByName',
        width: 150,
        headerText: 'Created By',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'createdAt',
        width: 220,
        headerText: 'Created Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy hh:mm:ss a',
    },
    {
        field: 'lastUpdatedByName',
        width: 150,
        headerText: 'Updated By',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'lastUpdatedAt',
        width: 220,
        headerText: 'Updated Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy hh:mm:ss a',
    },
    {
        field: 'pbpId',
        width: 150,
        headerText: 'PBP Id',
        type: 'text',
        visible: false,
    },
];
export const CapRatesColumns = [
    {
        field: 'sequence',
        width: 300,
        headerText: 'Sequence',
        type: 'text',
    },
    {
        field: 'capSubRateType',
        width: 150,
        headerText: 'SubRate Type',
        type: 'text',
    },
    {
        field: 'id',
        width: 75,
        headerText: 'ID',
        type: 'text',
    },
    {
        field: 'risk',
        width: 75,
        headerText: 'Contract Type',
        type: 'text',
    },
    {
        field: 'active',
        width: 75,
        headerText: 'Active',
        type: 'text',
    },
    {
        field: 'effDate',
        width: 150,
        headerText: 'Effective Date',
        type: 'text',
    },
    {
        field: 'endDate',
        width: 150,
        headerText: 'End Date',
        type: 'text',
    },
    {
        field: 'mediMediPCT',
        width: 140,
        headerText: 'Medi Medi PCT',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'premiumPCT',
        width: 140,
        headerText: 'Premium PCT',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'createdBy',
        width: 100,
        headerText: 'Created By',
        type: 'text',
    },
    {
        field: 'createDate',
        width: 200,
        headerText: 'Created',
        type: 'datetime',
        format: 'MM/dd/yyyy hh:mm:ss',
    },
    {
        field: 'updatedBy',
        width: 100,
        headerText: 'Updated By',
        type: 'text',
    },
    {
        field: 'updateDt',
        width: 200,
        headerText: 'Updated',
        type: 'datetime',
        format: 'MM/dd/yyyy hh:mm:ss',
    },
];
export const NetworkMappingGridColumns = [
    {
        field: 'ipaCode',
        width: 150,
        headerText: 'IPA Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'networkId',
        width: 150,
        headerText: 'Network ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'networkPrefix',
        width: 150,
        headerText: 'Network Prefix',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'agreementId',
        width: 150,
        headerText: 'Agreement ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'contractCode',
        width: 150,
        headerText: 'Contract Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'tin',
        width: 150,
        headerText: 'TIN',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'class',
        width: 150,
        headerText: 'Class',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'isPcp',
        width: 150,
        headerText: 'Is PCP',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'specialty',
        width: 150,
        headerText: 'Specialty',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'ipaNetworkId',
        width: 150,
        headerText: 'IPA Network ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'ipaNetworkPrefix',
        width: 170,
        headerText: 'IPA Network Prefix',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'dofrAgreementId',
        width: 170,
        headerText: 'DOFR Agreement ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'effectiveStartDate',
        width: 150,
        headerText: 'Start Date',
        type: 'datetime',
        format: 'MM/dd/yyyy',
        autoFit: true,
    },
    {
        field: 'effectiveEndDate',
        width: 150,
        headerText: 'End Date',
        type: 'datetime',
        format: 'MM/dd/yyyy',
        autoFit: true,
    },
    {
        field: 'createdByName',
        width: 150,
        headerText: 'Created By',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'createdAt',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        format: 'MM/dd/yyyy',
        autoFit: true,
    },
    {
        field: 'lastUpdatedByName',
        width: 150,
        headerText: 'Updated By',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'lastUpdatedAt',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        format: 'MM/dd/yyyy hh:mm:ss',
        autoFit: true,
    },
];
export const ESRDTypes = [
    {
        text: 'ESRD_HYBRID',
        value: 'ESRD_HYBRID',
    },
    {
        text: 'ESRD_ZERO',
        value: 'ESRD_ZERO',
    },
    {
        text: 'ESRD_POP',
        value: 'ESRD_POP',
    },
    {
        text: 'ESRD_VIRTUAL_HYBRID',
        value: 'ESRD_VIRTUAL_HYBRID',
    },
];
export const toolbarOptions = [
    {
        id: 'create-new',
        text: 'Create New',
        tooltipText: 'Create New',
        prefixIcon: 'e-plus',
        align: 'Right',
    },
    'ColumnChooser',
    {
        text: 'Export All to Excel',
        tooltipText: 'Exports the current search results to Excel',
        prefixIcon: 'e-excelexport',
        id: 'currentgrid_exportexcel',
        align: 'Right',
    },
];
export const tabsToolbarOptions = [
    {
        id: 'create',
        text: 'Create',
        tooltipText: 'Create',
        prefixIcon: 'e-plus',
        align: 'Left',
    },
    'ColumnChooser',
    {
        text: 'Refresh',
        tooltipText: 'Refresh',
        prefixIcon: 'e-refresh',
        id: 'refresh',
    },
];
export const defaultValuesCapRates = {
    premiumPCT: '0.0000',
    mediMediPCT: '0.0000',
    pmPmAmt: '0.00',
    pmPmAmtMm: '0.00',
    riskPHosp: '0.0000',
    floorMC: '000.00',
    isIndividualFloorLevel: false,
    hospSurplusSplit: '0.0000',
    profSurplusSplit: '0.0000',
    adminFee: '0.0000',
    riskPprof: '0.0000',
    floorMM: '000.00',
    sequence: 0,
    isAverageFloorLevel: false,
    isAverageFloorPCP: false,
    isAverageFloorIPA: false,
    isAverageFloorBenefit: false,
    isEsrdExclusion: false,
    isVirtualExclusion: false,
    isIpaGroupLevel: false,
    ipaPhone: '',
    ipaPhone2: '',
    spec: '',
    spec2: '',
    type: 'IPA',
    isSequestrationtax: false,
    capRevCode: '0',
    isAcaTax: false,
    isMemberPremiumApplicable: false,
    mpCapRevCode: '0',
    acaTaxDivisor: '1',
    isEsrdApplicable: false,
    esrdType: null,
    esrdPmPm: '0000.00',
    esrdPmPmMm: '0000.00',
    esrdPop: '0.0000',
    esrdPopMm: '0.0000',
    childIpas: '',
    isVirtualApplicable: false,
    virtualPmPm: '0000.00',
    virtualPmPmMm: '0000.00',
    isPreferredProviderSearch: false,
    isRestrictedPcpSearch: false,
    isSpecialRateApplicable: false,
    productCode: '',
    isValueBasedFlag: false,
    valueBasedPmPm: '0000.00',
    valueBasedPmPmMm: '0000.00',
    formula: '',
    effDate: new Date(),
    endDate: new Date(),
    ipaGroup: '',
    notes: '',
};
export const defaultValuesCapTieredRates = {
    premiumPCT: '0.0000',
    mediMediPCT: '0.0000',
    floorMC: '0.0000',
    pmPmAmt: '0.0000',
    pmPmAmtMm: '0.0000',
    riskPHosp: '0.0000',
    hospSurplusSplit: '0.0000',
    profSurplusSplit: '0.0000',
    floorMM: '0.00',
    adminFee: '0.0000',
    riskPprof: '0.0000',
    esrdPmPm: '0.00',
    esrdPmPmMm: '0.00',
    deductions: '0.00',
    valueBasedPmPm: '0000.00',
    valueBasedPmPmMm: '0000.00',
};

export const defaultValuesCapSpecialRates = {
    premiumPCT: '0.0000',
    mediMediPCT: '0.0000',
    pmPmAmt: '0000.00',
    esrdPmPm: '0000.00',
    esrdPmPmMm: '0000.00',
    esrdPop: '0.0000',
    esrdPopMm: '0.0000',
    virtualPmPm: '0000.00',
    virtualPmPmMm: '0000.00',
    valueBasedPmPm: '0000.00',
    valueBasedPmPmMm: '0000.00',
};

export const riskTypesValues = [
    { text: 'S (S)hared Risk', value: 'S' },
    { text: 'R Full (R)isk', value: 'R' },
    { text: 'F (F)ee For Service', value: 'F' },
    { text: 'G (G)lobal', value: 'G' },
    { text: 'C (C)CG', value: 'C' },
];

export const scbPremiumComponentValues = [
    { text: 'Total_PartA', value: 'Total_PartA' },
    { text: 'Total_PartB', value: 'Total_PartB' },
    { text: 'Rebate_A', value: 'Rebate_A' },
    { text: 'Rebate_B', value: 'Rebate_B' },
    { text: 'Rebate_Sup_A', value: 'Rebate_Sup_A' },
    { text: 'Rebate_Sup_B', value: 'Rebate_Sup_B' },
    { text: 'RebateD_Sup_A', value: 'RebateD_Sup_A' },
    { text: 'RebateD_Sup_B', value: 'RebateD_Sup_B' },
    { text: 'Rebate_PartD', value: 'Rebate_PartD' },
    { text: '+', value: '+' },
    { text: '-', value: '-' },
    { text: 'SCB_FUNCTION_OUTCOME', value: 'SCB_FUNCTION_OUTCOME' },
    { text: '(', value: '(' },
    { text: ')', value: ')' },
];
