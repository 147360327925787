import { Observable, Subject, map, filter, BehaviorSubject } from 'rxjs';
import { ajaxGet, ajaxGetType, ajaxPost } from 'common/ajax';
import { getAvaDataManager, getODataV4DataManager } from 'common/AVADataManager';
import { Stat } from 'components/CRM/AgentProductivity/AgentProductivityStats';
import CampaignRequest from 'interfaces/CRM/Requests/CampaignRequest';
import { CreateInteractionRequest } from 'interfaces/CRM/Requests/CreateInteractionRequest';
import { CreateWorkflowRequest } from 'interfaces/CRM/Requests/CreateWorkflowRequest';
import { CreateServiceFlowRequest, UpdateServiceFlowRequest } from 'interfaces/CRM/Requests/ServiceFlowRequests';
import StickyNoteRequest from 'interfaces/CRM/Requests/StickyNoteRequest';
import SwitchIsCaseClosedRequest from 'interfaces/CRM/Requests/SwitchIsCaseClosedRequest';
import { UpdateCrmBenefitInfoRequest } from 'interfaces/CRM/Requests/UpdateCrmBenefitInfoRequest';
import { UpdateInteractionRequest } from 'interfaces/CRM/Requests/UpdateInteractionRequest';
import { UpdateWorkflowRequest } from 'interfaces/CRM/Requests/UpdateWorkflowRequest';
import { CreateWorkflowTaskRequest, UpdateWorkflowTaskRequest } from 'interfaces/CRM/Requests/WorkflowTaskRequest';
import { GetMemberWalletsResponse } from 'interfaces/CRM/Responses/GetMemberWalletsResponse';
import { SupplementalBenefitValidationError } from 'interfaces/CRM/Responses/SupplementalBenefitValidationError';
import { ServiceFlow } from 'interfaces/CRM/ServiceFlow';
import { getODataArray, getODataObject, ODataArrayResponse } from './ODataAjaxUtil';
import Auth from '../../Auth';
import ComplaintTagInfo from '../../interfaces/CRM/Tags/ComplaintTagInfo';
import { BasicMemberProperties } from '../../interfaces/Member';
import MemberTag from '../../interfaces/MemberTag';
import { Case } from 'interfaces/CRM/Case';
import { WorkflowTask } from 'interfaces/CRM/WorkflowTask';

/*
 * @deprecated use ODataAjaxUtil
 */
const ajaxGetResponse = <TResponse = any>(url) =>
    ajaxGet<TResponse>(url).pipe(map((ajaxResponse) => ajaxResponse.response));

const productivityStatExecuted = new Subject<Stat>();
const tabsSubject = new Subject();
const memberCasesSubject = new BehaviorSubject(null);
const defaultCrmMemberTimeSubject: any = {};
const crmMemberTimelineSubject = new BehaviorSubject(defaultCrmMemberTimeSubject);

const myQueueService = {
    exportExcelUrls: {
        tasks: `/odata/crm/myqueue/default.csvexportworkflowtasks`,
    },
    subjects: {
        refreshOutbound: new Subject(),
        refreshCases: new Subject(),
        refreshInteractions: new Subject(),
        refreshTasks: new Subject(),
        refreshWorkflows: new Subject(),
    },
    dataManagers: {
        prescribers: () => getODataV4DataManager('odata/crm/myqueue/default.getprescribers'),
        pharmacies: () => getODataV4DataManager('odata/crm/myqueue/default.getpharmacies'),
        campaigns: () => getODataV4DataManager('odata/crm/myqueue/default.getcampaigns'),
        campaignEligibleMembers: () => getODataV4DataManager(`/odata/crm/myqueue/default.getcampaigneligiblemembers`),
        caseOwners: () => getODataV4DataManager(`/odata/crm/myqueue/default.GetCaseOwners?$OrderBy=Text`),
        caseModifiedBy: () => getODataV4DataManager(`/odata/crm/myqueue/default.GetCaseModifiedBy`),
        cases: () => getODataV4DataManager('odata/crm/myqueue/default.getcases'),
        caseStatuses: () => getODataV4DataManager(`/odata/crm/myqueue/default.getcasestatuses`),
        formTemplates: () => getODataV4DataManager('odata/crm/myqueue/default.getformtemplates'),
        interactions: () => getODataV4DataManager('odata/crm/myqueue/default.getinteractions'),
        interactionCallDirections: () =>
            getODataV4DataManager('odata/crm/myqueue/default.getInteractionCallDirections'),
        interactionCallOutcomes: () => getODataV4DataManager('odata/crm/myqueue/default.getInteractionCallOutcomes'),
        interactionCreatedBy: () => getODataV4DataManager('odata/crm/myqueue/default.getInteractionCreatedBy'),
        interactionTypes: () => getODataV4DataManager('odata/crm/myqueue/default.getInteractionTypes'),
        languages: () => getODataV4DataManager(`/odata/crm/myqueue/default.getlanguages`),
        ezMemberLanguages: () => getODataV4DataManager(`/odata/crm/myqueue/default.getezmemberlanguages`),
        medicalGroups: () => getODataV4DataManager(`/odata/sessionorganizations?OrgType=IPA`),
        memberMedication: (memberId: string) =>
            getAvaDataManager(`/odata/crm/myqueue/default.getMedications?memberid=${memberId}`),
        memberPortalMessageCategories: () =>
            getODataV4DataManager(`odata/crm/myqueue/default.getMemberPortalMessageCategories`),
        outboundSurveys: () => getODataV4DataManager(`/odata/crm/myqueue/default.getOutboundSurveys`),
        outboundSurveyCreatedBy: () => getODataV4DataManager('odata/crm/myqueue/default.getOutboundSurveyCreatedBy'),
        queues: () => getODataV4DataManager('odata/crm/myqueue/default.getqueues'),
        serviceFlows: () => getODataV4DataManager('odata/crm/myqueue/default.getserviceflows'),
        workflows: () => getODataV4DataManager('odata/crm/myqueue/default.getworkflows'),
        workflowCreatedBy: () => getODataV4DataManager('odata/crm/myqueue/default.getworkflowcreatedby'),
        workflowModifiedBy: () => getODataV4DataManager('odata/crm/myqueue/default.getworkflowmodifiedby'),
        workflowClosedBy: () => getODataV4DataManager('odata/crm/myqueue/default.getworkflowclosedby'),
        workflowStatuses: () => getODataV4DataManager('odata/crm/myqueue/default.getWorkflowStatuses'),
        workflowReasons: () => getODataV4DataManager('odata/crm/myqueue/default.getWorkflowReasons'),
        workflowTypes: () => getODataV4DataManager('odata/crm/myqueue/default.getworkflowtypes'),
        workflowTasks: () => getODataV4DataManager('odata/crm/myqueue/default.getworkflowtasks'),
        workflowTaskClosedBy: () => getODataV4DataManager('odata/crm/myqueue/default.getTaskClosedBy'),
        workflowTaskCreatedBy: () => getODataV4DataManager('odata/crm/myqueue/default.getTaskCreatedBy'),
        workflowTaskAssignedTo: () => getODataV4DataManager('odata/crm/myqueue/default.getWorkflowTaskAssignedTo'),
        workflowTaskStatuses: () => getODataV4DataManager('odata/crm/myqueue/default.getWorkflowTaskStatuses'),
        workflowTaskTypes: () => getODataV4DataManager('odata/crm/myqueue/default.getTaskTypes'),
        activeCounties: () => getODataV4DataManager('odata/crm/myqueue/default.getActiveCountiesOptions'),
    },
    urls: {
        GetActiveServiceFlowTaskTypes: '/odata/crm/myqueue/default.GetActiveServiceFlowTaskTypes',
        GetFormTemplates: '/odata/crm/myqueue/default.getFormTemplates',
    },
    getIPAAdditionalContacts$: (ipaCode: string) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetIPAAdditionalContacts(ipacode='${ipaCode}')`),
    // campaign
    createCampaign$: (request: CampaignRequest) => ajaxPost('/odata/crm/myqueue/default.createCampaign', request),
    updateCampaign$: (request: CampaignRequest) => ajaxPost('/odata/crm/myqueue/default.updateCampaign', request),
    deleteCampaign$: (id) =>
        ajaxPost('/odata/crm/myqueue/default.deleteCampaign', {
            id: id,
        }),
    switchIsActiveCampaign$: (id) => ajaxPost('/odata/crm/myqueue/default.SwitchIsActiveCampaign', { id: id }),

    getCampaigns$: () => ajaxGetResponse('odata/crm/myqueue/default.getcampaigns'),
    getCampaignById$: (campaignId) =>
        ajaxGetResponse(`odata/crm/myqueue/default.getcampaigns?$filter=(id eq '${campaignId}')`).pipe(
            map((response) => response.value),
            filter((results) => results && results.length),
            map((results) => results[0]),
        ),

    getMemberCountOfCampaignById$: (campaignId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetMemberCountOfCampaignById(campaignId='${campaignId}')`),

    // form template
    createFormTemplate$: (name, fields) =>
        ajaxPost(`/odata/crm/myqueue/default.createFormTemplate`, {
            name: name,
            fields: fields,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),
    updateFormTemplate$: (id, name, fields) =>
        ajaxPost(`/odata/crm/myqueue/default.updateFormTemplate`, {
            id: id,
            name: name,
            fields: fields,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),
    deleteFormTemplate$: (id) =>
        ajaxPost(`/odata/crm/myqueue/default.deleteFormTemplate`, { id: id }).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    duplicateFormTemplate$: (id) =>
        ajaxPost(`/odata/crm/myqueue/default.duplicateFormTemplate`, { id: id }).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),

    getFormTemplates$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getFormTemplates`),
    getFormTemplateById$: (formTemplateId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getformtemplates?$filter=(id eq '${formTemplateId}')`).pipe(
            map((response) => response.value),
            filter((results) => results && results.length),
            map((results) => results[0]),
        ),

    getFormTemplateFieldTypes$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getFormTemplateFieldTypes`),

    // member
    searchMember$: (searchTerm) => ajaxGetResponse(`/odata/crm/myqueue/default.searchmembers?searchterm=${searchTerm}`),

    // OData member
    searchODataMember$: (searchTerm) =>
        getODataV4DataManager(`/odata/crm/myqueue/default.searchmembers?searchterm=${searchTerm}`),
    // returns active member profile
    memberProfile$: (memberId) => ajaxGetResponse(`/odata/crm/myqueue/default.getmember(memberId='${memberId}')`),
    // returns list for member for all statuses (termed, active, future confirmed)
    getMemberAllStatuses$: (memberId) =>
        ajaxGet(`/odata/crm/myqueue/default.getmemberallstatuses(memberId='${memberId}')`),
    memberTagDetails$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getmembertagdetails(memberId='${memberId}')`),
    getJsaAppointmentTagDetails$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getJsaAppointmentTagDetails(memberId='${memberId}')`),
    getAppealGrievanceCtmTagDetails$: (memberId): Observable<ComplaintTagInfo[]> =>
        getODataArray(`/odata/crm/myqueue/default.getAppealGrievanceCtmTagDetails(memberId='${memberId}')`),
    getDmeProcedureCategories$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getDmeProcedureCategories(memberId='${memberId}')`),
    getClinicalAlertRegistries$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getClinicalAlertRegistries(memberId='${memberId}')`),
    getCtm$: (memberId) => ajaxGetResponse(`/odata/crm/myqueue/default.getCtm(memberId='${memberId}')`),

    getPotentialHousehold$: (memberIds): Observable<BasicMemberProperties[]> =>
        getODataArray(`/odata/crm/myqueue/default.getpotentialhousehold(memberIds='${memberIds}')`),

    memberNextBestActions$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetNextBestActions(memberId='${memberId}')`).pipe(
            map((odataResponse) => odataResponse.value),
        ),

    memberSupplementalBenefits$: (memberId) =>
        ajaxGetResponse(`/odata/supplementalbenefits?$filter=memberId eq '${memberId}'`),

    searchMembersByPhoneNumber$: (phone) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.SearchMembersByPhoneNumber(phoneNumber='${phone}')`),

    searchMembersForInbound$: (name, phoneNumber, memberIds, birthDate) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.SearchMembersForInbound(` +
                `name=${getStringProperty(name)}, ` +
                `phoneNumber=${getStringProperty(phoneNumber, true)}, ` +
                `memberIds=${getArrayToStringProperty(memberIds)}, ` +
                `birthDate=${getDateProperty(birthDate)})`,
        ),

    getMemberCommunicationPreferences$: (memberId, companyCode) =>
        ajaxGet(
            `/odata/crm/myqueue/default.getmembercommunicationpreferences(memberId='${memberId}',companyCode='${companyCode}')`,
        ).pipe(map((ajaxResponse) => ajaxResponse.response)),

    updateMemberCommunicationPreferences$: (preferences) =>
        ajaxPost(`/odata/crm/myqueue/default.updatemembercommunicationpreferences`, preferences).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),

    getMemberClaims$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetMemberClaims(memberId='${memberId}')`),

    getMyOpenReminders$: () => ajaxGetResponse('/odata/crm/myqueue/default.GetMyOpenReminders'),
    getMemberCard$: (memberId, companyId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetMemberCard(memberId='${memberId}',companyId='${companyId}')`),

    getMemberEligibilityVerification$: (memberId, healthPlanId, pbpId, planYear, userTimezoneOffset) =>
        ajaxGetType(
            `/odata/crm/myqueue/default.GetMemberEligibilityVerificationDocument?memberId=${memberId}&Hcode=${healthPlanId}&pbpid=${pbpId}&planyear=${planYear}&userTimezoneOffset=${userTimezoneOffset}`,
            'blob',
        ),

    getPoaMembers$: (memberId) => ajaxGetResponse(`/odata/crm/myqueue/default.GetPoaMembers(memberId='${memberId}')`),

    getMembershipDocByFilePath$: (filePath) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetMembershipDocByFilePath?filePath=${filePath}`),

    // interaction
    createInteraction$: (body: CreateInteractionRequest) =>
        ajaxPost('/odata/crm/myqueue/default.createinteraction', body).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    updateInteraction$: (body: UpdateInteractionRequest) =>
        ajaxPost(`/odata/crm/myqueue/default.UpdateInteraction`, body).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),

    getInteractionsByMemberId$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getinteractions?$filter=memberId eq '${memberId}'&$skip=0&$top=50`),
    getInteractionsByCaseId$: (caseId) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getinteractions?$filter=CaseIds/any(x: x eq '${caseId}')&$orderby=CreatedDateTime desc&$skip=0&$top=50`,
        ),

    markMemberPortalMessagesRead$: (interactionId, memberId) =>
        ajaxPost(`/odata/crm/myqueue/default.markMemberPortalMessagesRead`, { interactionId, memberId }).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    getFileContentForInteraction$: (fileId) =>
        ajaxGet(`/odata/crm/myqueue/default.GetFileContentForInteraction(fileId=${fileId})`).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    getOdataArrayAsync: async <tResult>(endpoint: string, setter: (value: tResult[]) => void, onError: () => void) => {
        const headers = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
                'Content-Type': 'application/json',
            },
        };
        return await fetch(`${endpoint}`, headers)
            .then((response) => response?.json())
            .then((response) => {
                if (!response) {
                    onError();
                    return null;
                }
                const data = response as ODataArrayResponse<tResult>;
                return data.value || null;
            })
            .then((result) => (result ? setter(result) : onError()))
            .catch((_) => onError());
    },
    getPromiseAsync: async <tResult>(endpoint: string, onError: () => void): Promise<tResult | null> => {
        const headers = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
                'Content-Type': 'application/json',
            },
        };
        return await fetch(`${endpoint}`, headers)
            .then((response) => response?.json())
            .then((response) => {
                if (!response) {
                    onError();
                    return null;
                }
                return response;
            })
            .catch((_) => onError());
    },
    postAsync: async <tResult>(
        endpoint: string,
        params: any,
        handler: (response: tResult) => void,
        onError: () => void,
    ) => {
        const headers = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        };
        await fetch(`${endpoint}`, headers)
            .then((response) => {
                return response?.json();
            })
            .then((response) => {
                if (!response) {
                    onError();
                }
                handler(response);
            })
            .catch((_) => onError());
    },
    postPromiseAsync: async <tResult>(endpoint: string, params: any, onError: () => void): Promise<tResult | null> => {
        const headers = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        };
        return await fetch(`${endpoint}`, headers)
            .then((response) => response?.json())
            .then((response) => {
                if (!response) {
                    onError();
                    return null;
                }
                return response;
            })
            .catch((_) => onError());
    },
    getInteractionCallDirections$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getInteractionCallDirections`),
    getInteractionCallOutcomes$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getInteractionCallOutcomes`),
    getInteractionTypes$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getInteractionTypes`),
    upsertCallNotes$: (talkdeskId: string, userId: string, notes: string) =>
        ajaxPost(`/odata/crm/myqueue/default.upsertCallNotes`, { talkdeskId, notes, agentName: userId }).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),

    // outbound survey
    getOutboundSurveysByCaseId$: (caseId) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getoutboundsurveys?$filter=CaseId eq '${caseId}'&$orderby=CreatedDateTime desc&$skip=0&$top=50`,
        ),
    createOutboundSurvey$: ({ caseId, campaignId, memberId, formData }) =>
        ajaxPost(`/odata/crm/myqueue/default.CreateOutboundSurvey`, {
            caseId,
            campaignId,
            memberId,
            formData,
        }),

    // case
    createCase$: ({
        memberId,
        campaignId,
        callerType,
        status,
        caseOrigin,
        notes,
        notesSource,
        lastCallDate,
        lastCallStatus,
        callDirection,
        reason,
    }) =>
        ajaxPost(`/odata/crm/myqueue/default.CreateCase`, {
            memberId,
            campaignId,
            callerType,
            status,
            caseOrigin,
            notes,
            notesSource,
            lastCallDate,
            lastCallStatus,
            callDirection,
            reason,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),
    switchIsCaseClosed$: (request: SwitchIsCaseClosedRequest) =>
        ajaxPost(`/odata/crm/myqueue/default.SwitchIsCaseClosed`, request).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    reassignCase$: (caseId, assignTo, assignToEmail) =>
        ajaxPost(`/odata/crm/myqueue/default.ReassignCase`, {
            caseId: caseId,
            assignTo: assignTo,
            assignToEmail: assignToEmail,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),
    addNoteToCase$: (caseId, notes) =>
        ajaxPost(`/odata/crm/myqueue/default.AddNoteToCase`, { caseId: caseId, notes: notes }).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    addActionTakenToCase$: (caseId, actionTaken) =>
        ajaxPost(`/odata/crm/myqueue/default.AddActionTakenToCase`, {
            caseId: caseId,
            actionTaken: actionTaken,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),

    getAllMemberCases$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getallmembercases(memberId='${memberId}')`),

    getFailedFirstCallResolutions$: (body) =>
        ajaxPost(`/odata/crm/myqueue/default.GetFailedFirstCallResolutions`, body),
    excelExportFailedFirstCallResolutions$: (body) =>
        ajaxPost(`/odata/crm/myqueue/default.ExcelExportFailedFirstCallResolutions`, body, 'blob'),

    getCaseByCaseId$: (caseId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getCases?$filter=id eq '${caseId}'&$skip=0&$top=50`).pipe(
            map((response) => response.value),
            filter((results) => results && results.length),
            map((results) => results[0]),
        ),

    getCaseOwners$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getCaseOwners`),

    // this is used for memberverticaltimeline
    updateMemberCases: (value) => memberCasesSubject.next(value),
    memberCases$: memberCasesSubject.asObservable(),
    tabs$: tabsSubject.asObservable(),
    tabsNext: (tab) => tabsSubject.next(tab),

    productivityStat: (stat) => productivityStatExecuted.next(stat),
    onProductivityStatExecute: productivityStatExecuted.asObservable(),

    // workflow
    createWorkflow$: (request: CreateWorkflowRequest) =>
        ajaxPost(`/odata/crm/myqueue/default.CreateWorkflow`, request).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    updateWorkflow$: (request: UpdateWorkflowRequest) =>
        ajaxPost(`/odata/crm/myqueue/default.UpdateWorkflow`, request).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    closeWorkflow$: (workflowId) =>
        ajaxPost(`/odata/crm/myqueue/default.CloseWorkflow`, {
            workflowId: workflowId,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),

    addNoteToWorkflow$: (workflowId, notes) =>
        ajaxPost(`/odata/crm/myqueue/default.AddNoteToWorkflow`, {
            workflowId: workflowId,
            notes: notes,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),
    getFileContentForWorkflow$: (fileId) =>
        ajaxPost(`/odata/crm/myqueue/default.GetFileContentForWorkflow`, {
            fileId: fileId,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),
    escalateWorkflow$: (workflowId, escalatedTo, escalatedToEmail) =>
        ajaxPost(`/odata/crm/myqueue/default.EscalateWorkflow`, {
            workflowId: workflowId,
            escalatedTo: escalatedTo,
            escalatedToEmail: escalatedToEmail,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),

    getWorkflowsByMemberId$: (memberId) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getworkflows?$filter=memberId eq '${memberId}'&$orderby=CreatedDate desc&$skip=0&$top=50`,
        ),
    getWorkflowsByMemberIdAndServiceAction$: (memberId, serviceAction) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getworkflows?$filter=memberId eq '${memberId}' and workflowTypeString eq '${serviceAction}'&$orderby=CreatedDate desc&$skip=0&$top=1000`,
        ),
    getWorkflowsByCaseId$: (caseId) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getworkflows?$filter=caseId eq '${caseId}'&$orderby=CreatedDate desc&$skip=0&$top=50`,
        ),
    getWorkflowByWorkflowId$: (workflowId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getworkflows?$filter=id eq '${workflowId}'&$skip=0&$top=50`).pipe(
            map((response) => response.value),
            filter((results) => results && results.length),
            map((results) => results[0]),
        ),
    // only used in CommunicationPreferences.tsx
    getWorkflowByWorkflowIdWithDataManager$: (workflowId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getWorkflowById(workflowId='${workflowId}')`),

    trackDiscardedWorkflow$: () => ajaxPost(`odata/crm/myqueue/default.trackdiscardworkflow`),

    getDemographicChangeFieldHistory$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getDemographicChangeFieldHistory(memberId='${memberId}')`),

    demographicChange$: (formData, memberId) =>
        ajaxPost(`/odata/crm/myqueue/default.DemographicChange`, {
            formData: formData,
            memberId: memberId,
        }),
    pcpChange$: (formData, memberId) =>
        ajaxPost(`/odata/crm/myqueue/default.PcpChange`, {
            formData: formData,
            memberId: memberId,
        }),
    requestPlanMaterial$: (formData) =>
        ajaxPost(`/odata/crm/myqueue/default.RequestPlanMaterial`, {
            formData: formData,
        }),
    poaValidation$: (poaItems, memberId) =>
        ajaxPost(`/odata/crm/myqueue/default.PoaValidation`, { poaItems: poaItems, memberId: memberId }),

    memberReimbursement$: (formData) =>
        ajaxPost(`/odata/crm/myqueue/default.MemberReimbursement`, {
            ...formData,
        }),

    getWorkflowTypes$: () => ajaxGetResponse(`/odata/crm/myqueue/default.GetWorkflowTypes`),
    getWorkflowStatuses$: () => ajaxGetResponse(`/odata/crm/myqueue/default.GetWorkflowStatuses`),
    getCrmInboundUsers$: () => ajaxGetResponse(`/odata/crm/myqueue/default.GetCrmInboundUsers`),

    getCrmBenefitInfo$: () => ajaxGetResponse(`/odata/crm/myqueue/default.GetCrmBenefitInfo`),
    updateCrmBenefitInfo$: (request: UpdateCrmBenefitInfoRequest) =>
        ajaxPost(`/odata/crm/myqueue/default.UpdateCrmBenefitInfo`, request),

    // workflow task
    createWorkflowTask$: (request: CreateWorkflowTaskRequest) =>
        ajaxPost(`/odata/crm/myqueue/default.CreateWorkflowTask`, request).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    updateWorkflowTask$: (request: UpdateWorkflowTaskRequest) =>
        ajaxPost(`/odata/crm/myqueue/default.UpdateWorkflowTask`, request).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    closeWorkflowTask$: (workflowTaskId) =>
        ajaxPost(`/odata/crm/myqueue/default.CloseWorkflowTask`, {
            workflowTaskId: workflowTaskId,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),

    addNoteToWorkflowTask$: (workflowTaskId, notes) =>
        ajaxPost(`/odata/crm/myqueue/default.AddNoteToWorkflowTask`, {
            workflowTaskId: workflowTaskId,
            notes: notes,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),

    getWorkflowTasksByWorkflowId$: (workflowId) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getworkflowtasks?$filter=workflowId eq '${workflowId}'&$skip=0&$top=50`,
        ),
    getWorkflowTasksByCaseId$: (caseId) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getworkflowtasks?$filter=caseId eq '${caseId}'&$orderby=CreatedDate desc&$skip=0&$top=50`,
        ),
    getWorkflowTaskById$: (id) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getworkflowtasks?$filter=id eq '${id}'&$skip=0&$top=50`).pipe(
            map((response) => response.value),
            filter((results) => results && results.length),
            map((results) => results[0]),
        ),

    getWorkflowTaskStatuses$: () => ajaxGetResponse(`odata/crm/myqueue/default.getWorkflowTaskStatuses`),
    getTaskTypes$: () => ajaxGetResponse(`odata/crm/myqueue/default.GetTaskTypes`),

    // service flow
    createServiceFlow$: (request: CreateServiceFlowRequest) => {
        return ajaxPost(`odata/crm/myqueue/default.CreateServiceFlow`, request).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        );
    },
    updateServiceFlow$: (request: UpdateServiceFlowRequest) => {
        return ajaxPost(`/odata/crm/myqueue/default.UpdateServiceFlow`, request).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        );
    },
    deleteServiceFlow$: (id) => {
        return ajaxPost(`/odata/crm/myqueue/default.DeleteServiceFlow`, {
            id: id,
        }).pipe(map((ajaxResponse) => ajaxResponse.response));
    },
    switchIsActiveServiceFlow$: (id) => {
        return ajaxPost(`/odata/crm/myqueue/default.SwitchIsActiveServiceFlow`, {
            id: id,
        }).pipe(map((ajaxResponse) => ajaxResponse.response));
    },

    getServiceFlow$: (id) => ajaxGetResponse(`/odata/crm/myqueue/default.getserviceflow(id='${id}')`),
    getServiceFlows$: () =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getserviceflows`).pipe(
            map((response: any) => response.value),
            map((serviceFlows: any) => serviceFlows.filter((flow) => flow.isActive && !flow.isDeleted)),
        ),
    getAllServiceFlows$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getserviceflows`),
    getServiceFlowsForMember$: (memberId, effectiveDate): Observable<ServiceFlow[]> =>
        getODataArray(
            `/odata/crm/myqueue/default.GetServiceFlowsForMember?memberId=${memberId}&effectiveDate=${effectiveDate}`,
        ),

    // misc
    initialData$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getinitialdata`),

    getInboundScript$: () => ajaxGetResponse('odata/crm/myqueue/default.getInboundScript'),

    getFeatureFlags$: () => ajaxGet(`api/crm/myqueue/GetFeatureFlags`),

    getAppSettings$: () => ajaxGetResponse('odata/crm/myqueue/default.getAppSettings'),

    getCompanyMaster$: () => ajaxGetResponse('odata/crm/myqueue/default.getCompanyMaster'), // TODO: remove

    getMsos$: () => ajaxGetResponse('odata/sessionorganizations?OrgType=MSO'),
    getAllMsos$: () => ajaxGetResponse('odata/crm/myqueue/default.GetAllMsos'),

    getQueues$: () => ajaxGetResponse(`odata/crm/myqueue/default.GetQueues`),

    getAdasLanguages$: () => ajaxGetResponse('odata/crm/myqueue/default.GetAdasLanguages'),
    getEzmLanguages$: () => ajaxGetResponse('odata/crm/myqueue/default.getezmemberlanguages'),

    getWorkflowTaskOptions$: () => ajaxGetResponse('odata/crm/myqueue/default.GetWorkflowTaskOptions'),
    updateCaseReassignmentStructureByQueue$: (body) =>
        ajaxPost('odata/crm/myqueue/default.UpdateCaseReassignmentStructureByQueue', body),

    getCrmConfig$: () => ajaxGetResponse('odata/crm/myqueue/default.GetCrmConfig'),
    updateTextReplaceOptions$: (body) => ajaxPost('odata/crm/myqueue/default.UpdateTextReplaceOptions', body),

    getUserManagementQueues$: () => ajaxGetResponse(`/odata/usermanagementqueues`),

    addQueueItem$: (queue) =>
        ajaxPost(`odata/crm/myqueue/default.AddQueueItem`, {
            queue: queue,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),

    pushQueues$: () =>
        ajaxPost(`odata/crm/myqueue/default.PushQueues`).pipe(map((ajaxResponse) => ajaxResponse.response)),

    getMyProductivityStats$: () => ajaxGetResponse('api/myqueue/my-stats'),

    getMyProductivityStatsTotalCases$: (): Observable<Case[]> => ajaxGetResponse('api/myqueue/my-stats/totalcases'),

    getMyProductivityStatsTotalClosedCases$: (): Observable<Case[]> =>
        ajaxGetResponse('api/myqueue/my-stats/totalclosedcases'),

    getMyProductivityStatsTotalOpenCases$: (): Observable<Case[]> =>
        ajaxGetResponse('api/myqueue/my-stats/totalopencases'),

    getMyProductivityStatsTotalOpenAssignedCases$: (): Observable<Case[]> =>
        ajaxGetResponse('api/myqueue/my-stats/totalopenassignedcases'),

    getMyProductivityStatsTotalOpenAssignedTasks$: (): Observable<WorkflowTask[]> =>
        ajaxGetResponse('api/myqueue/my-stats/totalopenassignedtasks'),

    getMyProductivityStatsCallCasesThisMonth$: (): Observable<Case[]> =>
        ajaxGetResponse('api/myqueue/my-stats/callcasesthismonth'),

    getMyProductivityStatsClosedCasesThisMonth$: (): Observable<Case[]> =>
        ajaxGetResponse('api/myqueue/my-stats/closedcasesthismonth'),

    getMyProductivityStatsOpenCasesThisMonth$: (): Observable<Case[]> =>
        ajaxGetResponse('api/myqueue/my-stats/opencasesthismonth'),

    getOpenCaseItemsForUser$: (userEmail) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetOpenCaseItemsForUser(userEmail='${userEmail}')`),

    getMailRequestItems$: () => ajaxGetResponse('/odata/crm/myqueue/default.getMailRequestItems'),

    updateMailTypes$: (data) => ajaxPost('/odata/crm/myqueue/default.UpdateMailTypes', data),

    updateMailSubTypes$: (data) => ajaxPost('/odata/crm/myqueue/default.UpdateMailSubTypes', data),

    getMemberPcpHistory$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.pcphistory(memberId='${memberId}')`),

    getAuthorizations$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getauthorizations(memberId='${memberId}')`),

    getMemberPbpHistory$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.getpoliciescoverage(memberId='${memberId}')`),

    getNextBestActions$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetNextBestActions(memberId='${memberId}')`),

    getActiveCounties$: () => ajaxGet(`/odata/crm/myqueue/default.getactivecounties`),
    getCountyByZipCode$: (zipCode) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.getcountybyzipcode(getCountyByZipCodeRequest=@getCountyByZipCodeRequest)?@getCountyByZipCodeRequest={"@odata.type":"AHC.VNext.Infrastructure.CRM.RequestModels.Workflow.GetCountyByZipCodeRequest","ZipCode":"${zipCode}"}`,
        ),
    getStates$: () => ajaxGet(`/odata/crm/myqueue/default.getstates`),

    getAllHealthPlan$: () => ajaxGetResponse(`/odata/crm/myqueue/default.GetAllHealthPlan`),
    getHealthPlan$: (parameters) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetHealthPlan?companyid=${parameters.companyId}&pbpid=${parameters.pbpId}&hcode=${parameters.hcode}&planyear=${parameters.planYear}&displayzipcodes=${parameters.displayZipCodes}`,
        ),

    getHealthPlanBenefitDetails$: (parameters) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetHealthPlanBenefitDetails?companyId=${parameters.companyId}&hcode=${
                parameters.hcode
            }&pbpId=${parameters.pbpId || ''}&pbpType=${parameters.pbpType}&planYear=${
                parameters.planYear
            }&countyCode=${parameters.countyCode}&countyName=${parameters.countyName}&lineOfBusiness=${
                parameters.lineOfBusiness
            }&ipa=${parameters.ipa}&searchTerm=${parameters.searchTerm}&template=EOC`,
        ),

    getPlanDetailsByZipCode$: (zipCode, coverageYear) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetPlanDetailsByZipCode?zipCode=${zipCode}&coverageYear=${coverageYear}`,
        ),

    getPoaEnums$: () => ajaxGetResponse(`/odata/crm/myqueue/default.GetPoaEnums`),

    getTalkdeskRecordingUrls$: (talkDeskInteractionId) =>
        ajaxGetResponse(
            `odata/crm/myqueue/default.GetTalkdeskRecordingUrls(talkDeskInteractionId='${talkDeskInteractionId}')`,
        ),
    searchProviders$: (parameters, memberId: string | null) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.searchproviders?pageIndex=${
                parameters.pageNumber ?? ''
            }&providerCategory=Primary Care Provider (PCP)&pbpId=${parameters.pbpId ?? ''}&healthplanid=${
                parameters.healthplanid ?? ''
            }&searchterm=${parameters.searchterm ?? ''}&fromAddress=${parameters.fromAddress ?? ''}&gender=${
                parameters.gender ?? ''
            }&language=${parameters.language ?? ''}&withinmiles=${parameters.withinmiles ?? ''}&acceptingNewPatients=${
                parameters.acceptingNewPatients ?? ''
            }&ipa=${parameters.ipa ?? ''}&sortby=qualityrating,${parameters.sortby ?? ''}&visitType=${
                parameters.visitType ?? ''
            }&planYear=${parameters.planYear ?? ''}&memberId=${memberId}`,
        ),

    addProvider$: (provider) => ajaxPost('/odata/crm/myqueue/default.AddProvider', provider),

    // supplemental benefits
    getSupplementalBenefitAllocationHistory$: (memberId, type = 'benefit') =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetSupplementalBenefitsAllocationHistoryByType(memberId='${memberId}',type='${type}')`,
        ),
    getSupplementalBenefitConfig$: (code, year, type) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetSupplementalBenefitConfig(code='${code.replace(
                /\s|\\|\//gi,
                '',
            )}',year='${year}',type='${type}')`,
        ),
    upsertSupplementalBenefitConfig$: (code, year, type, displayText) =>
        ajaxPost('/odata/crm/myqueue/default.UpsertSupplementalBenefitConfig', {
            code: code.replace(/\s|\\|\//gi, ''),
            year,
            type,
            displayText,
        }),
    getSupplementalBenefitsMasterList$: () =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetSupplementalBenefitsMaster`),
    getSupplementalBenefitsListByEligibility: (memberId) =>
        ajaxGet(`/odata/crm/myqueue/default.GetSupplementalBenefitsListByEligibility(memberId='${memberId}')`).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    getSupplementalBenefitsDetailByEligibility$: (memberId) =>
        ajaxGet(`/odata/crm/myqueue/default.GetSupplementalBenefitsDetailByEligibility(memberId='${memberId}')`).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    getSupplementalBenefitsDetail$: (memberId) =>
        ajaxGet(`/odata/crm/myqueue/default.GetSupplementalBenefitsDetail(memberId='${memberId}')`).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    getSupplementalBenefitsDetailForBubbles$: (memberId) =>
        ajaxGet(`/odata/crm/myqueue/default.GetSupplementalBenefitsDetailForBubbles(memberId='${memberId}')`).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    getSupplementalBenefitValidationErrorsMap$: (memberId: string, incentiveNames: string[]) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetSupplementalBenefitValidationErrorsMap?memberId=${memberId}&${incentiveNames
                .map((incentiveName) => `incentiveNames=${encodeURIComponent(incentiveName)}`)
                .join('&')}
            `,
        ),

    getStickyNotes$: (memberId) =>
        ajaxGet(`/odata/crm/myqueue/default.GetStickyNotes(memberId='${memberId}')`).pipe(
            map((ajaxResponse) => ajaxResponse?.response?.value ?? null),
        ),
    getCrmAdminStickyNotes$: () => ajaxGetResponse(`/odata/crm/myqueue/default.getCrmAdminStickyNotes`),
    dismissStickyNote$: (noteId: string, active: boolean) =>
        ajaxPost(`/odata/crm/myqueue/default.dismissStickyNote`, { id: noteId, active: active }).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    upsertStickyNote$: (memberId: string, note: string, active: boolean) =>
        ajaxPost(`/odata/crm/myqueue/default.upsertStickyNote`, { memberId, note, active }).pipe(
            map((ajaxResponse) => ajaxResponse.response),
        ),
    createStickyNote$: (request: StickyNoteRequest) => ajaxPost(`/odata/crm/myqueue/default.createStickyNote`, request),
    updateStickyNote$: (request: StickyNoteRequest) => ajaxPost(`/odata/crm/myqueue/default.updateStickyNote`, request),
    deleteStickyNote$: (id: string) => ajaxPost(`/odata/crm/myqueue/default.deleteStickyNote`, { id: id }),

    validateCountyZipCode$: (zipCode, countyCode) =>
        ajaxPost(`/odata/crm/myqueue/default.validateCountyZipCode`, {
            zipCode,
            countyCode,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),
    getMemberCallLogs$: (memberId, phoneNumbers) =>
        ajaxPost(`/odata/crm/myqueue/default.getMemberCallLogs`, {
            memberId,
            phoneNumbers,
        }).pipe(map((ajaxResponse) => ajaxResponse)),
    getMemberTimeLine$: (memId) =>
        crmMemberTimelineSubject.asObservable().pipe(
            filter(({ memberId }) => memberId === memId),
            map(({ memberId, response }) => response),
        ),
    fetchMemberTimeLine$: (memberId) =>
        ajaxGet(`/odata/crm/myqueue/memberTimeline(memberId='${memberId}')`).subscribe({
            next: (response) => crmMemberTimelineSubject.next({ memberId, response }),
            error: (error) =>
                crmMemberTimelineSubject.next({ memberId, error: "Member's health journey was not found" }),
        }),
    getUserQueueMappings$: () =>
        ajaxGet(`/odata/crm/myqueue/default.GetUserQueueMappings`).pipe(
            map((ajaxResponse) => ajaxResponse.response.value),
        ),
    getAuthorizationByAuthNo$: (authNo: string) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetAuthorizationByAuthNo(authNo='${authNo}')`),
    getAuthorizationDetails$: (authNo: string) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetAuthorizationDetails(authNo='${authNo}')`),
    getAuthAdmissions$: (authNo: string) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetAuthAdmissionDetails(authNo='${authNo}')`),
    getMemberLookup$: (authNo: string) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.MemberLookup(authNo='${authNo}')`),
    getAuthorizationLetter$: (authNo: string, letterId: number) =>
        ajaxGetType(`/api/crm/myqueue/authorizations/${authNo}/letters/${letterId}`, 'blob'),
    validateIncentiveName$: (incentiveName: string): Observable<SupplementalBenefitValidationError> =>
        ajaxPost(`/odata/crm/myqueue/default.validateIncentiveName`, {
            incentiveName: incentiveName,
        }).pipe(map((ajaxResponse) => ajaxResponse.response)),

    //Mail Material Request
    processMailMaterialRequest$: (requestPlanMaterialForm, member, mailMaterialRequests, id) =>
        ajaxPost('api/crm/myqueue/ProcessMailMaterialRequest', {
            planMaterials: mailMaterialRequests,
            memberId: member.memberId,
            memberEffectiveDate: member.ezMemEffectiveDate,
            mailingAddressLine1: requestPlanMaterialForm.mailingAddressLine1,
            mailingAddressLine2: requestPlanMaterialForm.mailingAddressLine2,
            mailingAddressCity: requestPlanMaterialForm.mailingAddressCity,
            mailingAddressState: requestPlanMaterialForm.mailingAddressState,
            mailingAddressZip: requestPlanMaterialForm.mailingAddressZip,
            email: requestPlanMaterialForm.email,
            id: id,
        }),

    //Email & Mail Status
    GetEmailStatus$: () => ajaxGet('api/crm/myqueue/GetEmailStatus/GetEmailStatus'),

    GetMailStatus$: () => ajaxGet('api/crm/myqueue/GetMailStatus/GetMailStatus'),

    getStarMeasureMed$: (memberId) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetStarMeasureMedications(memberId='${memberId}')`),

    getMemberPortalEvents$: (memberId, memberEmail) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetMemberPortalEventsById(memberId='${memberId}',memberEmail='${memberEmail}')`,
        ),
    getMemberPortalAccountEvents$: (memberId, memberEmail) =>
        ajaxGetResponse(
            `/odata/crm/myqueue/default.GetMemberPortalAccountEvents(memberId='${memberId}',memberEmail='${memberEmail}')`,
        ),
    //Claim
    getClaimByNo$: (claimNo: string, companyId: string) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetClaimByNo(claimNo='${claimNo}',companyId='${companyId}')`),
    getMemberNotes$: (memberId: string) =>
        ajaxGetResponse(`/odata/crm/myqueue/default.GetMemberContacts(memberId='${memberId}')`),
    getMemberWallets$: (memberId: string) =>
        ajaxGetResponse<GetMemberWalletsResponse>(
            `/odata/crm/myqueue/default.GetMemberWallets(memberId='${memberId}')`,
        ),
    getHraTagInfo$: (memberId: string) =>
        getODataObject<MemberTag>(`/odata/crm/myqueue/default.GetHraTagInfo(memberId='${memberId}')`),
};
const getStringProperty = (property, replaceNonWords?) => {
    if (property === null || property?.trim() === '') {
        return null;
    } else if (replaceNonWords) {
        return `'${property.toUpperCase().replace(/\W/g, '')}'`;
    } else {
        return `'${property.toUpperCase()}'`;
    }
};
const getArrayToStringProperty = (property) => {
    if (property === null || property === undefined) {
        return null;
    } else {
        const allItems = property
            .map((p) => `'${p}'`)
            .join(',')
            .toUpperCase();
        return `[${allItems}]`;
    }
};
const getDateProperty = (property) => {
    if (property === null || property === undefined) {
        return null;
    } else {
        return property.toJSON();
    }
};

export { myQueueService, getDateProperty };
