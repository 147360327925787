import React, { useEffect, useRef, useState, useCallback } from 'react';
import { DatePickerComponent, MaskedDateTime, Inject } from '@syncfusion/ej2-react-calendars';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { AxiosError } from 'axios';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { requests } from 'components/AvaEzm/AxiosApi';
import { defaultValuesCapTieredRates } from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { CapRateDetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { fillWithZeros } from 'components/ProviderData/IpaAdministration/atoms/utils';
import { submitCapRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { dialogService } from 'subjects/common/DialogService';

type CapTieredRatesProps = {
    sequence?: number;
    ipaCode?: string;
    capId?: number;
    capTierRate?: CapRateDetail;
    dates: {
        effDate: Date;
        endDate: Date;
    };
    prevDatesCap: {
        effDate: Date | string;
        endDate: Date | string;
    };
    isReadOnlyViewer: boolean;
};

export const CapTieredRates = (props: CapTieredRatesProps) => {
    const { sequence, ipaCode, capId, capTierRate, dates, prevDatesCap, isReadOnlyViewer } = props;
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        control,
    } = useForm<any>();
    const toastRef = useRef<ToastComponent>(null);
    const [effDate, setEffDate] = useState();
    const [startMember, setStartMember] = useState<number>();

    const fetchCapTierRateDetail = async (sequence: any) => {
        let capRate: any = capTierRate;
        if (!capTierRate && sequence) {
            capRate = await requests.get<CapRateDetail>(
                `/api/CapTieredRate/GetCapTieredRateDetail?ipaCode=${ipaCode}&sequence=${sequence}`,
            );
        }
        const fields = getValues();
        for (const field in fields) {
            if (fields[field] !== undefined) {
                const value = capRate[field];
                const decimals = `${defaultValuesCapTieredRates[field]}`.split('.')[1];
                if (typeof value === 'number' && decimals) {
                    setValue(
                        field as keyof CapRateDetail,
                        value ? fillWithZeros(value, decimals.length) : defaultValuesCapTieredRates[field],
                    );
                } else {
                    setValue(field as keyof CapRateDetail, value);
                }
            }
        }
        setValue('sequence', sequence);
    };

    const resetForm = () => {
        setValue('sequence', 0);
        reset();
        for (const field in defaultValuesCapTieredRates) {
            setValue(field as keyof CapRateDetail, defaultValuesCapTieredRates[field]);
        }
        setValue('effDate', prevDatesCap?.effDate);
        setValue('endDate', prevDatesCap?.endDate);
    };

    const saveInformation = async (data: CapRateDetail) => {
        try {
            const url = `/api/CapTieredRate/`;
            let action = 'AddNewCapTieredRate';
            const sequenceValue = data.sequence ? data.sequence : 0;
            const formattedData = {
                ...data,
                ipaCode,
                sequence: sequenceValue,
                capId: capId,
                createDate: new Date(),
                updateDt: new Date(),
            };
            if (sequenceValue) {
                action = 'OverwriteExistingCapTieredRate';
            }
            await requests.post(`${url}${action}`, formattedData);
            submitCapRateService.submit();
            dialogService.close();
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            toastRef.current?.show({
                title: 'Error',
                content: message,
                cssClass: 'e-toast-danger',
            });
        }
    };
    const ControlledMaskInput = useCallback(
        ({ name, value, decimals, label }: any) => {
            let regex = /^[0-9]*$/;
            const defaultValue = `0.${'0'.padEnd(decimals, '0')}`;
            if (decimals === 4) {
                regex = /^[0-9]*\.[0-9]{4}$/;
            } else if (decimals === 2) {
                regex = /^[0-9]*\.[0-9][0-9]$/;
            }
            return (
                <Controller
                    render={({ field }) => (
                        <div>
                            <div className="e-float-input e-input-group">
                                <input
                                    {...register(name, { pattern: regex })}
                                    id={name}
                                    onBlur={({ target }) => {
                                        if (decimals && !regex.test(target.value)) {
                                            const defaultDecimals = `.${'0'.padEnd(decimals, '0')}`;
                                            const valueFilled = fillWithZeros(target.value, decimals);
                                            const valueSplitted = target.value ? `${target.value}`.split('.') : '';
                                            if (valueSplitted[1] && valueSplitted[1].length > decimals) {
                                                setError(name, {
                                                    type: 'custom',
                                                    message: `Format 0${defaultDecimals}`,
                                                });
                                            } else if (valueSplitted && valueFilled) {
                                                setValue(name as keyof CapRateDetail, valueFilled);
                                                clearErrors(name);
                                            }
                                        } else {
                                            clearErrors(name);
                                        }
                                    }}
                                    placeholder={decimals ? defaultValue : '0'}
                                />
                                <span className="e-float-line"></span>
                                <label className="e-float-text">{label}</label>
                            </div>
                            {errors[name] && <p className="error-form"> {errors[name].message}</p>}
                        </div>
                    )}
                    control={control}
                    name={name}
                />
            );
        },
        [errors],
    );

    useEffect(() => {
        resetForm();
        fetchCapTierRateDetail(sequence);
        const sub = submitCapRateService.get$().subscribe(({ type }) => {
            if (type === 'add') {
                resetForm();
            }
        });
        return () => {
            sub.unsubscribe();
        };
    }, [sequence]);

    return (
        <DisabledWrapper disabled={isReadOnlyViewer}>
            <div className="tab-content" style={{ border: '1px solid #c8c8c8', padding: '10px' }}>
                <div className="mb-4">
                    <h4>
                        <b>Cap Tier Rate for: {capId}</b>
                    </h4>
                </div>
                <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmit(saveInformation)}>
                            <Row className="center-fields mb-2">
                                <Col md={2}>
                                    <div className="e-float-input e-input-group">
                                        <input
                                            type="number"
                                            {...register('memberCountStart', {
                                                required: 'Member Count Start is required',
                                            })}
                                            onChange={(e) => setStartMember(Number(e.target.value))}
                                        />
                                        <span className="e-float-line"></span>
                                        <label className="e-float-text">Member Count Start</label>
                                    </div>
                                    {errors.memberCountStart && (
                                        <p className="error-form"> {errors.memberCountStart.message}</p>
                                    )}
                                </Col>
                                <Col md={2}>
                                    <div className="e-float-input e-input-group">
                                        <input
                                            type="number"
                                            {...register('memberCountEnd', {
                                                required: 'Member Count End is required',
                                                min: startMember,
                                            })}
                                        />
                                        <span className="e-float-line"></span>
                                        <label className="e-float-text">Member Count End</label>
                                    </div>
                                    {errors.memberCountEnd && (
                                        <p className="error-form"> {errors.memberCountEnd.message}</p>
                                    )}
                                </Col>
                                <Col md={2}>
                                    <div className="e-float-input e-input-group disable-field">
                                        <input {...register('active')} type="text" value={capTierRate?.active} />
                                        <span className="e-float-line"></span>
                                        <label className="e-float-text">Active</label>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div>
                                        <label className="e-label-select">Effective Date</label>
                                        <DatePickerComponent
                                            {...register('effDate', { required: 'Effective Date is required' })}
                                            id="effDate"
                                            change={({ value }) => {
                                                setValue('effDate', value);
                                                setEffDate(value);
                                            }}
                                            format="MM/dd/yyyy"
                                            min={dates?.effDate}
                                            max={dates?.endDate}
                                            enableMask={true}
                                            strictMode
                                            disabled
                                        >
                                            <Inject services={[MaskedDateTime]} />
                                        </DatePickerComponent>
                                    </div>
                                    {errors.effDate && <p className="error-form"> {errors.effDate.message}</p>}
                                </Col>
                                <Col md={3}>
                                    <div>
                                        <label className="e-label-select">End Date</label>
                                        <DatePickerComponent
                                            {...register('endDate', { required: 'End Date is required' })}
                                            id="endDate"
                                            change={({ value }) => {
                                                setValue('endDate', value);
                                            }}
                                            format="MM/dd/yyyy"
                                            min={effDate}
                                            max={dates?.endDate}
                                            enableMask={true}
                                            strictMode
                                            disabled
                                        >
                                            <Inject services={[MaskedDateTime]} />
                                        </DatePickerComponent>
                                        {errors.endDate && <p className="error-form"> {errors.endDate.message}</p>}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="center-fields mb-2">
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="premiumPCT"
                                        decimals={4}
                                        label="Premium PCT"
                                        value={capTierRate?.premiumPCT}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="mediMediPCT"
                                        label="Medi Medi PCT"
                                        decimals={4}
                                        value={capTierRate?.mediMediPCT}
                                    />
                                </Col>

                                <Col md={3}>
                                    <ControlledMaskInput
                                        label="PM PM Amt"
                                        name="pmPmAmt"
                                        decimals={4}
                                        value={capTierRate?.pmPmAmt}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="pmPmAmtMm"
                                        label="PM PM Amt (MM)"
                                        decimals={4}
                                        value={capTierRate?.pmPmAmtMm}
                                    />
                                </Col>
                            </Row>
                            <Row className="center-fields mb-2">
                                <Col md={3}>
                                    <ControlledMaskInput
                                        label="Floor MC"
                                        name="floorMC"
                                        decimals={4}
                                        value={capTierRate?.floorMC}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        label="Floor MM"
                                        name="floorMM"
                                        decimals={2}
                                        value={capTierRate?.floorMM}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="hospSurplusSplit"
                                        label="Hospital SurPlus Split"
                                        decimals={4}
                                        value={capTierRate?.hospSurplusSplit}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="profSurplusSplit"
                                        label="Prof SurPlus Split"
                                        decimals={4}
                                        value={capTierRate?.profSurplusSplit}
                                    />
                                </Col>
                            </Row>
                            <Row className="center-fields mb-2">
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="riskPHosp"
                                        label="Risk P Hospital"
                                        decimals={4}
                                        value={capTierRate?.riskPHosp}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="riskPprof"
                                        label="Risk P Prof"
                                        decimals={4}
                                        value={capTierRate?.riskPprof}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        label="Admin Fee"
                                        name="adminFee"
                                        decimals={4}
                                        value={capTierRate?.adminFee}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        label="ESRD PM PM"
                                        name="esrdPmPm"
                                        decimals={2}
                                        value={capTierRate?.esrdPmPm}
                                    />
                                </Col>
                            </Row>
                            <Row className="center-fields mb-2">
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="esrdPmPmMm"
                                        label="ESRD PM PM (MM)"
                                        decimals={2}
                                        value={capTierRate?.esrdPmPmMm}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        name="deductions"
                                        label="Deduction"
                                        decimals={2}
                                        value={capTierRate?.deductions}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        {...register('valueBasedPmPm')}
                                        label="Value Based PMPM"
                                        decimals={2}
                                        value={capTierRate?.valueBasedPmPm}
                                    />
                                </Col>
                                <Col md={3}>
                                    <ControlledMaskInput
                                        {...register('valueBasedPmPmMm')}
                                        label="Value Based PMPM (MM)"
                                        decimals={2}
                                        value={capTierRate?.valueBasedPmPmMm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="e-float-input e-input-group">
                                        <textarea {...register('notes')} rows={3} />
                                        <span className="e-float-line"></span>
                                        <label className="e-float-text">Notes</label>
                                    </div>
                                </Col>
                                <Col md={12} className="mt-3">
                                    <div style={{ width: '100%', textAlign: 'right' }}>
                                        {capTierRate?.id && (
                                            <button
                                                style={{ marginRight: 15 }}
                                                type="button"
                                                onClick={() => setValue('sequence', 0)}
                                                className="btn btn-primary btn-flat"
                                            >
                                                Copy Record
                                            </button>
                                        )}
                                        <button type="submit" className="btn btn-primary btn-flat">
                                            Save
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
                <ToastComponent
                    id="toast_target"
                    ref={toastRef}
                    title=""
                    content=""
                    position={{ X: 'Right', Y: 'Top' }}
                />
            </div>
        </DisabledWrapper>
    );
};
