import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dialogPlaceholderService from '../../subjects/common/dialogPlaceholderService';
import EligibleDialog from '../PanelManagement/AdminPanel/EligibleDialog/EligibleDialog';

const DELAY_CLOSURE_BY = 500;

const dialogType = {
    ELIGIBLE: 1,
    EMPTY: 999,
};

class DialogPlaceHolder extends Component {
    static displayName = DialogPlaceHolder.name;

    state = {
        isOpen: false,
        type: dialogType.EMPTY,
        dialogProps: {},
    };

    queue = [];

    componentDidMount() {
        this.dialogSubscription = dialogPlaceholderService.get().subscribe({
            next: ({ isOpening, type, dialogProps }) => {
                if (isOpening) {
                    this.openDialog(type, dialogProps);
                } else {
                    this.closeDialog();
                }
            },
        });
    }

    openDialog = (type, dialogProps) => {
        if (this.clearToken) {
            clearTimeout(this.clearToken);
            this.clearToken = null;
        }

        this.setState({
            uuid: uuidv4(),
            type,
            dialogProps,
            isOpen: true,
        });
    };

    closeDialog() {
        const { type, isOpen } = this.state;

        if (isOpen) {
            this.setState({ isOpen: false });
        }

        if (this.clearToken) {
            clearTimeout(this.clearToken);
        }

        this.clearToken = setTimeout(() => {
            if (type !== dialogType.EMPTY) {
                this.setState({ type: dialogType.EMPTY, isOpen: false });
            }
        }, DELAY_CLOSURE_BY);
    }

    componentWillUnmount() {
        this.dialogSubscription.unsubscribe();
    }

    render() {
        const { type, isOpen, uuid, dialogProps } = this.state;
        switch (type) {
            case dialogType.ELIGIBLE: {
                return <EligibleDialog key={uuid} {...dialogProps} isOpen={isOpen} />;
            }
            case dialogType.EMPTY: {
                return null;
            }
            default:
                throw new TypeError(
                    `${DialogPlaceHolder.name} error: \n\t Type ${type} not implemented`
                );
        }
    }
}
export { dialogType };
export default DialogPlaceHolder;
