import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

export interface TalkdeskNote {
    talkdeskId: string;
    notes: string;
}

export const DefaultNote = {
    talkdeskId: '0',
    notes: '',
} as TalkdeskNote;

const noteSavedMessages = new BehaviorSubject<TalkdeskNote>(DefaultNote);
const callNotes = new BehaviorSubject<TalkdeskNote>(DefaultNote);
const debouncedNotes = callNotes.asObservable().pipe(
    filter((note) => note.notes?.length > 0),
    debounceTime(3000),
    distinctUntilChanged()
);

const talkdeskNotesService = {
    enqueue: (currentCallNotes: TalkdeskNote) => callNotes.next(currentCallNotes),
    queue$: debouncedNotes,
    noteSaved$: (note: TalkdeskNote) => noteSavedMessages.next(note),
    savedNotes$: noteSavedMessages.asObservable(),
};

export { talkdeskNotesService };
