import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styles from './DataImport.module.scss';
import { DataType, IpaExportFormData } from './Model';
import { ProviderNetworkDataTypes } from 'components/ProviderData/DataImportUtility/DataImport.Metadata';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import './IpaDataImport.scss';
import { ControlledSyncfusionDropDownList } from 'components/Common/SyncfusionWrappers/Syncfusion';
import { exportExcelService } from 'subjects/common/ExportMembersService';

const SEARCH_URL = '/api/ProviderNetworkDataUtility/exportData';

type IpadataExportFormProps = {
    searchFilters: any[];
    isReadOnlyViewer: boolean;
};

const IpaDataExportForm = ({ searchFilters, isReadOnlyViewer }: IpadataExportFormProps) => {
    const [exporting, setExporting] = useState(false);

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<IpaExportFormData>({
        mode: 'onSubmit',
    });

    const onFormSubmit = async (data: IpaExportFormData) => {
        setExporting(true);

        const requestdata = {
            ...data,
            ...searchFilters,
        };

        exportExcelService.post(SEARCH_URL, requestdata, () => {
            setExporting(false);
        });
    };

    return (
        <DisabledWrapper disabled={isReadOnlyViewer} withSpinner={exporting}>
            {exporting ? (
                <div style={{ height: 200 }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '45%',
                            left: '40%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '50px',
                            fontWeight: 'bold',
                        }}
                    >
                        <p>Exporting data, please wait....</p>
                    </div>
                </div>
            ) : (
                <form>
                    <Row className="mb-4">
                        <Col>
                            <div className={styles.col}>
                                <label className="e-label-select mb-2">Target Dataset:</label>
                                <ControlledSyncfusionDropDownList
                                    name="dataType"
                                    control={control}
                                    dataSource={ProviderNetworkDataTypes as DataType[]}
                                    fields={{ text: 'text', value: 'value' }}
                                    ddlAttrs={{
                                        placeholder: 'Select a Target Dataset',
                                    }}
                                    rules={{ required: 'Please select a Target Dataset' }}
                                />
                            </div>
                            <Form.Text className="text-danger">{errors.dataType?.message}</Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <div className={styles.btnGroup}>
                                <Button
                                    className="e-file-upload-btn"
                                    type="button"
                                    onClick={handleSubmit(onFormSubmit)}
                                >
                                    Export Data
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            )}
        </DisabledWrapper>
    );
};

export default IpaDataExportForm;
