/* eslint-disable import/order */
import { useState, useEffect } from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { useUnsubscribe } from './useUnsubscribe';
import { myQueueService } from '../subjects/CRM/MyQueueService';
import { trackEvent } from '../ApplicationInsights';
import { notificationService } from '../subjects/common/NotificationService';
import { SupplementalBenefitFormAnswer } from 'components/CRM/Workflow/WorkflowForms/SupplementalBenefit/SupplementalBenefit.utils';

export interface SupplementalBenefitForm {
    code: string;
    desc: string;
    type: string;
    unit: string;
    unitValue: string;
    limitValue: string;
    limitFrequency: string;
    callScript: string;
    eligibilityGroup?: string | undefined;
    year: string;
    name: string;
    notes: string;
    benefitName: string;
    answers: Array<SupplementalBenefitFormAnswer>;
    effectiveFrom: string;
    effectiveTo: string;
}
export interface Incentive {
    id: string;
    callScript: string;
    code: string;
    desc: string;
    effectiveFrom: string;
    effectiveTo: string;
    limitFrequency: string;
    limitValue: string;
    name: string;
    type: string;
    unit: string;
    unitValue: string;
    eligibilityGroup?: string;
    notes?: string;
    benefitName?: string;
}

export interface useSupplementalBenefitsState {
    loadingBenefits: boolean;
    benefitsList: Incentive[];
    rewardsList: Incentive[];
}
export const useSupplementalBenefits = (memberId: string, existingData: boolean) => {
    const [loadingBenefits, setLoadingBenefits] = useState<boolean>(true);
    const [benefitList, setBenefitList] = useState<Incentive[]>([]);
    const [rewardList, setRewardList] = useState<Incentive[]>([]);
    const pushSubscription = useUnsubscribe();

    const getBenefits = () => {
        setLoadingBenefits(true);
        pushSubscription(
            myQueueService.getSupplementalBenefitsListByEligibility(memberId).subscribe({
                next: async (response: { item1: Incentive[]; item2: Incentive[] }) => {
                    if (!response) return;
                    const [eligibleBenefits, eligibleRewards] = await response.item1.reduce<[Incentive[], Incentive[]]>(
                        ([eligibleBenefits, eligibleRewards], nextBenefit) => {
                            nextBenefit.id = `${nextBenefit.code}-${
                                nextBenefit.effectiveFrom.match(/\d{4}/)?.[0] || ''
                            }`;
                            if (nextBenefit.type === 'Benefit') {
                                nextBenefit.eligibilityGroup = 'Eligible Benefits';
                                eligibleBenefits.push(nextBenefit);
                            } else if (nextBenefit.type === 'Reward') {
                                nextBenefit.eligibilityGroup = 'Eligible Rewards';
                                eligibleRewards.push(nextBenefit);
                            }
                            return [eligibleBenefits, eligibleRewards];
                        },
                        [[], []],
                    );
                    const [otherBenefits, otherRewards] = await response.item2.reduce<[Incentive[], Incentive[]]>(
                        ([otherBenefits, otherRewards], nextBenefit) => {
                            nextBenefit.id = `${nextBenefit.code}-${
                                nextBenefit.effectiveFrom.match(/\d{4}/)?.[0] || ''
                            }`;
                            if (nextBenefit.type === 'Benefit') {
                                nextBenefit.eligibilityGroup = 'Other Benefits';
                                otherBenefits.push(nextBenefit);
                            } else if (nextBenefit.type === 'Reward') {
                                nextBenefit.eligibilityGroup = 'Other Rewards';
                                otherRewards.push(nextBenefit);
                            }
                            return [otherBenefits, otherRewards];
                        },
                        [[], []],
                    );
                    setBenefitList([...eligibleBenefits, ...otherBenefits]);
                    setRewardList([...eligibleRewards, ...otherRewards]);
                    setLoadingBenefits(false);
                },
                error: (error) => {
                    setLoadingBenefits(false);
                    notificationService.error({
                        title: 'Error',
                        content: error.message,
                    });
                    trackEvent('AVA-UI-MEMBER-GET-BENEFITS-LIST-ERROR', SeverityLevel.Error, error);
                },
            }),
        );
    };
    useEffect(() => {
        if (existingData) {
            setLoadingBenefits(false);
        } else {
            getBenefits();
        }
    }, [memberId]);

    return { loadingBenefits, benefitList, rewardList };
};
